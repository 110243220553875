/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Update Page
 */
import styled from '@emotion/styled';
import {Button, Divider} from '@mui/material';
import {ShareLink} from '@viasat/insights-components';
import {spacing} from '@vst/beam';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import {ApprovalFlow} from '../../components/ApprovalFlow';
import ApprovalHistoryTable from '../../components/ApprovalHistoryTable';
import BackButton from '../../components/BackButton';
import UpdateInformationTable from '../../components/UpdateInformationTable';
import {PATHS} from '../../paths';
import {PackageData, PackageStatuses} from '../../api/queries/packages';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import {formatPackageType} from '../../utils/gridUtils';
import {getCurrentUrlPath} from '../../utils/linkUtils';
import {Alert} from '../../components/Alert';
import AddApplicableTailsSection from '../../components/AddApplicableTailsSection';
import {useAddApplicableTails} from '../../api/mutations/useAddApplicableTails';
import {strings} from '../../locale/strings';
import {useUpdates} from '../../api/queries/useUpdates';
import {useApprovals} from '../../api/queries/useApprovals';
import moment from 'moment-timezone';
import {useCreateApproval} from '../../api/mutations/useCreateApproval';
import useBearStore from '../../utils/stores/useBearStore';
import {CloseUpdateDialog} from '../../components/CloseUpdateDialog';
import InternalNotesSection from '../../components/InternalNotesSection';
import qs from 'qs';

const ApprovalHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[24]};
`;

const AddUpdateSection = styled.div`
  margin: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
`;

const Banner: React.FC<{
  isInternal: boolean;
  isLoading: boolean;
  isError: boolean;
  isAddedTailsSuccess: boolean;
  isCreateApprovalSuccess: boolean;
  updateData: PackageData;
  onClickClose: () => void;
}> = ({isInternal, isLoading, isError, isAddedTailsSuccess, isCreateApprovalSuccess, updateData, onClickClose}) => {
  // Hide banner while action is being performed
  if (isLoading) {
    return null;
  }
  // Error banner
  else if (isError) {
    return (
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['error']}
        status="error"
        closing={true}
        onClickClose={onClickClose}
      />
    );
  } else if (isCreateApprovalSuccess) {
    return updateData.status === PackageStatuses.REJECTED ? (
      // Update rejected
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['update_rejected_success_feedback']}
        status="info"
        closing={true}
        onClickClose={onClickClose}
      />
    ) : updateData.status === PackageStatuses.CLOSED ? (
      // Update closed
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['update_closed']}
        status="info"
        closing={true}
        onClickClose={onClickClose}
      />
    ) : (
      // Update approved
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['update_approved']}
        status="success"
        closing={true}
        onClickClose={onClickClose}
      />
    );
  }
  // Added applicable tails
  else if (isAddedTailsSuccess) {
    return (
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['tails_added'].replace('%airline_name%', updateData.airline_name)}
        status="success"
        closing={true}
        onClickClose={onClickClose}
      />
    );
  }
  // No applicable tails have been added yet
  else if (isInternal && updateData.status === PackageStatuses.NO_APPLICABLE_TAILS) {
    return <Alert bodyText={strings.UpdatePage.BannerBodyText['no_tails']} status="error" closing={false} />;
  }
  // Update has been closed
  else if (updateData.status === PackageStatuses.CLOSED) {
    return <Alert bodyText={strings.UpdatePage.BannerBodyText['update_closed']} status="info" closing={false} />;
  }
  // Notice to admin that airline has rejected the update
  else if (isInternal && updateData.status === PackageStatuses.REJECTED) {
    return (
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['update_rejected']}
        status="error"
        closing={true}
        onClickClose={onClickClose}
      />
    );
  }
  // Notice to airline that the update deployment date has passed
  else if (
    !isInternal &&
    moment().isAfter(updateData.deployment_date) &&
    ![PackageStatuses.REJECTED, PackageStatuses.FULLY_APPROVED, PackageStatuses.CLOSED].includes(updateData.status)
  ) {
    return (
      <Alert
        bodyText={strings.UpdatePage.BannerBodyText['deployment_date_passed']}
        status="error"
        closing={true}
        onClickClose={onClickClose}
      />
    );
  }
  // Default: No banner display
  else {
    return null;
  }
};

const Update: React.FC = () => {
  const {
    init: {isAdmin, isInternal, approvalAirlineCodes},
    airlines
  } = useBearStore(state => ({init: state.init, airlines: state.airlines}));

  let pageParams = useParams<{updateId: string}>();
  const updateId = pageParams.updateId;

  const {data: updatesData} = useUpdates({airlines});
  const {data: approvalsData, isLoading: isApprovalsLoading, isError: isApprovalsError} = useApprovals({airlines});
  const [updateData, setUpdateData] = useState<PackageData | undefined>(undefined);
  const [closeUpdateDialogOpen, setCloseUpdateDialogOpen] = useState(false);

  const internalNotes = updateData?.internal_notes;
  const links = updateData?.links;
  const approvals = approvalsData?.approvals.filter(({package_id}) => package_id === updateData?.id);
  const urlPath = getCurrentUrlPath();
  const urlQuery = qs.stringify({airlineCode: airlines}, {arrayFormat: 'comma'});
  const navigate = useNavigate();
  // Determines if banner is closed
  const [bannerIsActive, setBannerIsActive] = useState<boolean>(true);
  const addApplicableTailsResult = useAddApplicableTails();
  const createApprovalResult = useCreateApproval({
    onMutate: () => {
      setBannerIsActive(true);
    }
  });

  // Hides the banner
  const onBannerClickClose = () => {
    setBannerIsActive(false);
  };

  /**
   * Callback when user clicks button to add applicable tails.
   * @param packageId The package ID.
   * @param tails List of tails to add.
   */
  const onAddAircraft = (packageId: string, tails: Array<string>) => {
    setBannerIsActive(true);
    addApplicableTailsResult.reset();
    addApplicableTailsResult.mutate({
      packageId,
      params: {tails}
    });
  };

  const onClose = (closureReason: string) => {
    createApprovalResult.mutate({
      updateId: updateData?.id ?? '',
      params: {
        approval_type: 'closure',
        reason: closureReason
      }
    });
  };

  const handleCloseUpdateClick = () => {
    setCloseUpdateDialogOpen(true);
  };

  useEffect(() => {
    if (updatesData) {
      setUpdateData(updatesData.find(d => d.id === updateId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatesData]);

  return (
    <PageContainerTemplate
      title={`${updateData?.package_type ? formatPackageType(updateData?.package_type) : ''}  ${
        updateData?.name ?? ''
      }`}
      isSubtitleLoading={false}
      getFullElementId={(name: string, type: string) => `${name}-${type}`}
      preTitleStack={[<BackButton label={strings.Updates} onClick={() => navigate(PATHS.updates)} />]}
      leftStack={[]}
      rightStack={[
        <ShareLink
          key="shareLink"
          getFullElementId={(name: string, type?: string) => `update__${name}-${type}`}
          disabled={false}
          urlPath={urlPath}
          urlQuery={urlQuery}
        />
      ]}
      disableSpaGroupSelector={true}
    >
      {updateData ? (
        <>
          {bannerIsActive ? (
            <Banner
              isInternal={isInternal}
              isLoading={createApprovalResult.isLoading || addApplicableTailsResult.isLoading}
              isError={createApprovalResult.isError || addApplicableTailsResult.isError}
              isCreateApprovalSuccess={createApprovalResult.isSuccess}
              isAddedTailsSuccess={addApplicableTailsResult.isSuccess}
              updateData={updateData}
              onClickClose={onBannerClickClose}
            />
          ) : null}
          <div>
            <UpdateInformationTable
              update={updateData}
              displayAirlineColumn={isInternal}
              allowApproval={!isInternal && approvalAirlineCodes.includes(updateData.airline_code)}
            />
            {(internalNotes || Boolean(links?.length)) && isInternal && (
              <>
                <InternalNotesSection internalNotes={internalNotes} links={links} />
                <Divider style={{marginTop: 24, marginBottom: 10}} />
              </>
            )}

            {approvals != null && !isInternal && (
              <ApprovalFlow update={updateData} approvals={approvals} createApprovalResult={createApprovalResult} />
            )}

            {isInternal && (
              <>
                <AddApplicableTailsSection
                  packageData={updateData}
                  isLoading={addApplicableTailsResult.isLoading}
                  onAddAircraft={onAddAircraft}
                  isAdminUser={isAdmin}
                />
              </>
            )}

            <ApprovalHistoryContainer>
              <Divider style={{marginTop: 10, marginBottom: 10}} />
              <ApprovalHistoryTable
                updateName={updateData?.name}
                approvals={approvals}
                isLoading={isApprovalsLoading}
                error={isApprovalsError}
                applicableTailsNum={updateData.applicable_tails.length}
              />
            </ApprovalHistoryContainer>

            {isAdmin &&
              isInternal &&
              ![PackageStatuses.CLOSED, PackageStatuses.REJECTED, PackageStatuses.FULLY_APPROVED].includes(
                updateData.status
              ) && (
                <>
                  <AddUpdateSection>
                    <Button size="large" variant="contained" onClick={handleCloseUpdateClick} sx={{gap: spacing[8]}}>
                      <UpdateDisabledIcon />
                      Close Update
                    </Button>
                  </AddUpdateSection>

                  <CloseUpdateDialog
                    updateName={`${formatPackageType(updateData.package_type)} ${updateData.name}`}
                    open={closeUpdateDialogOpen}
                    updateOpen={setCloseUpdateDialogOpen}
                    onCloseClick={(reason: string): void => {
                      onClose(reason);
                    }}
                    isLoading={createApprovalResult.isLoading}
                  />
                </>
              )}
          </div>
        </>
      ) : null}
    </PageContainerTemplate>
  );
};

export default Update;
