/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Primary View Pages
 */

import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CloudUpload from '@mui/icons-material/CloudUpload';
import PeopleIcon from '@mui/icons-material/People';
import {PATHS} from './paths';
import Users from './pages/Users/Users';
import {generatePath} from 'react-router-dom';
import AvailableUpdates from './pages/AvailableUpdates/AvailableUpdates';
import ApprovalHistory from './pages/ApprovalHistory/ApprovalHistory';
import {AppConfigs, PageProps} from './types';
import CreateUpdate from './pages/CreateUpdate/CreateUpdate';
import Update from './pages/Update/Update';
import CppRequest from './pages/Cpp/CppRequest';
import CppRequestHistory from './pages/CppRequestHistory/CppRequestHistory';
import CppIntakeDetails from './pages/CppDetails/CppDetails';

type TabPagesType = {
  name: string;
  key: string;
  icon: JSX.Element;
  route: string;
  ariaLabel: string;
  component: React.FC<PageProps>;
  props: {
    title: string;
  };
};

const TAB_CPP_INTAKE_PAGES: TabPagesType[] = [
  {
    name: 'CPP Request History',
    key: 'cppRequestHistory',
    icon: <CloudUpload />,
    route: PATHS.cppRequestHistory,
    ariaLabel: 'cpp request history',
    component: CppRequestHistory,
    props: {
      title: 'Third Party Application Update Requests'
    }
  }
];

const TAB_APPROVAL_PAGES: TabPagesType[] = [
  {
    name: 'Updates',
    key: 'updates',
    icon: <AssignmentLateIcon />,
    route: PATHS.updates,
    ariaLabel: 'default page',
    component: AvailableUpdates,
    props: {
      title: 'Updates'
    }
  },
  {
    name: 'Approval history',
    key: 'approvals',
    icon: <PlaylistAddCheckIcon />,
    route: PATHS.approvals,
    ariaLabel: 'approval history page',
    component: ApprovalHistory,
    props: {
      title: 'Approval history'
    }
  },
  {
    name: 'Users',
    key: 'users',
    icon: <PeopleIcon />,
    route: generatePath(PATHS.users, {tabId: 'approvers'}),
    ariaLabel: 'users page',
    component: Users,
    props: {
      title: 'Users'
    }
  }
];

type NonTabPagesType = {
  key: string;
  route: string;
  component: React.FC<PageProps>;
  props: any;
};

const NON_TAB_CPP_PAGES: NonTabPagesType[] = [
  {
    key: 'cpp request',
    route: PATHS.cppRequestBase,
    component: CppRequest,
    props: {title: 'Application Container Update Request'}
  },
  {
    key: 'cpp request',
    route: PATHS.cppRequest,
    component: CppRequest,
    props: {title: 'Application Container Update Request'}
  },
  {
    key: 'cpp details',
    route: PATHS.cppDetail,
    component: CppIntakeDetails,
    props: {title: 'CPP Request Details'}
  }
];

const NON_TAB_APPROVAL_PAGES: NonTabPagesType[] = [
  {
    key: 'update',
    route: PATHS.update,
    component: Update,
    props: {}
  },
  {
    key: 'create-update',
    route: PATHS.createUpdate,
    component: CreateUpdate,
    props: {title: 'Create Update'}
  },
  {
    key: 'approvals-approvalId',
    route: PATHS.approval,
    component: ApprovalHistory,
    props: {title: 'Approval history'}
  }
];

/**
 * Gets the list of tab'd pages to route and display in tabs.
 * @param appConfigs App configurations
 * @returns List of pages to display
 */
export const getTabPages = (appConfigs: AppConfigs): TabPagesType[] => {
  if (!appConfigs) return [];

  const retVal: TabPagesType[] = [];
  if (appConfigs.dispatcherDisplay3ppIntakeViews) retVal.push(...TAB_CPP_INTAKE_PAGES);
  if (appConfigs.dispatcherDisplayApprovalsViews) retVal.push(...TAB_APPROVAL_PAGES);
  return retVal;
};

/**
 * Gets a list of non-tab'd pages to route.
 * @param appConfigs App configurations
 * @returns List of non-tab pages to route
 */
export const getNonTabPages = (appConfigs: AppConfigs): NonTabPagesType[] => {
  if (!appConfigs) return [];

  const retVal: NonTabPagesType[] = [];
  if (appConfigs.dispatcherDisplay3ppIntakeViews) retVal.push(...NON_TAB_CPP_PAGES);
  if (appConfigs.dispatcherDisplayApprovalsViews) retVal.push(...NON_TAB_APPROVAL_PAGES);
  return retVal;
};
