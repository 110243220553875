/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request Secrets Step
 */
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Section} from '../../../components/Section';
import {CppButtonSection, RequestBox, SubSectionContainer} from './CppRequestShared';
import {FormInput} from '../../../components/FormInput';
import {CppRequestSecretsFormSchema, CppRequestSecretsFormType, CppStepProps} from '../types';
import {clearFormObject} from '../../../utils/utils';
import {useCppRequestDraftStore} from '../../../utils/stores/cppRequestDraftStore';

const CppRequestSecretsStep: React.FC<CppStepProps> = props => {
  const {secrets} = props.inputs;
  const {drafts} = useCppRequestDraftStore(state => ({
    drafts: state.drafts
  }));
  const draft = props.id ? drafts.get(props.id) : null;

  const formMethods = useForm<CppRequestSecretsFormType>({
    defaultValues: secrets ?? undefined,
    resolver: yupResolver(CppRequestSecretsFormSchema)
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <RequestBox>
          <Section
            title="Secrets & Certificates"
            bodyText="Provide details about changes to any secrets or certificates. Please be careful not to share sensitive information as this method is currently not encrypted."
            layout="vertical"
          >
            <SubSectionContainer>
              <FormInput
                name="secrets"
                defaultValue={draft?.secrets || undefined}
                label="Secrets & Certificates"
                multiline
              />
            </SubSectionContainer>
          </Section>
        </RequestBox>
      </FormProvider>
      <CppButtonSection
        {...props}
        verify={formMethods.handleSubmit}
        clear={() => formMethods.reset(clearFormObject(secrets))}
        valueKey="secrets"
        id={props.id as string}
      />
    </>
  );
};

export default CppRequestSecretsStep;
