/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: TailListDropdown component
 */

import styled from '@emotion/styled';
import {useState} from 'react';
import {Dispatcher} from '../theme/Colors';
import TailList from './TailList';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {fonts, spacing} from '@vst/beam';
import {Divider, Typography} from '@mui/material';
import {checkTails} from '../utils/utils';
import {TailListLink} from './TailListLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16]};
`;

const Header = styled.button<{
  tails: string[];
}>`
  background: none;
  border: none;
  font: ${fonts.body.Small.Regular};
  color: ${Dispatcher.AccessibleGray};
  height: ${spacing[24]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :focus-visible {
    ${props => (props.tails.length > 0 ? null : `outline: none`)}
  }
`;

const TailListContainer = styled.div`
  margin: 0 0 ${spacing[8]} 0;
`;

/**
 * Display a openable/closeable tail list
 */
const TailListDropdown: React.FC<{
  tails: string[];
  tailListLinkPosition?: 'top' | 'bottom';
  defaultOpen?: boolean;
  displayDividerWhenClosed?: boolean;
}> = ({tails, tailListLinkPosition, defaultOpen, displayDividerWhenClosed}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Container>
      <Header
        disabled={tails.length === 0 && true}
        tails={tails}
        onClick={() => {
          setOpen(open => !open);
        }}
      >
        <Typography color={open ? 'primary' : Dispatcher.AccessibleGray}>{checkTails(tails)}</Typography>
        {tails.length > 0 && (open ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />)}
      </Header>
      {tails.length > 0 && (
        <>
          {open || displayDividerWhenClosed ? <Divider /> : null}
          {open && (
            <>
              {tailListLinkPosition === 'top' && <TailListLink tails={tails} />}
              <TailListContainer>
                <TailList tails={tails} />
              </TailListContainer>
              {tailListLinkPosition === 'bottom' && <TailListLink tails={tails} />}
            </>
          )}
        </>
      )}
    </Container>
  );
};

TailListDropdown.defaultProps = {
  defaultOpen: false,
  displayDividerWhenClosed: true
};
export default TailListDropdown;
