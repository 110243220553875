/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request Container Changes Step
 */
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Section} from '../../../components/Section';
import {CppButtonSection, RequestBox, SubSectionContainer} from './CppRequestShared';
import {FormInput} from '../../../components/FormInput';
import {CppRequestContainerFormSchema, CppRequestContainerFormType, CppStepProps} from '../types';
import CustomDropzone from '../../../components/CustomDropzone';
import {Box, Typography} from '@mui/material';
import {Dispatcher} from '../../../theme/Colors';
import {spacing} from '@vst/beam';
import {clearFormObject} from '../../../utils/utils';
import {useCppRequestDraftStore} from '../../../utils/stores/cppRequestDraftStore';

const EXTENSIONS: string[] = ['.tar.gz', '.tgz'];

const CustomSectionText: React.FC<React.PropsWithChildren> = ({children}) => (
  <Typography color={Dispatcher.AccessibleGray} variant="body1" sx={{marginBottom: spacing[16]}}>
    {children}
  </Typography>
);

const CppRequestContainerStep: React.FC<CppStepProps> = props => {
  const {container} = props.inputs;
  const {drafts} = useCppRequestDraftStore(state => ({
    drafts: state.drafts
  }));
  const draft = props.id ? drafts.get(props.id) : null;
  const [imageFiles, setImageFiles] = useState<File[]>(container?.imageFiles || []);

  const formMethods = useForm<CppRequestContainerFormType>({
    defaultValues: container ?? undefined,
    resolver: yupResolver(CppRequestContainerFormSchema)
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <RequestBox>
          <Section
            title="Container Changes"
            bodyText="Provide a concise description of all changes to the container(s)."
            layout="vertical"
          >
            <SubSectionContainer>
              <FormInput
                name="containerChanges"
                defaultValue={draft?.containerChanges}
                label="Container Changes"
                multiline
                required
                triggerValidation
              />
              <CustomSectionText>
                Provide details about changes to the functionality that are being implemented or removed from the
                container. If you have a new container image, you can upload it below.
              </CustomSectionText>
              <FormInput
                name="functionalChanges"
                defaultValue={draft?.functionalChanges}
                label="Functional Changes"
                multiline
              />
              <CustomDropzone
                disabled={false}
                files={imageFiles}
                setFiles={setImageFiles}
                accept={{'application/gzip': EXTENSIONS}}
                keyPfx="images-dropzone"
              />
              <Box sx={{height: spacing[8]}} />
              <CustomSectionText>
                Provide details on how the container interfaces with other internal services.
              </CustomSectionText>
              <FormInput
                name="containerInterfaces"
                defaultValue={draft?.containerInterfaces}
                label="Container Interfaces"
                multiline
              />
              <CustomSectionText>Provide details about any new or deprecated volume mounts.</CustomSectionText>
              <FormInput name="volumeMounts" defaultValue={draft?.volumeMounts} label="Volume Mounts" multiline />
              <CustomSectionText>
                Provide details about any new ports to be exposed or ports that should no longer be exposed.
              </CustomSectionText>
              <FormInput name="ports" defaultValue={draft?.ports} label="Ports" multiline />
              <CustomSectionText>
                Provide details about any changes to traffic policies as well as access policies from external
                endpoints. Note that the default access method is DNS. For other access types, provide explicit details.
              </CustomSectionText>
              <FormInput
                name="trafficPolicies"
                defaultValue={draft?.trafficPolicies}
                label="Traffic Policies"
                multiline
              />
              <CustomSectionText>
                Provide details about all new end points added and those which are to be removed, including changes to
                hostnames and protocols (HTTPS).
              </CustomSectionText>
              <FormInput name="endPoints" defaultValue={draft?.endPoints} label="End Points" multiline />
              <CustomSectionText>
                Provide details about expected changes to resources (CPU, Memory, etc.) Note that increasing storage for
                the container can be accommodated pending a review of the resource quota allocated for the namespace.
                Decreasing the storage will impact data stored in the existing volume during upgrade scenarios.
              </CustomSectionText>
              <FormInput
                name="resourceChanges"
                defaultValue={draft?.resourceChanges}
                label="Resource Changes"
                multiline
              />
              <CustomSectionText>Provide details about any changes to environment variables.</CustomSectionText>
              <FormInput
                name="environmentVariables"
                defaultValue={draft?.environmentVariables}
                label="Environment Variables"
                multiline
              />
            </SubSectionContainer>
          </Section>
        </RequestBox>
      </FormProvider>
      <CppButtonSection
        {...props}
        verify={callback =>
          formMethods.handleSubmit(
            data => {
              callback({...data, imageFiles});
            },
            () => {
              document.getElementById('CppFormScrollElement')?.scrollIntoView({behavior: 'smooth'});
            }
          )
        }
        clear={() => {
          formMethods.reset(clearFormObject({...container, imageFiles: undefined}));
          setImageFiles([]);
        }}
        valueKey="container"
        id={props.id as string}
      />
    </>
  );
};

export default CppRequestContainerStep;
