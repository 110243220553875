/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: DateTime component
 */

import styled from '@emotion/styled';
import {formatDate, formatTime} from '../utils/gridUtils';
import {Typography} from '@mui/material';
import {colors} from '@vst/beam';

const DateTimeContainer = styled.time`
  display: flex;
  gap: 8px;
`;

const TimeContainer = styled.time`
  display: flex;
  gap: 1px;
`;

/**
 * Display date and time
 */
const DateTime: React.FC<{dateTime: string}> = ({dateTime}) => {
  return (
    <DateTimeContainer dateTime={dateTime}>
      <Typography variant="button">{formatDate(dateTime)}</Typography>
      <TimeContainer>
        <Typography display="inline" variant="body1" color={colors.gray[400]}>
          (
        </Typography>
        <Typography display="inline" variant="body1">
          {formatTime(dateTime)}
        </Typography>
        <Typography display="inline" variant="body1" color={colors.gray[400]}>
          )
        </Typography>
      </TimeContainer>
    </DateTimeContainer>
  );
};
export default DateTime;
