/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: BackButton component
 */

import styled from '@emotion/styled';
import {Button} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackContainer = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing[4]};
`;

const StyledTitle = styled.span`
  font-size: 18px;
`;

/**
 * A back button in the MenuBar
 */
const BackButton: React.FC<{
  label: string;
  onClick: () => void;
  color?: string;
  hoverColor?: string;
  focusColor?: string;
  activeColor?: string;
}> = ({label, onClick, color, hoverColor, focusColor, activeColor}) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        padding: `0px 0px`,
        color: color ? color : colors.gray[500],
        borderRadius: 0,
        borderTop: `2px solid ${colors.white}`,
        borderBottom: `2px solid ${colors.white}`,
        '&:hover': {
          color: hoverColor ? hoverColor : colors.violet[500],
          backgroundColor: colors.white,
          borderBottom: `2px solid ${colors.white}`
        },
        '&:focus': {
          color: focusColor ? focusColor : colors.violet[500],
          borderBottom: `2px solid ${focusColor ? focusColor : colors.violet[500]}`
        },
        '&:active': {
          color: activeColor ? activeColor : colors.black,
          backgroundColor: colors.white,
          borderBottom: `2px solid ${colors.white}`
        }
      }}
      disableRipple
    >
      <BackContainer>
        <ArrowBackIcon sx={{width: 24, height: 24}} />
        <StyledTitle>{label}</StyledTitle>
      </BackContainer>
    </Button>
  );
};

export default BackButton;
