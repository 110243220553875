/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request Description Step
 */

import {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Typography} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {AirlineFormSelect} from '../../../components/AirlineFormSelect';
import {CppButtonSection, RequestBox, SubSectionContainer} from './CppRequestShared';
import {useAirlines} from '../../../api/queries/useAirlines';
import useBearStore from '../../../utils/stores/useBearStore';
import {CppRequestDetailsFormSchema, CppRequestDetailsFormType, CppStepProps} from '../types';
import {FormInput} from '../../../components/FormInput';
import {clearFormObject} from '../../../utils/utils';
import {Dispatcher} from '../../../theme/Colors';
import {spacing} from '@vst/beam';
import {useCppRequestDraftStore} from '../../../utils/stores/cppRequestDraftStore';

const CppRequestDetailsStep: React.FC<CppStepProps> = props => {
  const {details} = props.inputs;
  const {drafts} = useCppRequestDraftStore(state => ({
    drafts: state.drafts
  }));
  const draft = props.id ? drafts.get(props.id) : null;

  const {airlines} = useBearStore(state => ({airlines: state.airlines}));
  const {data: airlinePairs} = useAirlines();

  const filteredAirlinePairs = useMemo(
    () => airlinePairs?.filter(pair => airlines.includes(pair.code)) || [],
    [airlinePairs, airlines]
  );

  const formMethods = useForm<CppRequestDetailsFormType>({
    defaultValues: details || {
      airline: airlines[0],
      airlineName: ''
    },
    resolver: yupResolver(CppRequestDetailsFormSchema)
  });

  // Watch for changes in the 'airline' field of the form
  const airline = formMethods.watch('airline');

  useEffect(() => {
    // update 'airlineName' when 'airline' changes
    const name = airlinePairs?.find(pair => pair.code === airline)?.name;
    if (name) {
      formMethods.setValue('airlineName', name, {shouldValidate: true});
    }
  }, [airline, airlinePairs, formMethods]);

  return (
    <>
      <FormProvider {...formMethods}>
        <RequestBox>
          <SubSectionContainer>
            <Typography variant="h2" sx={{whiteSpace: 'nowrap'}}>
              Welcome to the new Application Container Update Request form
            </Typography>
            <Typography variant="body1" color={Dispatcher.AccessibleGray} sx={{marginBottom: spacing[32]}}>
              This form will guide you through all the details required to perform an update to a third-party
              application. Start by telling us which application you’d like to update.
            </Typography>
            <AirlineFormSelect
              defaultValue={draft?.airline}
              airlines={filteredAirlinePairs}
              disabled={airlines.length <= 1}
            />
            <FormInput
              name="application"
              label="Application"
              required
              triggerValidation
              defaultValue={draft?.application}
              placeholder="Which application would you like to update? For example, “Portal”"
            />
          </SubSectionContainer>
        </RequestBox>
      </FormProvider>
      <CppButtonSection
        {...props}
        verify={formMethods.handleSubmit}
        clear={() => formMethods.reset({...clearFormObject(details), airline: airlines[0]})}
        valueKey="details"
        id={props.id as string}
      />
    </>
  );
};

export default CppRequestDetailsStep;
