/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Intake Request Review component
 */

import {Box, Typography, Button, Divider, Alert} from '@mui/material';
import {spacing} from '@vst/beam';
import AttachmentIcon from '@mui/icons-material/Attachment';
import {Dispatcher} from '../theme/Colors';
import {replaceNewlines} from '../pages/Cpp/CppRequestSteps/CppRequestReviewStep';
import {isNil} from 'lodash';
import {SubSectionContainer} from '../pages/Cpp/CppRequestSteps/CppRequestShared';
import {CppRequestFinalType} from '../pages/Cpp/types';
import {EMPTY_ARRAY} from '../utils/utils';

interface ReviewItemProps {
  title: string;
  value: string | undefined;
  keyPfx: string;
}

interface ReviewSectionProps {
  title: string;
  children: React.ReactNode;
}

interface IntakeRequestReviewProps {
  airline: string;
  data: CppRequestFinalType;
  setActiveStep?: (step: number) => void;
}

export const ReviewItem: React.FC<ReviewItemProps> = ({title, value, keyPfx}) => (
  <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
    <Typography variant="body2">{title}</Typography>
    <Typography variant="body1">{replaceNewlines(value || '', keyPfx)}</Typography>
  </Box>
);

/**
 * Renders a review section with a title and children components.
 *
 * @param {string} title - The title of the review section.
 * @param {ReactNode} children - The child components to be rendered within the review section.
 * @returns {ReactElement} The rendered review section.
 */
export const ReviewSection: React.FC<ReviewSectionProps> = ({title, children}) => (
  <>
    <Divider />
    <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
      <Typography variant="body1" sx={{flex: 1, fontWeight: 700}}>
        {title}
      </Typography>
      <Box sx={{flex: 1, display: 'flex', flexFlow: 'column nowrap', gap: spacing[16]}}>{children}</Box>
    </Box>
  </>
);

/**
 * Renders a list of file items for review.
 *
 * @param title - The title of the file items.
 * @param fileNames - An array of file names to be displayed.
 * @param keyPfx - The prefix to be used for generating unique keys.
 * @param backStep - The step number to go back to when the "Go Back" button is clicked.
 * @param setActiveStep - A function to set the active step.
 * @returns The rendered ReviewFileItems component.
 */
export const ReviewFileItems: React.FC<{
  title: string;
  fileNames: string[];
  keyPfx: string;
  backStep?: number;
  setActiveStep?: (step: number) => void;
}> = ({title, fileNames, keyPfx, backStep, setActiveStep}) => {
  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
      <Typography variant="body2">{title}</Typography>
      {fileNames.length > 0 ? (
        fileNames.map((fileName, idx) => (
          <Box
            key={`${keyPfx}-${idx}`}
            sx={{display: 'flex', flewFlow: 'row nowrap', alignItems: 'center', gap: spacing[4]}}
          >
            <AttachmentIcon sx={{color: Dispatcher.AccessibleGrayIconsAndBorders}} />
            <Typography variant="body1">{fileName}</Typography>
          </Box>
        ))
      ) : !isNil(backStep) && setActiveStep ? (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={() => setActiveStep(backStep)}>
              <Typography variant="body2" color="inherit">
                Go Back
              </Typography>
            </Button>
          }
        >
          No container images attached
        </Alert>
      ) : (
        <Typography variant="body1">
          <br />
        </Typography>
      )}
    </Box>
  );
};

/**
 * a functional component that displays the details of a CPP request details form.
 *
 * @param {CppRequest} props.data - The CPP request data to be displayed.
 *
 * @returns {ReactElement} The React component representing the intake request review form.
 */
const IntakeRequestReview: React.FC<IntakeRequestReviewProps> = ({airline, setActiveStep, data}) => {
  return (
    <Box sx={{width: '800px', wordBreak: 'break-word'}}>
      <SubSectionContainer>
        <ReviewSection title="1. Details">
          <ReviewItem title="Airline" value={airline} keyPfx="airline" />
          <ReviewItem title="Application" value={data.details?.application} keyPfx="application" />
        </ReviewSection>
        <ReviewSection title="2. Container Changes">
          <ReviewItem title="Container Changes" value={data.container?.containerChanges} keyPfx="container-changes" />
          <ReviewItem
            title="Functional Changes"
            value={data.container?.functionalChanges}
            keyPfx="functional-changes"
          />
          <ReviewFileItems
            title="Container Images"
            fileNames={data.container?.imageFiles?.map(file => file.name) || []}
            keyPfx="image-files"
            backStep={1}
            setActiveStep={setActiveStep}
          />
          <ReviewItem
            title="Container Interfaces"
            value={data.container?.containerInterfaces}
            keyPfx="container-interfaces"
          />
          <ReviewItem title="Ports" value={data.container?.ports} keyPfx="ports" />
          <ReviewItem title="Traffic Policies" value={data.container?.trafficPolicies} keyPfx="traffic-policies" />
          <ReviewItem title="End Points" value={data.container?.endPoints} keyPfx="end-points" />
          <ReviewItem title="Resource Changes" value={data.container?.resourceChanges} keyPfx="resource-changes" />
          <ReviewItem
            title="Environment Variables"
            value={data.container?.environmentVariables}
            keyPfx="environment-variables"
          />
        </ReviewSection>
        <ReviewSection title="3. Secrets & Certificates">
          <ReviewItem title="Details" value={data.secrets?.secrets} keyPfx="secret-details" />
        </ReviewSection>
        <ReviewSection title="4. Additional Details & Attachments">
          <ReviewItem title="Details" value={data.additional?.details} keyPfx="additional-details" />
          <ReviewFileItems
            title="Attachments"
            fileNames={data.additional?.otherFiles?.map(file => file.name) || EMPTY_ARRAY}
            keyPfx="additional-details"
          />
          <ReviewItem title="Lab Only" value={data.additional?.labOnly ? 'Yes' : 'No'} keyPfx="lab-only" />
        </ReviewSection>
      </SubSectionContainer>
    </Box>
  );
};

export default IntakeRequestReview;
