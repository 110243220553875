/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: TabsWithContent Component
 */

import {Box, SvgIconTypeMap, Tab, Tabs} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {colors, spacing} from '@vst/beam';
import {useState} from 'react';

export interface TabsPageTab {
  id: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  label: string;
  content: () => React.ReactNode;
  headerContent: React.ReactNode;
}

/**
 * Tabs with matching description and content
 */
export const TabsWithContent: React.FC<{
  tabs: TabsPageTab[];
  defaultTabIndex?: number;
  onTabChange?: (tab: TabsPageTab) => void;
}> = ({tabs, defaultTabIndex, onTabChange}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(defaultTabIndex ?? 0);
  const selectedTab = tabs[selectedTabIndex];
  return (
    <Box>
      <Box sx={{backgroundColor: colors.white, display: 'flex', flexDirection: 'column', marginBottom: spacing[16]}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={selectedTabIndex}
            onChange={(_, newTabIndex: number) => {
              setSelectedTabIndex(newTabIndex);
              onTabChange?.(tabs[newTabIndex]);
            }}
            sx={{marginLeft: spacing[32], marginRight: spacing[32]}}
          >
            {tabs.map(tab => (
              <Tab key={tab.id} icon={<tab.icon />} iconPosition="start" label={tab.label} />
            ))}
          </Tabs>
        </Box>
        <Box paddingX={spacing[32]} paddingY={spacing[24]}>
          {selectedTab?.headerContent}
        </Box>
      </Box>
      {selectedTab?.content()}
    </Box>
  );
};
