export const versionTypes = ['config', 'modem', 'server', 'ife', 'portal', 'vcdm', 'helm_chart', 'other'] as const;
export type VersionType = typeof versionTypes[number];

export const versionLabelRecord: Record<VersionType, string> = {
  config: 'Config Version',
  modem: 'Modem Version',
  server: 'Server Version',
  ife: 'Snapshot ID',
  portal: 'Portal Version',
  vcdm: 'VCDM Version',
  helm_chart: 'Helm Chart Version',
  other: 'Version'
};
