/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request View
 */

import {useCallback, useEffect} from 'react';
import {Box, Button, LinearProgress, Modal, Theme, Typography, styled, useTheme} from '@mui/material';
import {spacing} from '@vst/beam';
import {CppRequestFinalType} from './types';
import useHandleSubmitCppRequest, {ProgressStates, ProgressStatusType} from './useHandleSubmitCppRequest';
import {Airline, useAirlines} from '../../api/queries/useAirlines';
import {PATHS} from '../../paths';
import {useNavigate} from 'react-router-dom';

const InnerModalBox = styled(Box)`
  margin: 0 ${spacing[16]};
`;

type DisplayTextType = {title: string; subtitle: string; meta: JSX.Element; bar: JSX.Element};

/**
 * Converts the current progress to display messages
 * @param uploadProgress Upload Progress
 * @param theme Theme
 * @returns Display data
 */
const getDisplayText = (uploadProgress: ProgressStatusType, theme: Theme): DisplayTextType => {
  let retVal: DisplayTextType;

  switch (uploadProgress.state) {
    case ProgressStates.complete:
      retVal = {
        title: 'Submission complete!',
        subtitle: 'Your intake request has been successfully submitted. You can close this window now.',
        meta: (
          <Typography variant="h2" color={theme.palette.success.main}>
            Success
          </Typography>
        ),
        bar: <LinearProgress variant="determinate" value={100} />
      };
      break;
    case ProgressStates.error:
      retVal = {
        title: 'Submission error',
        subtitle: 'There has been a problem submitting your intake request.',
        meta: (
          <Typography variant="h2" color={theme.palette.error.main}>
            Error
          </Typography>
        ),
        bar: <LinearProgress variant="determinate" value={0} />
      };
      break;
    case ProgressStates.finishing:
      retVal = {
        title: 'Submission in progress',
        subtitle: 'Please do not close this window or refresh your browser.',
        meta: (
          <Typography variant="h2" color={theme.palette.primary.main}>
            Finishing
          </Typography>
        ),
        bar: <LinearProgress />
      };
      break;
    default:
      retVal = {
        title: 'Submission in progress',
        subtitle: 'Please do not close this window or refresh your browser.',
        meta: (
          <Typography variant="h2" color={theme.palette.primary.main}>
            {uploadProgress.progress || 'Idle'}
          </Typography>
        ),
        bar: <LinearProgress />
      };
      break;
  }

  return retVal;
};
const CppRequestSubmitModal: React.FC<{
  id: string;
  inputs: CppRequestFinalType;
  submitMode: boolean;
  clearForm: (keepData: boolean) => void;
}> = ({id, inputs, clearForm, submitMode}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {handleRequestSubmit, uploadProgress, resetProgress} = useHandleSubmitCppRequest();

  const {data: airlinePairs} = useAirlines();
  const airline: Airline | undefined =
    airlinePairs && inputs && inputs.details
      ? airlinePairs.find(pair => pair.code === inputs.details?.airline) // Linter needs the extra ? here for some reason
      : undefined;

  useEffect(() => {
    if (!submitMode || !airline) return;
    handleRequestSubmit(id, inputs, airline);

    // Only submit when we are submitting (airline should come along for the ride)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitMode]);

  const onClose = useCallback(() => {
    const requestSuccess = uploadProgress.state === ProgressStates.complete;
    resetProgress();
    clearForm(uploadProgress.state === ProgressStates.error);
    if (requestSuccess) {
      navigate(PATHS.cppRequestHistory, {state: {requestSuccess}});
    }
  }, [resetProgress, clearForm, uploadProgress.state, navigate]);

  const displayText = getDisplayText(uploadProgress, theme);

  return (
    <div>
      <Modal open={submitMode} onClose={() => {}} disableEscapeKeyDown={true} disablePortal={true}>
        <>
          <Box
            component="div"
            sx={{
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
              width: 600,
              pt: spacing[16],
              pb: spacing[16],
              position: 'absolute',
              borderRadius: '8px',
              display: 'flex',
              flexFlow: 'column nowrap',
              gap: spacing[16]
            }}
          >
            <InnerModalBox>
              <Typography variant="body2" color={theme.palette.text.primary} sx={{fontWeight: 'bold'}}>
                {displayText.title}
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {displayText.subtitle}
              </Typography>
            </InnerModalBox>
            <InnerModalBox sx={{textAlign: 'center'}}>{displayText.meta}</InnerModalBox>
            {displayText.bar}
            <InnerModalBox sx={{textAlign: 'right'}}>
              <Button
                size="medium"
                variant="contained"
                onClick={onClose}
                disabled={
                  uploadProgress.state !== ProgressStates.complete && uploadProgress.state !== ProgressStates.error
                }
              >
                Done
              </Button>
            </InnerModalBox>
          </Box>
        </>
      </Modal>
    </div>
  );
};

export default CppRequestSubmitModal;
