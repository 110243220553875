/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request View
 */
import {v4 as uuidv4} from 'uuid';
import React, {useCallback, useState} from 'react';
import {spacing} from '@vst/beam';
import ClearIcon from '@mui/icons-material/Clear';
import {Box, Button, Fade, Step, StepLabel, Stepper, Typography} from '@mui/material';
import {ModalDialog} from '../../components/ModalDialog';
import {useNavigate} from 'react-router-dom';
import {PageProps} from '../../types';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import CppRequestDetailsStep from './CppRequestSteps/CppRequestDetailsStep';
import {convertToFinalType, RequestBox, STEPS} from './CppRequestSteps/CppRequestShared';
import CppRequestContainerStep from './CppRequestSteps/CppRequestContainerStep';
import CppRequestSecretsStep from './CppRequestSteps/CppRequestSecretsStep';
import CppRequestAdditionalStep from './CppRequestSteps/CppRequestAdditionalStep';
import {CppRequestFinalType, CppStepProps} from './types';
import CppRequestSubmitModal from './CppRequestSubmitModal';
import CppRequestReviewStep from './CppRequestSteps/CppRequestReviewStep';
import {strings} from '../../locale/strings';
import {useParams} from 'react-router-dom';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import {colors} from '@vst/beam';
import {PATHS} from '../../paths';
import {useCppRequestDraftStore} from '../../utils/stores/cppRequestDraftStore';
import {mapDraftToFetchedFormat} from '../../utils/cppUtils';

const STEP_COMPONENTS: React.FC<CppStepProps>[] = [
  CppRequestDetailsStep,
  CppRequestContainerStep,
  CppRequestSecretsStep,
  CppRequestAdditionalStep,
  CppRequestReviewStep
];

export const initialInputValues: CppRequestFinalType = {
  details: null,
  container: null,
  secrets: null,
  additional: null
};

const DraftSavedContainer = styled(Box)`
  display: flex;
  gap: ${spacing[4]};
  color: ${colors.green[500]};
`;

/**
 * Represents the CppRequest component.
 * This component is responsible for rendering the CppRequest page.
 *
 * @component
 * @param {PageProps} props - The page props.
 * @param {string} props.title - The title of the page.
 * @returns {JSX.Element} The CppRequest component.
 */
const CppRequest: React.FC<PageProps> = ({ title }) => {
  const {id} = useParams<string>();
  const [draftId] = useState<string>(id || uuidv4());
  const {drafts} = useCppRequestDraftStore(state => ({
    drafts: state.drafts
  }));
  const draft = drafts.get(draftId);
  const [inputs, setInputs] = useState<CppRequestFinalType>(
    draft ? convertToFinalType(mapDraftToFetchedFormat(draftId, draft)) : {...initialInputValues}
  );
  const [submitMode, setSubmitMode] = useState<boolean>(false);
  const [displayDraftSaved, setDisplayDraftSaved] = useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<number>(drafts.get(draftId)?.lastActiveStep || 0);

  const clearForm = useCallback(
    (keepData: boolean = false) => {
      setSubmitMode(false);

      if (keepData) return;

      setInputs({...initialInputValues});
      setDisplayDraftSaved(false);
      setActiveStep(0);
    },
    [setInputs, setDisplayDraftSaved, setActiveStep]
  );

  const handleClickOpen = () => setOpenModal(true);

  const handleContinueEdit = () => setOpenModal(false);

  const saveDraft = () => {
    setDisplayDraftSaved(true);
    setTimeout(() => setDisplayDraftSaved(false), 5000);
  };

  const StepDisplay = STEP_COMPONENTS[activeStep];
  return (
    <>
      <PageContainerTemplate
        title={title}
        isSubtitleLoading={false}
        subtitle={''}
        getFullElementId={(name: string, type: string) => `${name}-${type}`}
        leftStack={[]}
        rightStack={[
          <Fade key="draft-fade" in={displayDraftSaved} timeout={500}>
            <DraftSavedContainer>
              <CheckIcon />
              <Typography
                variant="body2"
                sx={{
                  color: `${colors.green[500]}`
                }}
              >
                Draft Saved
              </Typography>
            </DraftSavedContainer>
          </Fade>,
          <Button
            onClick={draft ? handleClickOpen : () => navigate(PATHS.cppRequestHistory)}
            aria-label="Close"
            key="CloseButton"
            startIcon={<ClearIcon />}
            sx={{color: colors.gray[800]}}
          >
            Close
          </Button>
        ]}
      >
        {/* I need this to get scrolling to the top to not mess up the page layout as per StackOverflow's help */}
        <Box sx={{position: 'relative'}}>
          <Box id="CppFormScrollElement" sx={{position: 'absolute', top: '-1000px', left: 0}} />
        </Box>
        <Box sx={{minWidth: '800px', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}}>
          <RequestBox sx={{padding: `${spacing[16]} 0`, width: `calc(100% - ${spacing[48]})`}}>
            <Stepper activeStep={activeStep}>
              {STEPS.map((step, index) => {
                return (
                  <Step index={index} key={`cpp-request-step-${index}`} completed={index < activeStep}>
                    <StepLabel
                      StepIconProps={{
                        sx: {
                          '& text': {
                            fontSize: '16px'
                          }
                        }
                      }}
                    >
                      {step}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </RequestBox>
          <Box sx={{width: '800px'}}>
            {StepDisplay ? (
              <StepDisplay
                key={`cpp-step-display-${activeStep}`}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setInputs={setInputs}
                setDraftSaved={saveDraft}
                inputs={inputs}
                id={draftId}
                setSubmitMode={setSubmitMode}
              />
            ) : null}

            <CppRequestSubmitModal id={draftId} inputs={inputs} clearForm={clearForm} submitMode={submitMode} />
          </Box>
        </Box>
      </PageContainerTemplate>
      <ModalDialog
        title={strings.CppRequestHistory.DraftModal['heading']}
        displayState="default"
        okText={strings.Close}
        cancelText={strings.ContinueEditing}
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          navigate(PATHS.cppRequestHistory);
        }}
        onClose={handleContinueEdit}
      >
        <Typography variant="body1">{strings.CppRequestHistory.DraftModal['body']}</Typography>
      </ModalDialog>
    </>
  );
};

export default CppRequest;
