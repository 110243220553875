/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common utilities
 */

import {apiBaseUrl} from './config';
import {logout} from './auth';

export const EMPTY_ARRAY = [];

/**
 * Ensure value is not null or undefined.
 * Useful for filtering nulls out of arrays.
 * @param value value to test
 * @returns value is not null
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const checkTails = (tails: string[] | undefined) =>
  tails?.length === 0 ? '–' : `${tails?.length} ${tails?.length === 1 ? 'tail' : 'tails'}`;

/*
 * Url to ComAv Tail List with selected tails
 * @param urlBase base url
 * @param tails tails to include in the filter
 * @returns url
 */
export const comAvTailListLinkUrl = (urlBase: string, tails: string[]) =>
  `${new URL(urlBase).origin}/comav/lists/tail?${tails.map((tail, i) => `${i + 1}-tailId%5B0%5D=${tail}`).join('&')}`;

/**
 * Perform fetch with appropriate base url and headers
 * @param route
 * @param body
 * @param abortSignal
 * @returns fetch response
 */
export const postWithHeaders = async (
  route: string,
  body: BodyInit | null | undefined,
  abortSignal?: AbortSignal
): Promise<Response> =>
  await fetch(`${apiBaseUrl}/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.token}`
    },
    body,
    signal: abortSignal
  });

/**
 * Fetcher function for queries & mutations using React Query
 * @param route
 * @param params
 * @return Promise<any>
 */
export const fetcher = async ({route, params}: {route: string; params: Record<string, any> | undefined}) => {
  const response = await postWithHeaders(route, JSON.stringify(params));
  if (response.status === 401) {
    const errorMessage = `Received response status: ${response.status}, logging out`;
    console.error(errorMessage);
    logout();
    throw new Error(errorMessage);
  } else if (response.status >= 300) {
    const errorResponse = await response.json();
    const errorMessage = {
      message: `Received response status: ${response.status}`,
      response: errorResponse
    };
    console.error(JSON.stringify(errorMessage));
    throw new Error(JSON.stringify(errorMessage));
  }
  try {
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      return await response.json();
    } else {
      return null;
    }
  } catch (err) {
    // API returns no data for response
    if (err instanceof SyntaxError) {
      console.debug(`${route} API returns no data in response.`);
    } else {
      throw err;
    }
  }

  return {};
};

/**
 * Clear the input object of a react form
 * @param obj Input object of a react form
 * @returns Cleared input object
 */
export const clearFormObject = (obj: any) =>
  obj
    ? Object.entries(obj).reduce((acc: Record<string, any>, [key, value]) => {
        if (typeof value === 'string') acc[key] = '';
        else acc[key] = value;

        return acc;
      }, {})
    : {};
