/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Modal component
 */
import styled from '@emotion/styled';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import CircularProgress from '@mui/material/CircularProgress';
import {Dispatcher} from '../theme/Colors';
import React from 'react';
import {strings} from '../locale/strings';

const DialogTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[24]} 0px;
  width: 100%;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: ${spacing[8]};
  width: 100%;
  flex: none;
  flex-grow: 1;
`;

const LoadingIconTextContainer = styled.div`
  height: 176px;
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${spacing[16]};
  padding: ${spacing[24]} 0px ${spacing[40]};
`;

const LoadingDisplay: React.FC<{text?: string}> = ({text}) => (
  <LoadingIconTextContainer>
    <CircularProgress size="24px" color="primary" />
    <Typography display="inline" variant="subtitle1" color={Dispatcher.AccessibleGray} style={{textAlign: 'center'}}>
      {text}
    </Typography>
  </LoadingIconTextContainer>
);

export type ModalDisplayState = 'default' | 'loading';

/**
 * Generic Modal Dialog
 */
export const ModalDialog: React.FC<{
  title: string;
  text?: string | React.ReactNode; // Use <Typography> for rich text, string otherwise
  open: boolean;
  displayState: ModalDisplayState;
  onOk: () => void;
  onClose: () => void;
  children?: React.ReactNode;
  okText?: string;
  cancelText?: string;
  loadingText?: string;
  okButtonDisabled?: boolean;
}> = ({
  title,
  text,
  open,
  displayState,
  onOk,
  onClose,
  okText,
  cancelText,
  loadingText,
  okButtonDisabled,
  children
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitleContainer>
        <DialogTitle variant="h5" textTransform="none" letterSpacing="0.2px" color={colors.gray[800]}>
          {title}
        </DialogTitle>
      </DialogTitleContainer>
      <>
        {displayState === 'loading' ? (
          <>
            <Divider />
            <LoadingDisplay text={loadingText} />
          </>
        ) : (
          <>
            {text && (
              <>
                <Divider />
                <DialogContent sx={{padding: `${spacing[32]} ${spacing[24]}`}}>
                  <Typography display="inline" variant="subtitle1">
                    {text}
                  </Typography>
                </DialogContent>
              </>
            )}
            {children && (
              <>
                <Divider />
                <DialogContent sx={{padding: `${spacing[32]} ${spacing[24]}`}}>{children}</DialogContent>
              </>
            )}
          </>
        )}
        <>
          <Divider />
          <DialogActions sx={{padding: `${spacing[32]} ${spacing[24]}`}}>
            <ButtonsContainer>
              <Button size="large" variant="outlined" onClick={onClose} sx={{minWidth: 50, boxSizing: 'content-box'}}>
                {cancelText}
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={onOk}
                disabled={okButtonDisabled || displayState === 'loading'}
                sx={{minWidth: 50, boxSizing: 'content-box'}}
              >
                {okText}
              </Button>
            </ButtonsContainer>
          </DialogActions>
        </>
      </>
    </Dialog>
  );
};

ModalDialog.defaultProps = {
  okText: strings.Ok,
  cancelText: strings.Cancel,
  loadingText: strings.Loading,
  okButtonDisabled: false
};
