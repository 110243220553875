/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request Share Components
 */
import {Box, Button, Typography} from '@mui/material';
import styled from '@emotion/styled';
import {spacing} from '@vst/beam';
import {CppButtonSectionProps, CppRequest, CppRequestDraft, CppRequestFinalType} from '../types';
import {Dispatcher} from '../../../theme/Colors';
import {useCppRequestDraftStore} from '../../../utils/stores/cppRequestDraftStore';
import moment from 'moment';
import useBearStore from '../../../utils/stores/useBearStore';

export const STEPS = ['Details', 'Containers', 'Secrets & Certificates', 'Additional', 'Review'];

export const RequestBox = styled(Box)`
  margin: ${spacing[16]} ${spacing[48]};
`;

export const SubSectionContainer = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  margin-top: ${spacing[16]};
  gap: ${spacing[16]};
`;

export const CustomSectionText: React.FC<React.PropsWithChildren> = ({children}) => (
  <Typography color={Dispatcher.AccessibleGray} variant="body1" sx={{marginBottom: spacing[16]}}>
    {children}
  </Typography>
);

type OnValidCallType = (newValues: any) => void;

export const CppButtonSection: React.FC<
  CppButtonSectionProps & {
    id: string;
    verify: (onValid: OnValidCallType) => OnValidCallType;
    clear: (() => void) | null;
    valueKey: string;
  }
> = ({id, activeStep, setActiveStep, verify, clear, setInputs, setDraftSaved, valueKey, setSubmitMode}) => {
  const submitStage: boolean = STEPS.length - 1 === activeStep;
  const {drafts, updateDraft} = useCppRequestDraftStore(state => ({
    drafts: state.drafts,
    updateDraft: state.updateDraft
  }));
  const {init} = useBearStore(state => ({
    init: state.init
  }));
  return (
    <RequestBox>
      <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
        <Box sx={{flexGrow: 1}}>
          <Button
            disabled={activeStep === 0}
            size="medium"
            variant="contained"
            onClick={() => setActiveStep(activeStep - 1)}
            data-testid="StepBackButton"
          >
            Back
          </Button>
        </Box>
        <Box sx={{flexGrow: 0}}>
          {clear && (
            <Button
              size="medium"
              variant="outlined"
              onClick={clear}
              sx={{marginRight: spacing[8]}}
              data-testid="StepClearButton"
            >
              Clear
            </Button>
          )}
          <Button
            data-testid="StepNextButton"
            size="medium"
            variant="contained"
            onClick={verify(newValues => {
              setInputs(value => ({...value, [valueKey]: newValues}));

              // Grab any current values in the draft if editing a current draft
              const draft = drafts.get(id);

              if (submitStage) {
                setSubmitMode(true);
                return;
              }
              // Save new values in the draft
              const now = moment().toISOString();
              const updateValues: CppRequestDraft =
                activeStep === 0
                  ? {
                      ...draft,
                      ...newValues,
                      createdTimestamp: draft?.createdTimestamp || now,
                      creatorName: init.name,
                      creatorEmail: init.email,
                      updatedTimestamp: now,
                      lastActiveStep: Math.max(draft?.lastActiveStep || 0, activeStep)
                    }
                  : {
                      ...draft,
                      ...newValues,
                      updatedTimestamp: now,
                      lastActiveStep: Math.max(draft?.lastActiveStep || 0, activeStep)
                    };
              updateDraft(id, updateValues);
              setDraftSaved(true);
              setActiveStep(activeStep + 1);
            })}
          >
            {submitStage ? 'Submit' : 'Next'}
          </Button>
        </Box>
      </Box>
    </RequestBox>
  );
};

export const convertToFinalType = (request: CppRequest): CppRequestFinalType => {
  return {
    details: {
      airline: request.airlineCode,
      airlineName: request.airlineName,
      application: request.application
    },
    container: {
      containerChanges: request.containerChanges,
      functionalChanges: request.functionalChanges,
      containerInterfaces: request.containerInterfaces,
      volumeMounts: request.volumeMounts,
      ports: request.ports,
      trafficPolicies: request.trafficPolicies,
      endPoints: request.endpoints,
      resourceChanges: request.resourceChanges,
      environmentVariables: request.environmentVariables,
      imageFiles: request.containerImages?.map((imageName: string) => new File([imageName], imageName))
    },
    secrets: {
      secrets: request.secretsAndCertificates
    },
    additional: {
      details: request.additionalDetails,
      labOnly: request.labOnly,
      otherFiles: request.additionalAttachments?.map((attachment: string) => new File([attachment], attachment))
    }
  };
};
