/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ApprovalTypeAndTime component
 */

import styled from '@emotion/styled';
import DateTime from './DateTime';
import {colors} from '@vst/beam';
import {CheckCircle, CancelOutlined} from '@mui/icons-material';
import {ApprovalType} from '../api/queries/approvals';

const Container = styled.span`
  display: flex;
  gap: 8px;
`;

/**
 * Display approval type and time
 */
export const ApprovalTypeAndTime: React.FC<{approvalType: ApprovalType; createdTimestamp: string}> = ({
  approvalType,
  createdTimestamp
}) => {
  return (
    <Container data-testid="ApprovalTypeAndTime">
      {approvalType === 'approval' ? (
        <CheckCircle htmlColor={colors.green[500]} sx={{width: 24, height: 24}} />
      ) : (
        <CancelOutlined htmlColor={colors.alert[300]} sx={{width: 24, height: 24}} />
      )}
      <DateTime dateTime={createdTimestamp} />
    </Container>
  );
};
export default ApprovalTypeAndTime;
