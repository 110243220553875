/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: AirlineFormSelect component
 */

import React from 'react';
import {SxProps} from '@mui/material';
import {FormSelect} from './FormSelect';
import {Airline} from '../api/queries/useAirlines';
import {strings} from '../locale/strings';

export const AirlineFormSelect: React.FC<{
  name?: string;
  airlines: Airline[];
  multiple?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  defaultValue?: string;
}> = ({name = 'airline', airlines, multiple, sx, disabled, defaultValue}) => (
  <FormSelect
    name={name}
    label={multiple ? strings.Airlines : strings.Airline}
    required
    sx={sx}
    defaultValue={defaultValue}
    multiple={multiple}
    checkbox={multiple}
    options={Object.fromEntries(airlines.map(({code, name}) => [code, name]))}
    disabled={disabled}
  />
);
