/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: useAirlinesData
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';

export interface Airline {
  code: string;
  name: string;
  icao: string;
}

export const useAirlines = (params = {}) => {
  return useQuery<Airline[]>({
    queryKey: ['airlines'],
    queryFn: () => fetcher({route: 'airlines', params}),
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });
};
