/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: PartiallyApproved icon
 */
import * as React from 'react';

export const PartiallyApprovedIcon: React.FC<{color: string; width: number; height: number}> = ({
  color,
  width,
  height
}) => {
  return (
    <svg
      data-testid="PartiallyApprovedIcon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 2a8 8 0 0 1 0 16V4Z" fill={color} />
    </svg>
  );
};

export default PartiallyApprovedIcon;
