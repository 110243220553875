/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: approvers Route
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {PackageType} from './packages';

interface NotifiedUserWithoutAirline {
  email: string;
  package_types: PackageType[];
  airline_codes: string[];
}

interface NotifiedUsersData {
  notified_users: NotifiedUserWithoutAirline[];
}

export const useNotifiedUsers = (params: {airlines: string[] | null}) =>
  useQuery<NotifiedUsersData>({
    queryKey: ['notified-users', params.airlines],
    queryFn: () => fetcher({route: 'notified-users', params}),
    refetchOnWindowFocus: false
  });
