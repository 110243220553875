/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Section component
 */

import {Typography} from '@mui/material';
import styled from '@emotion/styled';
import {spacing} from '@vst/beam';
import React from 'react';
import {Dispatcher} from '../theme/Colors';

const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${spacing[80]};
  width: 100%;
`;

const ContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${spacing[16]};
  width: 100%;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`;

const HeaderContainerVertical = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: ${spacing[80]};
  align-items: start;
`;

const HeaderContainerHorizontal = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]};
  align-items: start;
  min-width: 179px;
`;

export const Section: React.FC<
  React.PropsWithChildren<{
    title: string;
    bodyText: string;
    titleColor?: string;
    bodyTextColor?: string;
    layout?: 'horizontal' | 'vertical';
    headerContent?: React.ReactNode;
  }>
> = ({title, bodyText, titleColor, bodyTextColor, layout, children, headerContent}) => {
  return layout === 'horizontal' ? (
    <ContainerHorizontal>
      <HeaderContainerHorizontal>
        <TextContainer>
          <Typography variant="h3" color={titleColor ? titleColor : 'primary'} sx={{whiteSpace: 'nowrap'}}>
            {title}
          </Typography>
          <Typography color={bodyTextColor ? bodyTextColor : Dispatcher.AccessibleGray}>{bodyText}</Typography>
        </TextContainer>
        {headerContent}
      </HeaderContainerHorizontal>
      {children}
    </ContainerHorizontal>
  ) : (
    <ContainerVertical>
      <HeaderContainerVertical>
        <TextContainer>
          <Typography variant="h3" color={titleColor ? titleColor : 'primary'} sx={{whiteSpace: 'nowrap'}}>
            {title}
          </Typography>
          <Typography color={bodyTextColor ? bodyTextColor : Dispatcher.AccessibleGray}>{bodyText}</Typography>
        </TextContainer>
        {headerContent}
      </HeaderContainerVertical>
      {children}
    </ContainerVertical>
  );
};
