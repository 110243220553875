/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Internal Notes component
 */
import styled from '@emotion/styled';
import {Typography} from '@mui/material';
import {spacing} from '@vst/beam';
import {Section} from './Section';
import {Dispatcher} from '../theme/Colors';
import React from 'react';
import ExternalLink from './ExternalLink';

const InternalNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[16]} ${spacing[32]};
  gap: ${spacing[32]};
  align-self: stretch;
  flex-grow: 0;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[8]};
  flex-grow: 0;
`;

const LinksListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${spacing[16]};
  flex-grow: 0;
  flex-wrap: wrap;
`;

/**
 * Get link display name and url
 * @param links
 */
const getLinkInfo = (link: string) => {
  const displayNameEnd = link.indexOf(']');
  const displayName = link.substring(1, displayNameEnd);
  const url = link.substring(displayNameEnd + 2, link.length - 1);
  return [displayName, url];
};

const InternalNotesSection: React.FC<{
  internalNotes?: string;
  links?: string[];
}> = ({internalNotes, links}) => {
  return (
    <InternalNotesContainer>
      <Section title="Internal Notes" bodyText="Visible to Viasat only">
        {internalNotes || 'No comments provided.'}
        {Boolean(links?.length) && (
          <LinksContainer>
            <Typography color={Dispatcher.AccessibleGray}>Links</Typography>
            <LinksListContainer>
              <>
                {links?.map(link => {
                  const linkInfo = getLinkInfo(link);
                  return (
                    <ExternalLink href={linkInfo[1]} sx={{padding: '0px'}}>
                      {linkInfo[0]}
                    </ExternalLink>
                  );
                })}
              </>
            </LinksListContainer>
          </LinksContainer>
        )}
      </Section>
    </InternalNotesContainer>
  );
};

export default InternalNotesSection;
