/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mutation query to add applicable tails.
 */

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import useBearStore from '../../utils/stores/useBearStore';

export interface AddApplicableTailsRequest {
  tails: Array<string>;
}

export const useAddApplicableTails = () => {
  const queryClient = useQueryClient();
  const airlines = useBearStore(state => state.airlines);
  return useMutation({
    mutationFn: ({packageId, params}: {packageId: string; params: AddApplicableTailsRequest}) =>
      fetcher({
        route: `packages/${packageId}/add-applicable-tails`,
        params
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['packages', airlines]);
      await queryClient.invalidateQueries(['approvals']);
    },
    onError: async () => {
      await queryClient.invalidateQueries(['packages', airlines]);
      await queryClient.invalidateQueries(['approvals']);
    }
  });
};
