/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Paths
 */

export const PATHS = {
  root: '/',
  updates: '/updates',
  update: '/updates/:updateId',
  createUpdate: '/update/create',
  approvals: '/approvals',
  approval: '/approvals/:approvalId',
  users: '/users/:tabId',
  cppRequestBase: '/cppRequest',
  cppRequest: '/cppRequest/:id',
  cppRequestHistory: '/cppRequestHistory',
  cppDetails: '/cppDetails',
  cppDetail: '/cppDetails/:id'
} as const;
