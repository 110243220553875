/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FormInput component
 */

import {SxProps, TextField} from '@mui/material';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

interface FormInputProps {
  name: string;
  required?: boolean;
  label: string;
  defaultValue?: string;
  helperText?: string;
  sx?: SxProps;
  multiline?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  triggerValidation?: boolean;
  dependentFields?: string[];
  placeholder?: string;
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  required = false,
  label,
  defaultValue = '',
  helperText = ' ',
  sx = {flex: '1'},
  multiline,
  type,
  triggerValidation = false,
  dependentFields = [],
  placeholder
}) => {
  const {
    trigger,
    control,
    formState: {errors, isSubmitted}
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field: {onChange, ...restFields}}) => (
        <TextField
          {...restFields}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onChange(event);
            triggerValidation && isSubmitted && trigger([name, ...dependentFields]);
          }}
          required={required}
          id={name}
          data-testid={name}
          label={label}
          error={!!errors[name]}
          helperText={!!errors[name] ? errors[name]?.message?.toString() : helperText}
          InputLabelProps={{
            shrink: true
          }}
          sx={sx}
          multiline={multiline}
          type={type}
          placeholder={placeholder}
        />
      )}
    />
  );
};
