/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Page container component
 */

import React, {useEffect} from 'react';
import {MenuBar, MenuBarProps} from '@viasat/insights-components';
import styled from '@emotion/styled';
import {spaComs} from '@viasat/insights-spa-package';

export const PageContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  height: calc(100% - 53px);
  width: 100%;
  overflow: scroll;
`;

const PageContainerTemplate: React.FC<MenuBarProps & React.PropsWithChildren & {disableSpaGroupSelector?: boolean}> = ({
  children,
  getFullElementId,
  preTitleStack,
  leftStack,
  rightStack,
  title,
  color,
  isSubtitleLoading,
  subtitle,
  tabs,
  disableSpaGroupSelector = false
}) => {
  useEffect(() => {
    spaComs.sendGroupDisable(disableSpaGroupSelector);
  }, [disableSpaGroupSelector]);
  return (
    <PageContent>
      <MenuBar
        getFullElementId={getFullElementId}
        title={title}
        subtitle={subtitle}
        preTitleStack={preTitleStack}
        leftStack={leftStack}
        rightStack={rightStack}
        color={color}
        isSubtitleLoading={isSubtitleLoading}
        tabs={tabs}
      />
      <ContentContainer id={getFullElementId('pageContent', 'container')}>{children}</ContentContainer>
    </PageContent>
  );
};

export default PageContainerTemplate;
