/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Update Status
 */

import {Done} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import styled from '@emotion/styled';
import {colors, spacing} from '@vst/beam';
import React from 'react';
import {Section} from './Section';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${spacing[24]};
`;

export const UpdateStatus: React.FC<
  React.PropsWithChildren<{icon?: 'done' | 'infoIcon'; title: string; bodyText: string}>
> = ({icon, title, bodyText, children}) => {
  const icons = {done: Done, infoIcon: InfoIcon};
  const Icon = icon != null ? icons[icon] : null;
  return (
    <Container>
      {Icon != null && <Icon color="primary" sx={{fontSize: 56}} />}
      <Section title={title} bodyText={bodyText} bodyTextColor={colors.gray[800]}>
        {children}
      </Section>
    </Container>
  );
};
