/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Handle submit CPP request hook
 */

import {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {CppSubmitRequestResp, useCppSubmitRequest} from '../../api/mutations/useCppSubmitRequest';
import {CppRequestFinalType} from './types';
import useCppRequestComplete from '../../api/mutations/useCppRequestComplete';
import {Airline} from '../../api/queries/useAirlines';
import {postWithHeaders} from '../../utils/utils';
import {useCppRequestDraftStore} from '../../utils/stores/cppRequestDraftStore';

export enum ProgressStates {
  idle,
  transfering,
  finishing,
  complete,
  error
}

export type ProgressStatusType = {
  progress: string | undefined;
  bytes: number;
  state: ProgressStates;
};

const initialProgressStatus: ProgressStatusType = {
  progress: undefined,
  bytes: 0,
  state: ProgressStates.idle
};

const SIZE_UNITS = ['B', 'KB', 'MB', 'GB'];
const SIZE_STEPS = 1000;

/**
 * Formats bytes into a nice display string
 * @param size Bytes
 * @returns Formatted size string
 */
const convertSize = (size: number) => {
  let remaining = size;
  let count = 0;

  while (remaining >= SIZE_STEPS) {
    remaining /= SIZE_STEPS;
    count++;
  }

  return `${remaining.toFixed(2)}${SIZE_UNITS[count]}`;
};

/**
 * Format the uploading text
 * @param filename Filename
 * @param bytes Current bytes
 * @returns Display string
 */
const formatUploadingText = (file: File, bytes: number) => {
  return `Uploading: ${file.name} (${convertSize(bytes)}/${convertSize(file.size)})`;
};

/**
 * Handle submit CPP request hook
 * @returns Hook variables
 */
const useHandleSubmitCppRequest = () => {
  const [uploadProgress, setUploadProgress] = useState<ProgressStatusType>({
    ...initialProgressStatus
  });
  const {deleteDraft} = useCppRequestDraftStore(state => ({
    deleteDraft: state.deleteDraft
  }));

  const [cachedInputs, setCachedInputs] = useState<{
    id: string | undefined;
    inputs: CppRequestFinalType | undefined;
    airline: Airline | undefined;
    response: CppSubmitRequestResp | undefined;
  }>({
    id: undefined,
    inputs: undefined,
    airline: undefined,
    response: undefined
  });

  const [transferFiles, setTransferFiles] = useState<boolean>(false);

  const resetProgress = useCallback(() => {
    setUploadProgress({...initialProgressStatus});
  }, [setUploadProgress]);

  const cppSubmitRequest = useCppSubmitRequest(
    // Handle Success
    useCallback(
      (data: CppSubmitRequestResp) => {
        setCachedInputs(value => ({...value, response: data}));
        setTransferFiles(true);
      },
      [setCachedInputs]
    ),
    // Handle Error
    useCallback(() => {
      setUploadProgress({...initialProgressStatus, state: ProgressStates.error});
    }, [setUploadProgress])
  );

  const cppRequestComplete = useCppRequestComplete(
    useCallback(() => {
      // Handle success
      setUploadProgress(value => ({...value, state: ProgressStates.complete}));
      deleteDraft(cachedInputs.id as string);
    }, [cachedInputs.id, deleteDraft]),
    useCallback(() => {
      // Handle Error
      setUploadProgress(value => ({...value, state: ProgressStates.error}));
    }, [setUploadProgress])
  );

  const handleRequestSubmit = useCallback(
    (id: string, inputs: CppRequestFinalType, airline: Airline) => {
      cppSubmitRequest.mutate({
        airline: airline,
        imageFiles: inputs.container?.imageFiles || [],
        otherFiles: inputs.additional?.otherFiles || []
      });

      setCachedInputs({id, inputs, airline, response: undefined});
    },
    [cppSubmitRequest]
  );

  useEffect(() => {
    (async () => {
      if (
        !transferFiles ||
        !cachedInputs.response ||
        !cachedInputs.airline ||
        !cachedInputs.inputs ||
        !cachedInputs.inputs.container
      )
        return;

      try {
        setTransferFiles(false);

        for (const imageFile of cachedInputs.inputs.container.imageFiles) {
          setUploadProgress({
            progress: formatUploadingText(imageFile, 0),
            state: ProgressStates.transfering,
            bytes: 0
          });

          const resp = await axios.put(cachedInputs.response.imageFiles[imageFile.name], imageFile, {
            onUploadProgress: progressEvent => {
              setUploadProgress(value => {
                const newBytes = value.bytes + progressEvent.bytes;
                return {
                  progress: formatUploadingText(imageFile, newBytes),
                  bytes: newBytes,
                  state: ProgressStates.transfering
                };
              });
            }
          });

          if (resp.status !== 200) throw new Error(`Failed sending ${imageFile.name}`);
        }

        for (const otherFile of cachedInputs.inputs.additional?.otherFiles || []) {
          setUploadProgress({
            progress: formatUploadingText(otherFile, 0),
            state: ProgressStates.transfering,
            bytes: 0
          });

          const resp = await axios.put(cachedInputs.response.otherFiles[otherFile.name], otherFile, {
            onUploadProgress: progressEvent => {
              setUploadProgress(value => {
                const newBytes = value.bytes + progressEvent.bytes;
                return {
                  progress: formatUploadingText(otherFile, newBytes),
                  bytes: newBytes,
                  state: ProgressStates.transfering
                };
              });
            }
          });

          if (resp.status !== 200) throw new Error(`Failed sending ${otherFile.name}`);
        }

        for (const imageFile of cachedInputs.inputs.container.imageFiles) {
          setUploadProgress({
            progress: `Processing: ${imageFile.name}`,
            bytes: 0,
            state: ProgressStates.transfering
          });

          const resp = await postWithHeaders(
            'cpp/requestPushImage',
            JSON.stringify({
              airlineCode: cachedInputs.airline.code,
              airlineIcao: cachedInputs.airline.icao,
              id: cachedInputs.response.id,
              imageFilename: imageFile.name
            })
          );

          if (!resp.ok) throw new Error(`Failed processing ${imageFile.name}`);
        }

        setUploadProgress(values => ({...values, state: ProgressStates.finishing}));
        cppRequestComplete.mutate({
          inputs: cachedInputs.inputs,
          airline: cachedInputs.airline,
          response: cachedInputs.response
        });
      } catch (exp: any) {
        console.error(`Failed uploading to S3 bucket: ${exp.toString()}`);
        setUploadProgress(value => ({...value, state: ProgressStates.error}));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferFiles]);

  return {handleRequestSubmit, uploadProgress, resetProgress};
};

export default useHandleSubmitCppRequest;
