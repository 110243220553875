/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Create Update Page
 */

import React, {useEffect, useState} from 'react';
import {ShareLink} from '@viasat/insights-components';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import styled from '@emotion/styled';
import {useNavigate} from 'react-router-dom';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {getCurrentUrlPath} from '../../utils/linkUtils';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import {PATHS} from '../../paths';
import {CreateUpdateRequest, useAddUpdate} from '../../api/mutations/useAddUpdate';
import {PageProps} from '../../types';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import ErrorMsg from '../../components/Error';
import {formSchema, UpdateFormSchema, UpdateInformationForm} from '../../components/UpdateInformationForm';
import {useAirlines} from '../../api/queries/useAirlines';
import {Alert} from '../../components/Alert';
import {strings} from '../../locale/strings';

const Container = styled.div`
  margin: 32px;
`;

const Status = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateUpdate: React.FC<PageProps> = ({title}) => {
  const navigate = useNavigate();
  const {data: airlines} = useAirlines();

  const [errorMessage, setErrorMessage] = useState('');
  const formMethods = useForm<UpdateFormSchema>({
    defaultValues: {
      airline: 'SOUTHWEST',
      updateType: undefined
    },
    resolver: yupResolver(formSchema)
  });
  const {setError} = formMethods;
  const {mutate: addUpdate, isLoading, isError, error} = useAddUpdate();

  useEffect(() => {
    if (isError) {
      const errorObj = JSON.parse((error as Error).message);
      if (errorObj.response) {
        const errorMsg = errorObj.response.errors[0].message;
        setErrorMessage(errorMsg);
        if (errorMsg.toLowerCase().includes('package name')) {
          setError('updateName', {type: 'custom', message: errorMsg});
        }
      }
    }
  }, [isError, error, setError]);

  const onSubmit: SubmitHandler<UpdateFormSchema> = data => {
    const params: CreateUpdateRequest = {
      name: data.updateName,
      package: {
        deployment_date: data.deploymentStartDate.toString(),
        package_type: data.updateType,
        versions: {
          config: data.configVersionNumber,
          ife: data.ifeContent,
          modem: data.modemVersionNumber,
          portal: data.portalVersionNumber,
          server: data.serverVersionNumber,
          vcdm: data.vcdmVersionNumber,
          helm_chart: data.helmChartVersionNumber,
          other: data.otherVersionNumber
        }
      },
      notes: data.releaseNotes,
      airline_code: data.airline,
      internal_notes: data.internalNotes ?? '',
      links: data.links?.map(function (link) {
        return `[${link.title}](${link.url})`;
      })
    };
    addUpdate({params});
  };

  return (
    <PageContainerTemplate
      title={title}
      isSubtitleLoading={false}
      subtitle={''}
      getFullElementId={(name: string, type: string) => `${name}-${type}`}
      preTitleStack={[<BackButton label="Updates" onClick={() => navigate(PATHS.updates)} />]}
      leftStack={[]}
      rightStack={[
        <ShareLink
          key="shareLink"
          getFullElementId={(name: string, type?: string) => `${title}__${name}-${type}`}
          disabled={false}
          urlPath={getCurrentUrlPath()}
          urlQuery={''}
        />
      ]}
      disableSpaGroupSelector={true}
    >
      {isLoading ? (
        <Status>
          <Loading text="Loading your update" />
        </Status>
      ) : isError ? (
        <Status>
          <ErrorMsg text={errorMessage} />
        </Status>
      ) : null}

      <FormProvider {...formMethods}>
        <Alert bodyText={strings.CreateUpdatePage.BannerBodyText.default_message} status="default" closing={false} />
        <Container>
          <UpdateInformationForm airlines={airlines ?? []} onSubmit={onSubmit} />
        </Container>
      </FormProvider>
    </PageContainerTemplate>
  );
};

export default CreateUpdate;
