/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add Applicable Tails component
 */
import styled from '@emotion/styled';
import {Button, Chip, Divider, Typography} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {Section} from './Section';
import MultiSelectorDropdown from '../components/MultiSelectorDropdown';
import {TailListLink} from '../components/TailListLink';
import {Add, Info, InfoOutlined} from '@mui/icons-material';
import {Dispatcher} from '../theme/Colors';
import {PackageData, PackageStatuses} from '../api/queries/packages';
import {useAirlineTails} from '../api/queries/airlineTails';
import {useMemo, useState} from 'react';
import TailList from './TailList';
import Loading from './Loading';
import {strings} from '../locale/strings';

const AddApplicableAircraftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[32]};
  gap: ${spacing[32]};
  align-self: stretch;
  flex-grow: 0;
`;

const ApplicableAircraftFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[32]};
  flex-grow: 0;
`;
const SectionChip = styled.div`
  display: flex;
  margin-left: auto;
`;
const HelperTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[24]};
  align-items: flex-start;
`;
const HelperIconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${spacing[12]};
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ApplicableAircraftForm: React.FC<{
  applicableTailIds: Set<string>;
  setShowForm: (showForm: boolean) => void;
  onAddAircraft: (tails: Array<string>) => void;
}> = ({applicableTailIds, setShowForm, onAddAircraft}) => {
  const [selectedAircraft, setSelectedAircraft] = useState(new Set<string>());

  return (
    <ApplicableAircraftFormContainer>
      <MultiSelectorDropdown
        selectAllByDefault={false}
        fullWidth={false}
        items={applicableTailIds}
        setSelectedItems={setSelectedAircraft}
        label={strings.ApplicableTails}
        prompt={
          selectedAircraft.size > 1
            ? strings.NTails.replace('%n%', selectedAircraft.size.toString())
            : selectedAircraft.size === 1
            ? strings.OneTail
            : strings.SelectTail
        }
        helperTextSx={{marginRight: 0, marginLeft: 0, marginTop: spacing[8]}}
        helperText={
          <HelperTextContainer>
            <HelperIconText>
              <InfoOutlined color="primary" sx={{fontSize: spacing[24]}} />
              <Typography sx={{fontSize: spacing[16], lineHeight: spacing[24], color: Dispatcher.AccessibleGray}}>
                {strings.AddApplicableTailsSection.OpenTailList}
              </Typography>
            </HelperIconText>
            <TailListLink
              tails={Array.from(selectedAircraft)}
              text={strings.TailStatus}
              sx={{
                fontSize: spacing[16],
                lineHeight: spacing[24],
                padding: 0,
                '& svg': {
                  fontSize: spacing[24]
                }
              }}
            />
          </HelperTextContainer>
        }
        selectAllLabel={strings.AllTails}
        renderFooter={selectedAircraft => <TailListLink tails={Array.from(selectedAircraft)} />}
        selectedItems={selectedAircraft}
      />
      {selectedAircraft.size > 0 ? <TailList tails={Array.from(selectedAircraft)} /> : null}
      <Button
        variant="contained"
        size="large"
        disabled={selectedAircraft.size <= 0}
        onClick={() => {
          // Add tails
          onAddAircraft(Array.from(selectedAircraft));
          // Reset form
          setSelectedAircraft(new Set<string>());
          setShowForm(false);
        }}
      >
        {strings.AddTails}
      </Button>
    </ApplicableAircraftFormContainer>
  );
};

const AddApplicableTailsSection: React.FC<{
  packageData: PackageData;
  isLoading?: boolean;
  onAddAircraft: (packageId: string, tails: Array<string>) => void;
  isAdminUser: boolean;
}> = ({packageData, isLoading, onAddAircraft, isAdminUser}) => {
  const {data: airlineTails} = useAirlineTails(isAdminUser ? {airlineCode: packageData.airline_code} : undefined);
  const [showForm, setShowForm] = useState<boolean>(packageData.applicable_tails.length <= 0);
  const applicableTailIds: Set<string> = useMemo(
    () =>
      new Set(
        airlineTails
          ?.map(tail => tail.tailId)
          //Should only contain tails that have not already been added to the package
          .filter(
            (tailId: string) =>
              !packageData.applicable_tails.includes(tailId) && !packageData.approved_tails.includes(tailId)
          )
      ),
    [packageData.applicable_tails, airlineTails, packageData.approved_tails]
  );

  const tailsCanBeAddedStates = [
    PackageStatuses.AVAILABLE,
    PackageStatuses.NO_APPLICABLE_TAILS,
    PackageStatuses.PARTIALLY_APPROVED,
    PackageStatuses.FULLY_APPROVED
  ];

  return (
    <AddApplicableAircraftContainer>
      {isLoading ? (
        <LoadingContainer>
          <Loading text={strings.AddingTails}></Loading>
        </LoadingContainer>
      ) : (
        <>
          <Section
            title={strings.ApplicableTails}
            bodyText={
              applicableTailIds.size > 0 && tailsCanBeAddedStates.includes(packageData.status)
                ? strings.AddApplicableTailsSection.SelectApplicableTailForUpdate
                : strings.AddApplicableTailsSection.AdditionalTailsCanNotBeAppliedToUpdate
            }
            layout="vertical"
            headerContent={
              isAdminUser &&
              (packageData.applicable_tails.length <= 0 ? (
                <SectionChip>
                  <Chip
                    icon={<Info sx={{color: `${colors.warning[500]} !important`, fontSize: spacing[20]}} />}
                    label={strings.MissingTail}
                    size="medium"
                    sx={{
                      background: colors.alert[100],
                      fontSize: 14,
                      padding: `${spacing[8]} ${spacing[4]}`,
                      radius: spacing[16],
                      lineHeight: spacing[20],
                      color: colors.success[600],
                      width: 'auto'
                    }}
                  />
                </SectionChip>
              ) : applicableTailIds.size > 0 && tailsCanBeAddedStates.includes(packageData.status) && !showForm ? (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    padding: `${spacing[8]} ${spacing[24]}`,
                    gap: spacing[8],
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    flex: 'none'
                  }}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <Add />
                  <span>{strings.AddTails}</span>
                </Button>
              ) : null)
            }
          >
            {packageData.applicable_tails.length ? <TailList tails={packageData.applicable_tails} /> : null}

            {isAdminUser && (
              <>
                {packageData.applicable_tails.length && showForm ? (
                  <>
                    <Divider sx={{width: '100%'}} />
                    <Section title={strings.AddMoreTails} bodyText={''}></Section>
                  </>
                ) : null}

                {applicableTailIds.size > 0 &&
                tailsCanBeAddedStates.includes(packageData.status) &&
                (packageData.applicable_tails.length <= 0 || showForm) ? (
                  <ApplicableAircraftForm
                    applicableTailIds={applicableTailIds}
                    setShowForm={setShowForm}
                    onAddAircraft={tails => onAddAircraft(packageData.id, tails)}
                  />
                ) : null}
              </>
            )}
          </Section>
        </>
      )}
    </AddApplicableAircraftContainer>
  );
};

export default AddApplicableTailsSection;
