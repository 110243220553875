/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: CPP draft local storage hook.
 */
import {create} from 'zustand';
import {persist, PersistStorage, StorageValue} from 'zustand/middleware';
import {CppRequestDraft} from '../../pages/Cpp/types';

interface CppRequestDraftState {
  drafts: Map<string, CppRequestDraft>;
  updateDraft: (id: string, value: CppRequestDraft) => void;
  deleteDraft: (id: string) => void;
  getDraft: (id: string) => CppRequestDraft | undefined;
}

// Custom storage option for Map types
const storage: PersistStorage<CppRequestDraftState> = {
  getItem: name => {
    const str = localStorage.getItem(name);
    if (!str) return null;
    const {state} = JSON.parse(str);
    return {
      state: {
        ...state,
        drafts: new Map(state.transactions)
      }
    };
  },
  setItem: (name, newValue: StorageValue<CppRequestDraftState>) => {
    // functions cannot be JSON encoded
    const str = JSON.stringify({
      state: {
        ...newValue.state,
        transactions: Array.from(newValue.state.drafts.entries())
      }
    });
    localStorage.setItem(name, str);
  },
  removeItem: name => localStorage.removeItem(name)
};

/**
 * Local storage hook for CPP request drafts.
 */
export const useCppRequestDraftStore = create<CppRequestDraftState>()(
  persist(
    (set, get) => ({
      drafts: new Map(),
      updateDraft: (id: string, value: CppRequestDraft) => {
        const storedDrafts = get().drafts;
        storedDrafts.set(id, value);
        set({drafts: storedDrafts});
      },
      deleteDraft: (id: string) => {
        const storedDrafts = get().drafts;
        storedDrafts.delete(id);
        set({drafts: storedDrafts});
      },
      getDraft: (id: string) => get().drafts.get(id)
    }),
    {
      name: 'cpp-request-drafts', //name of item in storage
      storage
    }
  )
);
