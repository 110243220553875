/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: AddNotifiedUserDialog component
 */
import {Box} from '@mui/material';
import * as React from 'react';
import * as yup from 'yup';
import {strings} from '../locale/strings';
import {AirlineFormSelect} from './AirlineFormSelect';
import {FormDialog} from './FormDialog';
import {FormInput} from './FormInput';
import {spacing} from '@vst/beam';
import {Airline, useAirlines} from '../api/queries/useAirlines';
import {useAddNotifiedUser} from '../api/mutations/useAddNotifiedUser';
import {UpdateTypeFormSelect} from './UpdateTypeFormSelect';
import {packageTypes} from '../api/queries/packages';
import {useEffect} from 'react';
import {spaComs} from '@viasat/insights-spa-package';

const AddNotifiedUserForm: React.FC<{airlines: Airline[]}> = ({airlines}) => {
  return (
    <Box display="flex" flexDirection="column" gap={spacing[16]}>
      <FormInput name="email" label={strings.Email} required type="email" triggerValidation />
      <AirlineFormSelect name="airline_codes" airlines={airlines} multiple />
      <UpdateTypeFormSelect name="package_types" packageTypes={packageTypes} multiple />
    </Box>
  );
};

interface AddNotifiedUserFormFields {
  email: string;
  airline_codes: string[];
  package_types: string[];
}

type AddNotifiedUserFormField = keyof AddNotifiedUserFormFields;

type FormFieldError<T> = {field: T; message: string};

const serverErrors: Record<string, FormFieldError<AddNotifiedUserFormField>> = {
  email_entry_exists: {field: 'email', message: strings.AddNotifiedUserDialog.ErrorMessages.email_entry_exists}
};

export const AddNotifiedUserDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}> = ({open, onClose, onSuccess, onError}) => {
  const formSchema = yup.object().shape({
    airline_codes: yup
      .array()
      .of(yup.string().required())
      .min(1, strings.FormValidation.SelectAtLeastOneAirline)
      .required(strings.FormValidation.PleaseCompleteThisRequiredField),
    package_types: yup
      .array()
      .of(yup.string().required())
      .min(1, strings.FormValidation.SelectAtleatOneUpdateType)
      .required(strings.FormValidation.PleaseCompleteThisRequiredField),
    email: yup.string().email().required(strings.FormValidation.PleaseCompleteThisRequiredField)
  });

  const {data: airlines} = useAirlines();
  const [errors, setErrors] = React.useState<FormFieldError<AddNotifiedUserFormField>[]>([]);
  const addNotifiedUserResult = useAddNotifiedUser({
    onSuccess: () => {
      onClose();
      onSuccess();
      addNotifiedUserResult.reset();
    },
    onError: error => {
      try {
        const errorObj = JSON.parse((error as Error).message) as {
          response: {errors: {code: string; message: string}[]};
        };
        if (errorObj.response) {
          const errors = errorObj.response.errors.map(({code, message}) => serverErrors[code] ?? message);
          setErrors(errors);
        }
      } catch (e) {
        onError();
        onClose();
      }
    }
  });
  useEffect(() => {
    spaComs.sendGroupDisable(open);
  }, [open]);

  return (
    <FormDialog
      title={strings.AddNotifiedUserDialog.Title}
      open={open}
      onSubmit={({email, airline_codes, package_types}) => {
        addNotifiedUserResult.mutate({params: {email, airline_codes, package_types}});
      }}
      onClose={onClose}
      formSchema={formSchema}
      defaultValues={() => ({
        email: '',
        airline_codes: [],
        package_types: []
      })}
      okText={strings.Add}
      isLoading={addNotifiedUserResult.isLoading || addNotifiedUserResult.isSuccess}
      dependencies={airlines}
      renderForm={airlines => <AddNotifiedUserForm airlines={airlines} />}
      errors={errors}
    />
  );
};
