/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: approvals Route
 */

export type ApprovalType = 'approval' | 'rejection' | 'closure';

interface ApprovalBase {
  id: string;
  creator_email: string;
  creator_name: string;
  created_timestamp: string;
  package_id: string;
  approved_tails: string[];
  approval_type: ApprovalType;
  reason?: string | null;
}

interface ApprovalApproval extends ApprovalBase {
  deployment_date: string;
  approval_type: 'approval';
}

interface ApprovalRejection extends ApprovalBase {
  deployment_date: null;
  approval_type: 'rejection';
}

interface ApprovalClosure extends ApprovalBase {
  deployment_date: null;
  approval_type: 'closure';
}

export type Approval = ApprovalApproval | ApprovalRejection | ApprovalClosure;

export interface ApprovalsData {
  approvals: Approval[];
}

export enum ApprovalTypes {
  APPROVAL = 'approval',
  REJECTION = 'rejection',
  CLOSURE = 'closure'
}
