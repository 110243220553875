/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: useApprovalsData
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {ApprovalsData} from './approvals';

export const useApprovals = (params: {airlines: string[] | null}) => {
  return useQuery<ApprovalsData>({
    queryKey: ['approvals'],
    queryFn: () => fetcher({route: 'approvals', params}),
    refetchOnWindowFocus: false,
    select: data => ({
      approvals: data.approvals.filter(x => x.approval_type !== 'closure')
    })
  });
};
