/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Alert Component.
 */

import styled from '@emotion/styled';
import {Icon, IconButton, Typography} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import React from 'react';
import {Dispatcher} from '../theme/Colors';
import {Close} from '@mui/icons-material';

const IconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  alight-items: flex-start;
  gap: 10px;
  flex: none;
  flex-grow: 0;
`;
const CloseContainer = styled.div`
  margin-left: auto;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.style?.padding};
  gap: ${spacing[24]};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: ${props => props.style?.background};
`;

/**
 * Get the alert component theme based on the alert status.
 *
 * @param status The alert status.
 *
 * @return The alert component theme styles.
 */
const getAlertTheme = (
  status: 'error' | 'success' | 'info' | 'default'
): {
  icon: string;
  iconColor: 'primary' | 'error' | 'success';
  background: string;
  fontColor: string;
} => {
  switch (status) {
    case 'info':
      return {
        icon: 'info_outlined',
        iconColor: 'primary',
        background: colors.gray[100],
        fontColor: Dispatcher.DefaultLinkGray
      };
    case 'error':
      return {
        icon: 'info_outlined',
        iconColor: 'error',
        background: colors.alert[100],
        fontColor: Dispatcher.DefaultLinkGray
      };
    case 'success':
      return {
        icon: 'done',
        iconColor: 'success',
        background: colors.success[100],
        fontColor: Dispatcher.DefaultLinkGray
      };
    default:
      return {
        icon: 'info_outlined',
        iconColor: 'primary',
        background: colors.white,
        fontColor: Dispatcher.AccessibleGray
      };
  }
};

type AlertStatus = 'error' | 'success' | 'info' | 'default';

export interface AlertState {
  status: AlertStatus;
  bodyText: string;
}
export const Alert: React.FC<
  {
    icon?: string;
    content?: string;
    closing?: boolean;
    displayTop?: boolean;
    onClickClose?: () => void;
  } & AlertState
> = ({icon, bodyText, status = 'default', content, closing = true, onClickClose, displayTop = true}) => {
  const alertTheme = getAlertTheme(status);

  return (
    <AlertContainer
      style={{
        background: alertTheme.background,
        padding: closing ? `${spacing[16]} ${spacing[32]}` : `${spacing[24]} ${spacing[32]}`,
        borderTop: displayTop ? 'none' : '1px solid rgba(0, 0, 0, 0.12)'
      }}
    >
      <IconTextContainer>
        <Icon color={alertTheme.iconColor} sx={{fontSize: 24}}>
          {icon ?? alertTheme.icon}
        </Icon>
        <TextContainer>
          <Typography variant="body1" color={alertTheme.fontColor}>
            {bodyText}
          </Typography>
        </TextContainer>
      </IconTextContainer>
      <TextContainer>
        {content && (
          <Typography variant="body1" color={Dispatcher.DefaultLinkGray}>
            {content}
          </Typography>
        )}
      </TextContainer>
      {closing && (
        <CloseContainer onClick={onClickClose}>
          <IconButton>
            <Close sx={{color: Dispatcher.DefaultLinkGray, fontSize: 24}} />
          </IconButton>
        </CloseContainer>
      )}
    </AlertContainer>
  );
};
