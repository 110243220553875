/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common component colors
 */

import {colors} from '@vst/beam';

export const Dispatcher = {
  AccessibleGray: '#5F7080',
  AccessibleGrayIconsAndBorders: '#8595A1',
  DefaultLinkGray: '#202E39',
  Secondary: {Violet: {0: '#F7F6FE'}},
  SuccessDark: '#008D48'
};

export const DISPATCHER_PRIMARY_PURPLE = colors.violet[500];
export const DISPATCHER_SECONDARY_PURPLE = colors.violet[400];
export const GRID_HOVER_TEXT = colors.violet[700];
export const GRID_HIGHLIGHT = colors.gray[100];
