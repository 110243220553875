/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Error Indicator component
 */

import React from 'react';
import Indicator from './Indicator';
import ErrorIcon from '@mui/icons-material/Error';

const Error: React.FC<{text: string}> = ({text}) => (
  <Indicator
    icon={
      <div>
        <ErrorIcon color="primary" />
      </div>
    }
    text={text}
  />
);

export default Error;
