/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: CPP History Table component
 */

import {
  TableHead,
  TableRow as MUITableRow,
  TableContainer,
  Table,
  TableCell,
  SxProps,
  Theme,
  IconButton
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {HeaderCell, TextCell, TableBody} from './Tables';
import {colors, spacing} from '@vst/beam';
import {Dispatcher} from '../theme/Colors';
import {CppRequestHistory} from '../api/queries/useCppRequestHistory';
import {formatDate} from '../utils/gridUtils';
import {useState} from 'react';
import {ArrowUpward} from '@mui/icons-material';
import {ascendingSortFn, descendingSortFn} from '../utils/dateUtils';

const NUM_COLUMNS = 7;

export const statusMap = {
  ready_to_deploy: 'Ready to Deploy',
  submitted: 'Submitted',
  draft: 'Draft'
};

/**
 * CPP Request History Table Header
 * @param param0 Table header parameters:
 *  - onClickSubmittedSortArrow: Click handler for "sort" arrow on "Submitted" header
 * @returns The table header component.
 */
const CppRequestHistoryTableHead: React.FC<{onClickSubmittedSortArrow: () => void; displayAirlineColumn: boolean}> = ({
  onClickSubmittedSortArrow,
  displayAirlineColumn
}) => {
  // Whether the "sort" arrow on the "Submitted" header should point "up" or "down"
  const [arrowDown, setArrowDown] = useState<boolean>(true);
  return (
    <TableHead>
      <MUITableRow>
        <HeaderCell text="Status" />
        {displayAirlineColumn && <HeaderCell text="Airline" />}
        <HeaderCell text="Requester" />
        <HeaderCell text="Part Number" />
        <HeaderCell
          text={
            <div
              style={{
                display: 'inline-flex',
                gap: spacing[4]
              }}
            >
              <div>Submitted</div>
              <IconButton
                data-testid="cpp-sort-by-submitted-time"
                onClick={() => {
                  onClickSubmittedSortArrow();
                  setArrowDown(!arrowDown);
                }}
                style={{
                  padding: 0
                }}
              >
                {arrowDown ? <ArrowDownwardIcon /> : <ArrowUpward />}
              </IconButton>
            </div>
          }
        />
        <HeaderCell text="Last Change" />
        <HeaderCell text="Application" />
        <HeaderCell text="" sx={{width: '99%'}} />
      </MUITableRow>
    </TableHead>
  );
};

/**
 * CPP Request History Table Row
 * @param param0 The component params:
 *  - cppRequest: The CPP request displayed on the row.
 *  - onClick: onClick handler for when row is clicked
 *  - selected: Whether or not the table row has been clicked/selected.
 * @returns A table row element.
 */
const CppRequestTableRow: React.FC<{
  cppRequest: CppRequestHistory;
  onClick: (cppRequest: CppRequestHistory) => void;
  selected: boolean;
  displayAirlineColumn: boolean;
}> = ({cppRequest, onClick, selected, displayAirlineColumn}) => {
  return (
    <MUITableRow
      sx={{
        '&:hover': {backgroundColor: colors.gray[100]},
        cursor: 'pointer'
      }}
      onClick={() => onClick(cppRequest)}
      selected={selected}
    >
      <TextCell
        text={statusMap[cppRequest.status] || ''}
        color={
          cppRequest.status === 'submitted'
            ? Dispatcher.SuccessDark
            : cppRequest.status === 'draft'
            ? '#7C8B8B'
            : '#562BDA'
        }
      />
      {displayAirlineColumn && <TextCell text={cppRequest.airlineName} />}
      <TextCell text={cppRequest.creatorName} />
      <TextCell text={cppRequest.status === 'draft' ? '' : cppRequest.id} />
      <TextCell text={cppRequest.status === 'draft' ? '' : formatDate(cppRequest.createdTimestamp)} />
      <TextCell text={formatDate(cppRequest.updatedTimestamp)} />
      <TextCell text={cppRequest.application} />
      <TableCell align="right" sx={{whiteSpace: 'nowrap'}}>
        <ArrowForwardIcon
          htmlColor={Dispatcher.AccessibleGrayIconsAndBorders}
          sx={{verticalAlign: 'middle', width: 24, height: 24}}
        />
      </TableCell>
    </MUITableRow>
  );
};

/**
 * Sort comparison function for CPP Requests.
 * Drafts are always first.
 * Requests are sorted via creation time stamp.
 * @param a CPP Request to compare.
 * @param b Other CPP Request to compare.
 * @param descending Whether to sort in descending order of creation time.
 * @returns Number indicating sort priority.
 */
const cppSortFn = (a: CppRequestHistory, b: CppRequestHistory, descending: boolean = true): number => {
  if (a.status === 'draft' && b.status !== 'draft') {
    // sort a before b
    return -1;
  } else if (b.status === 'draft' && a.status !== 'draft') {
    // sort b before a
    return 1;
  } else {
    return descending
      ? descendingSortFn(a.createdTimestamp, b.createdTimestamp)
      : ascendingSortFn(a.createdTimestamp, b.createdTimestamp);
  }
};

/**
 * CPP Request History Table.
 * @param param0
 *  - cppRequests: Array of CPP requests
 *  - isLoading: Whether CPP request history query is still in progress and data is loading
 *  - error: Whether CPP request history query erred
 *  - onClickRow: onClick handler for a table row
 *  - selectedId: The ID of the selected CPP request.
 *  - sx: Any custom component props.
 * @returns The CPP Request History table.
 */
const CppRequestHistoryTable: React.FC<{
  cppRequests: CppRequestHistory[];
  isLoading: boolean;
  error: boolean;
  onClickRow: (cppRequest: CppRequestHistory) => void;
  selectedId?: string;
  sx?: SxProps<Theme>;
  displayAirlineColumn: boolean;
}> = ({cppRequests, isLoading, error, onClickRow, selectedId, sx, displayAirlineColumn}) => {
  // Number of table columns
  // The sort function to use for the table
  const [sortDescending, setSortDescending] = useState<boolean>(true);

  return (
    <TableContainer sx={{flex: 1, ...sx}}>
      <Table>
        <CppRequestHistoryTableHead
          onClickSubmittedSortArrow={() => {
            const toggle = !sortDescending;
            setSortDescending(toggle);
          }}
          displayAirlineColumn={displayAirlineColumn}
        />
        <TableBody
          items={cppRequests}
          defaultSortFn={(a, b) => cppSortFn(a, b, sortDescending)}
          isLoading={isLoading}
          error={error}
          numCols={NUM_COLUMNS}
          renderRow={cppRequest => (
            <CppRequestTableRow
              key={cppRequest.id}
              cppRequest={cppRequest}
              selected={selectedId === cppRequest.id}
              onClick={onClickRow}
              displayAirlineColumn={displayAirlineColumn}
            />
          )}
          renderEmpty={function (): React.ReactNode {
            // The empty table is never rendered because the "No History" Desktop screen is shown
            // when there is no cpp request history
            return <></>;
          }}
        />
      </Table>
    </TableContainer>
  );
};
export default CppRequestHistoryTable;
