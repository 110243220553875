/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Users Page
 */
import styled from '@emotion/styled';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {colors, spacing} from '@vst/beam';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import ApproversTable from '../../components/ApproversTable';
import {TabsPageTab, TabsWithContent} from '../../components/TabsWithContent';
import {strings} from '../../locale/strings';
import {Approver, useApprovers} from '../../api/queries/useApprovers';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import {PageProps} from '../../types';
import {Box, Button, Typography} from '@mui/material';
import {AddApproverDialog} from '../../components/AddApproverDialog';
import {DeleteApproverDialog} from '../../components/DeleteApproverDialog';
import {Alert, AlertState} from '../../components/Alert';
import NotifiedUsersTable, {NotifiedUser} from '../../components/NotifiedUsersTable';
import {useNotifiedUsers} from '../../api/queries/useNotifiedUsers';
import {useAirlines} from '../../api/queries/useAirlines';
import {notEmpty} from '../../utils/utils';
import {AddNotifiedUserDialog} from '../../components/AddNotifiedUserDialog';
import {DeleteNotifiedUserDialog} from '../../components/DeleteNotifiedUserDialog';
import useBearStore from '../../utils/stores/useBearStore';

const Main = styled.main`
  min-height: 100%;
  background-color: ${colors.gray[100]};
  padding: ${spacing[16]};
`;

const ApproversTabContent: React.FC<{
  isAdmin: boolean;
  airlines: string[] | null;
  onClickDeleteRow?: (approver: Approver) => void;
}> = ({airlines, onClickDeleteRow}) => {
  const {data: approvers, isLoading, isError} = useApprovers({airlines});
  return (
    <ApproversTable
      approvers={approvers}
      isLoading={isLoading}
      error={isError}
      onClickDeleteRow={onClickDeleteRow}
      sx={{backgroundColor: colors.white}}
    />
  );
};

const NotifiedUsersTabContent: React.FC<{
  airlines: string[] | null;
  onClickDeleteRow?: (user: NotifiedUser) => void;
}> = ({airlines, onClickDeleteRow}) => {
  const notifiedUsersResponse = useNotifiedUsers({airlines});
  const {data: airlinesData} = useAirlines();

  return (
    <NotifiedUsersTable
      users={notifiedUsersResponse.data?.notified_users.map(notifiedUser => ({
        ...notifiedUser,
        airlines: notifiedUser.airline_codes.map(
          code => airlinesData?.find(a => a.code === code) ?? {code, name: code, icao: code}
        )
      }))}
      isLoading={notifiedUsersResponse.isLoading}
      error={notifiedUsersResponse.isError}
      onClickDeleteRow={onClickDeleteRow}
      sx={{backgroundColor: colors.white}}
    />
  );
};

/**
 * Users Page
 */
const Users: React.FC<PageProps> = ({title}) => {
  const {
    init: {isAdmin, isInternal},
    airlines
  } = useBearStore(state => ({init: state.init, airlines: state.airlines}));
  let pageParams = useParams<{tabId: string}>();

  const [addApproverDialogOpen, setAddApproverDialogOpen] = useState(false);
  const [addNotifiedUserDialogOpen, setAddNotifiedUserDialogOpen] = useState(false);
  const [approverToDelete, setApproverToDelete] = useState<Approver | null>(null);
  const [notifiedUserToDelete, setNotifiedUserToDelete] = useState<NotifiedUser | null>(null);
  const [alertState, setAlertState] = useState<AlertState | null>(null);

  const tabs: TabsPageTab[] = [
    {
      id: 'approvers',
      icon: AirplanemodeActiveIcon,
      label: strings.AirlineApprovers,
      content: () => (
        <ApproversTabContent
          isAdmin={isAdmin}
          airlines={airlines}
          onClickDeleteRow={isAdmin ? approver => setApproverToDelete(approver) : undefined}
        />
      ),
      headerContent: (
        <Box display="flex" flexDirection="row" alignItems="start" gap={spacing[80]}>
          <Typography sx={{flexGrow: 1}}>{strings.UsersPage.ApproversTabDescription}</Typography>
          {isAdmin && (
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  setAddApproverDialogOpen(true);
                }}
                sx={{whiteSpace: 'nowrap'}}
              >
                {strings.AddAirlineApprover}
              </Button>
            </Box>
          )}
        </Box>
      )
    },
    isInternal
      ? {
          id: 'notified_users',
          icon: AdminPanelSettingsIcon,
          label: strings.NotifiedViasatUsers,
          content: () => (
            <NotifiedUsersTabContent
              airlines={airlines}
              onClickDeleteRow={isAdmin ? user => setNotifiedUserToDelete(user) : undefined}
            />
          ),
          headerContent: (
            <Box display="flex" flexDirection="row" alignItems="start" gap={spacing[80]}>
              <Typography sx={{flexGrow: 1}}>{strings.UsersPage.NotifiedUsersTabDescription}</Typography>
              {isAdmin && (
                <Box>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => {
                      setAddNotifiedUserDialogOpen(true);
                    }}
                    sx={{whiteSpace: 'nowrap'}}
                  >
                    {strings.AddNotifiedUser}
                  </Button>
                </Box>
              )}
            </Box>
          )
        }
      : null
  ].filter(notEmpty);

  const tabIdIndex = tabs.findIndex(tab => tab.id === pageParams.tabId);
  const defaultTabIndex = tabIdIndex === -1 ? 0 : tabIdIndex;

  return (
    <PageContainerTemplate
      title={title}
      isSubtitleLoading={false}
      subtitle={''}
      getFullElementId={(name: string, type: string) => `${name}-${type}`}
      leftStack={[]}
      rightStack={[]}
    >
      {alertState && <Alert {...alertState} onClickClose={() => setAlertState(null)} />}
      <Main>
        <TabsWithContent tabs={tabs} defaultTabIndex={defaultTabIndex} />
        {isAdmin && (
          <>
            <AddApproverDialog
              open={addApproverDialogOpen}
              onClose={() => setAddApproverDialogOpen(false)}
              onSuccess={() =>
                setAlertState({
                  status: 'success',
                  bodyText: strings.UserHasBeenAddedSuccessfully
                })
              }
              onError={() =>
                setAlertState({
                  status: 'error',
                  bodyText: strings.SomethingWentWrongTryAgain
                })
              }
            />
            <AddNotifiedUserDialog
              open={addNotifiedUserDialogOpen}
              onClose={() => setAddNotifiedUserDialogOpen(false)}
              onSuccess={() =>
                setAlertState({
                  status: 'success',
                  bodyText: strings.UserHasBeenAddedSuccessfully
                })
              }
              onError={() =>
                setAlertState({
                  status: 'error',
                  bodyText: strings.SomethingWentWrongTryAgain
                })
              }
            />
            <DeleteApproverDialog
              approverToDelete={approverToDelete}
              onClose={() => setApproverToDelete(null)}
              onSuccess={() =>
                setAlertState({
                  status: 'success',
                  bodyText: strings.AirlineApproverHasBeenRemoved
                })
              }
              onError={() =>
                setAlertState({
                  status: 'error',
                  bodyText: strings.SomethingWentWrongTryAgain
                })
              }
            />
            <DeleteNotifiedUserDialog
              notifiedUserToDelete={notifiedUserToDelete}
              onClose={() => setNotifiedUserToDelete(null)}
              onSuccess={() =>
                setAlertState({
                  status: 'success',
                  bodyText: strings.UserHasBeenRemovedSuccessfully
                })
              }
              onError={() =>
                setAlertState({
                  status: 'error',
                  bodyText: strings.SomethingWentWrongTryAgain
                })
              }
            />
          </>
        )}
      </Main>
    </PageContainerTemplate>
  );
};

export default Users;
