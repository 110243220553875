/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Airline Tails Route
 */
import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';

export interface AirlineTailsData {
  tailId: string;
  fleet: string;
}

/**
 * Transforms the tails API.
 *
 * @param data The raw data returned from the api/airlines/{airlineCode}/tails response.
 *
 * @returns The transformed tails data.
 */
const transformTailsData = (data: any): AirlineTailsData => ({
  tailId: data.tailId,
  fleet: data.fleet
});

export const useAirlineTails = (params: {airlineCode: string} | undefined) =>
  useQuery<AirlineTailsData[]>({
    queryKey: ['airlineTails', params?.airlineCode],
    queryFn: () => fetcher({route: `airlines/${params?.airlineCode || ''}/tails`, params}),
    select: data => data.map(transformTailsData),
    refetchOnWindowFocus: false,
    enabled: Boolean(params),
    staleTime: Infinity,
    cacheTime: Infinity
  });
