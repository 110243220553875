/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: UpdateTypeFormSelect component
 */

import React from 'react';
import {SxProps} from '@mui/material';
import {FormSelect} from './FormSelect';
import {formatPackageType} from '../utils/gridUtils';
import {PackageType} from '../api/queries/packages';
import {strings} from '../locale/strings';

export const UpdateTypeFormSelect: React.FC<{
  name?: string;
  packageTypes: readonly PackageType[];
  multiple?: boolean;
  sx?: SxProps;
}> = ({name = 'updateType', packageTypes, multiple, sx}) => (
  <FormSelect
    name={name}
    label={multiple ? strings.UpdateTypes : strings.UpdateType}
    required
    sx={sx}
    multiple={multiple}
    checkbox={multiple}
    options={Object.fromEntries(packageTypes.map(packageType => [packageType, formatPackageType(packageType)]))}
  />
);
