/**
 * Descending sort function
 * @returns Function which sorts by descending time.
 */
export const descendingSortFn = (a: string, b: string) => new Date(b).getTime() - new Date(a).getTime();

/**
 * Ascending sort function
 * @returns Function which sorts by ascending time.
 */
export const ascendingSortFn = (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime();
