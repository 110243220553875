/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Grid-related utility functions.
 */

import moment from 'moment-timezone';
import {PackageType} from '../api/queries/packages';

const DATE_RELEASED_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'HH:mm';

/**
 * Formats a date object into a UTC date time depart/arrival string
 * @param date moment.MomentInput object
 * @returns Formatted date time depart/arrival string in UTC
 */
export const formatDate = (date: moment.MomentInput) => formatMomentInput(date, DATE_RELEASED_FORMAT);
/**
 * Formats a date object into a UTC time string
 * @param date moment.MomentInput object
 * @returns Formatted time string
 */
export const formatTime = (date: moment.MomentInput) => formatMomentInput(date, TIME_FORMAT);
/**
 * Formats a date object into a UTC string
 * @param date moment.MomentInput object
 * @param fmt Format string
 * @returns Formatted date string in UTC
 */
export const formatMomentInput = (date: moment.MomentInput, fmt: string) => moment.utc(date).format(fmt);
/**
 * Sorts the list
 * @param list The unsorted list
 * @param key The key by which to sort
 * @param order The order to sort in (asc or desc)
 * @returns The sorted list
 */
export const sortList = <
  K extends string,
  T extends {
    [key in K]: any;
  }
>(
  list: T[],
  key: K,
  order: 'asc' | 'desc'
): T[] =>
  [...list].sort((a, b) => {
    if (order === 'asc') {
      return a[key] < b[key] ? -1 : 1;
    }
    return a[key] > b[key] ? -1 : 1;
  });

/**
 * Calculates the multi select value
 * @param airlineCodes airline codes
 * @returns boolean to indicate if it is a mutliselect
 */
export const isMultiSelectFunc = (airlineCodes: string[]): boolean => airlineCodes.length > 1;

/**
 * Format approved tails count
 * @param approvedTails approved tails
 * @param applicableTails applicable tails
 * @returns formatted tails count
 */
export const formatApprovedTailsCount = (approvedTails: string[], applicableTails: string[]) =>
  `${approvedTails.length} / ${applicableTails.length}`;

/**
 * Format package type
 * @param packageType
 * @returns formatted package type
 */
export const formatPackageType = (packageType: PackageType) => {
  return {
    config: 'Config',
    sslk: 'SSLK',
    ife: 'IFE',
    portal: 'Portal',
    vcdm: 'VCDM',
    mud: 'MUD',
    other: 'Other'
  }[packageType];
};
