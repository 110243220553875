/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mutation query to Add Update
 */

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {PATHS} from '../../paths';
import useBearStore from '../../utils/stores/useBearStore';
import {spaComs} from '@viasat/insights-spa-package';
import {useNavigate} from 'react-router-dom';

export interface Versions {
  config?: string;
  ife?: string;
  modem?: string;
  portal?: string;
  server?: string;
  vcdm?: string;
  helm_chart?: string;
  other?: string;
}

export interface Package {
  package_type: string;
  versions?: Versions;
  deployment_date?: string;
}

export interface CreateUpdateRequest {
  name: string;
  package: Package;
  airline_code: string;
  notes: string;
  internal_notes?: string;
  links?: string[];
}

export const useAddUpdate = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const airlines = useBearStore(state => state.airlines);

  return useMutation({
    mutationFn: ({route = 'packages/create', params}: {route?: string; params: CreateUpdateRequest}) =>
      fetcher({route, params}),
    onSuccess: async ({id}, {params}) => {
      await queryClient.invalidateQueries(['packages', airlines]);
      if (!airlines.includes(params.airline_code)) spaComs.sendGroupChange(params.airline_code);
      navigate(`${PATHS.updates}/${id}`);
    }
  });
};
