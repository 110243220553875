/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Auth utility functions
 */

import {spaComs} from '@viasat/insights-spa-package';
import useBearStore from './stores/useBearStore';

/**
 * Handles a user logging out
 */
export const logout = () => {
  spaComs.logout();
};

const SESSION_STORAGE_EXCEPTIONS = ['iframeLocation'];
const LOCAL_STORAGE_EXCEPTIONS = ['session', 'redirectUrl', 'entryUrl'];

/**
 * Clears local storage except for the items on the local storage exceptions list.
 */
const safeClearLocalStorage = () => {
  const exception_map: Record<string, string> = LOCAL_STORAGE_EXCEPTIONS.reduce((sum, newItem) => {
    const localStorageValue = localStorage.getItem(newItem);
    return localStorageValue !== null ? {...sum, [newItem]: localStorageValue} : sum;
  }, {});

  localStorage.clear();

  Object.entries(exception_map).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

/**
 * Performs a safe cleaning of session storage, by keeping stuff we still want to keep
 */
const safeClearSessionStorage = () => {
  const exceptionMap: Record<string, string> = SESSION_STORAGE_EXCEPTIONS.reduce((memo, exception) => {
    const sessionStorageValue = sessionStorage.getItem(exception);
    return sessionStorageValue ? {...memo, [exception]: sessionStorageValue} : memo;
  }, {});

  sessionStorage.clear();

  Object.entries(exceptionMap).forEach(([key, value]) => {
    sessionStorage.setItem(key, value);
  });
};

/**
 * Logs out of the app locally
 */
export const localLogout = () => {
  safeClearLocalStorage();
  safeClearSessionStorage();
  useBearStore.getState().reset();
};
