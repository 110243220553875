/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: NotifiedUsersTable component
 */

import {Delete} from '@mui/icons-material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import PersonIcon from '@mui/icons-material/Person';
import {
  IconButton,
  SxProps,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  Theme
} from '@mui/material';
import {tableCellClasses} from '@mui/material/TableCell';
import {tableRowClasses} from '@mui/material/TableRow';
import {spacing} from '@vst/beam';
import React from 'react';
import {strings} from '../locale/strings';
import {Dispatcher} from '../theme/Colors';
import IconTextInABox from './IconTextInABox';
import {HeaderCell, IconTextCell, TableBody, TextCell} from './Tables';
import {PackageType} from '../api/queries/packages';
import {formatPackageType} from '../utils/gridUtils';
import {Airline} from '../api/queries/useAirlines';

export interface NotifiedUser {
  email: string;
  package_types: PackageType[];
  airlines: Airline[];
}

const NotifiedUsersTableHead: React.FC = () => (
  <TableHead>
    <MUITableRow>
      <HeaderCell text={strings.ViasatUser} />
      <HeaderCell text={strings.UpdateType} />
      <HeaderCell text={strings.Airline} sx={{width: '99%'}} />
      <TableCell />
    </MUITableRow>
  </TableHead>
);

const NotifiedUsersTableRow: React.FC<{
  user: NotifiedUser;
  onClickDeleteRow?: (user: NotifiedUser, i: number) => void;
  i: number;
}> = ({user, onClickDeleteRow, i}) => (
  <MUITableRow>
    <IconTextCell Icon={PersonIcon} text={user.email} />
    <TextCell text={user.package_types.map(formatPackageType).join(', ')} />
    <IconTextCell Icon={AirplanemodeActiveIcon} text={user.airlines.map(({name}) => name).join(', ')} />
    <TableCell align="left">
      {onClickDeleteRow && (
        <IconButton data-testid="btnDeleteUser" onClick={() => onClickDeleteRow(user, i)}>
          <Delete htmlColor={Dispatcher.AccessibleGrayIconsAndBorders} />
        </IconButton>
      )}
    </TableCell>
  </MUITableRow>
);

const NotifiedUsersTableEmptyRow: React.FC<{numCols: number}> = ({numCols}) => (
  <MUITableRow>
    <TableCell colSpan={numCols + 1} style={{paddingTop: `${spacing[32]}`, paddingBottom: `${spacing[40]}`}}>
      <IconTextInABox text={strings.NotifiedUsersTable.Empty} />
    </TableCell>
  </MUITableRow>
);

// Sort ascending by email
const defaultSortFn = (a: NotifiedUser, b: NotifiedUser) => a.email.localeCompare(b.email);

/**
 * Table of users
 */
const NotifiedUsersTable: React.FC<{
  users?: NotifiedUser[];
  isLoading: boolean;
  error: boolean;
  onClickDeleteRow?: (user: NotifiedUser, i: number) => void;

  sx?: SxProps<Theme>;
}> = ({users, isLoading, error, onClickDeleteRow, sx}) => {
  const numCols = 4;
  return (
    <TableContainer sx={{flex: 1, padding: 0, ...sx}}>
      <Table data-testid="NotifiedUsersTable">
        <NotifiedUsersTableHead />
        <TableBody
          sx={{[`& .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]: {borderBottom: 'none'}}}
          items={users}
          isLoading={isLoading}
          error={error}
          numCols={numCols}
          renderRow={(user, i) => (
            <NotifiedUsersTableRow key={user.email} user={user} i={i} onClickDeleteRow={onClickDeleteRow} />
          )}
          renderEmpty={() => <NotifiedUsersTableEmptyRow numCols={numCols} />}
          defaultSortFn={defaultSortFn}
        />
      </Table>
    </TableContainer>
  );
};
export default NotifiedUsersTable;
