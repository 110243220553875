/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Global styles for the application
 */

import {css} from '@emotion/react';

// From: https://git.viasat.com/Mobility-Engineering/bizav-client/blob/f42949f88bd7357ce8e66644bc4443433b145bd6/src/components/common/theme/GlobalStyle.tsx
const toolTipAlignment = `
   div[role=tooltip] {
     z-index: 9999;
     opacity: 1 !important;
   }
   div[data-popper-placement*="right"] {
    top: -2px !important;
    &> div {
      left: 0px;
    }
  }
   div[data-popper-placement*="left"] {
     top: -2px !important;
     &> div {
       right: 0px;
     }
   }
   div[data-popper-placement="bottom-start"] {
     &> div {
       right: 10px;
     }
   }
   div[data-popper-placement="bottom-end"] {
     &> div {
       left: 15px;
     }
   }
   div[data-popper-placement="top-start"] {
     &> div {
       right: 10px;
     }
   }
   div[data-popper-placement="top-end"] {
     &> div {
       left: 15px;
     }
   }
   .chart-pie {
     .highcharts-label-box.highcharts-tooltip-box {
       stroke: #000;
     }
   }
   .highcharts-label-box.highcharts-tooltip-box {
     stroke: none;
   }
 
   .highcharts-tooltip-container {
     z-index: 10 !important;
   }
 
   .highcharts-tooltip-container svg.highcharts-root {
     -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .3));
     filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .3));
   }
 `;

const globalStyle = css`
  body {
    -webkit-min-device-pixel-ratio: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    padding-bottom: 24px;
    overflow: hidden;
    ${toolTipAlignment}
  }
`;

export default globalStyle;
