/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Packages Route
 */

export const packageTypes = ['config', 'sslk', 'ife', 'portal', 'vcdm', 'mud', 'other'] as const;

export type PackageType = typeof packageTypes[number];

export enum PackageStatuses {
  AVAILABLE = 'available',
  PARTIALLY_APPROVED = 'partially_approved',
  FULLY_APPROVED = 'fully_approved',
  REJECTED = 'rejected',
  CLOSED = 'closed',
  NO_APPLICABLE_TAILS = 'no_applicable_tails'
}

export const packageStatuses = [
  PackageStatuses.AVAILABLE,
  PackageStatuses.PARTIALLY_APPROVED,
  PackageStatuses.FULLY_APPROVED,
  PackageStatuses.REJECTED,
  PackageStatuses.CLOSED,
  PackageStatuses.NO_APPLICABLE_TAILS
] as const;
export type PackageStatus = typeof packageStatuses[number];

export interface PackageData {
  id: string;
  name: string;
  created_timestamp: string;
  creator_email: string;
  package_type: PackageType;
  airline_name: string;
  airline_code: string;
  deployment_date: string;
  notes: string;
  internal_notes?: string;
  links?: string[];
  applicable_tails: string[];
  approved_tails: string[];
  status: PackageStatus;
  versions:
    | {config: string}
    | {server: string; config: string}
    | {modem: string; config: string}
    | {server: string; modem: string; config: string}
    | {ife: string}
    | {portal: string}
    | {other: string};
}
