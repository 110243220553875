/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Available Updates store
 */
import createViewStore from '../../utils/stores/createViewStore';
import {GridBaseActionType, GridBaseStateType, gridBaseHookActions, gridBaseInitialValues} from '../gridStoreBase';

type State = GridBaseStateType;

type Action = GridBaseActionType & {
  reset: () => void;
};

const initialValues: State = {
  ...gridBaseInitialValues
};

/**
 * Sets up action mapping
 * @param set Set store function
 * @returns Action mappings
 */
const hookActions = (set: (newState: Partial<State>) => void): Action => ({
  ...gridBaseHookActions(set),
  reset: () => set(initialValues)
});

const availableUpdatesStore = createViewStore<State, Action>('available-updates', initialValues, hookActions, []);

export default availableUpdatesStore;
