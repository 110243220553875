/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Link-related utility functions.
 */
import {stringify} from 'qs';
import {FilterRow} from '../store/reducers/FiltersReducer';

/**
 * Gets the current URL base path for the app
 * @returns the base path
 */
export const getCurrentUrlBase = () => {
  // Gen location (allow for port on localhost/tunnel)
  const href = sessionStorage.iframeLocation;
  const cleanedHref = href && href[href.length - 1] === '/' ? href.slice(0, -1) : href;
  return cleanedHref;
};

/**
 * Gets the current URL path for the app
 * @returns the path
 */
export const getCurrentUrlPath = () => {
  // Path always needs to be for the current window, not the parent
  return `${getCurrentUrlBase()}${window.location.pathname}`;
};

/**
 * Gets filters as a search string
 * @param filters filters to be appended
 * @param airlines airlines to be appended
 * @returns the full URL
 */
export const getFiltersToUrl = (filters: FilterRow[], airlines: string[] | null): string =>
  stringify({filters: filters, airlineCode: airlines?.join(',')});
