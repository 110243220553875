/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approval Details component
 */

import * as React from 'react';
import styled from '@emotion/styled';
import {IconButton, Typography} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {DetailsHeading} from './DetailsHeading';
import CloseIcon from '@mui/icons-material/Close';
import {BoxWithTitle} from './BoxWithTitle';
import {VersionInformation, VersionInformationProps} from './VersionInformation';
import {PackageData, PackageStatus} from '../api/queries/packages';
import TailList from './TailList';
import {formatDate, formatPackageType} from '../utils/gridUtils';
import {checkTails} from '../utils/utils';
import {Approval} from '../api/queries/approvals';
import {PATHS} from '../paths';
import {VersionType} from '../versions';
import ApprovalTypeAndTime from './ApprovalTypeAndTime';
import {strings} from '../locale/strings';
import useBearStore from '../utils/stores/useBearStore';

export interface ApprovalDetailsProps {
  approved: boolean;
  title: string;
  url: string;
  versionData?: Array<VersionInformationProps>;
  releaseNotes?: string;
  authDate?: string;
  deploymentDate?: string;
  authBy?: string;
  status?: PackageStatus;
  tails?: string[];
  rejectionReason?: string;
}

const ApprovalDetailsContainer = styled.div<{
  flex: number;
}>`
  flex: ${({flex}) => flex};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[24]} ${spacing[24]} ${spacing[40]};
  gap: ${spacing[32]};
  background: ${colors.gray[100]};
  align-items: stretch;
  min-height: 840px;
  overflow-y: scroll;

  @media screen and (max-width: 1920px) {
    max-width: 530px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 320px;
  }
  @media screen and (max-width: 960px) {
    max-width: 276px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  align-self: stretch;
  flex-grow: 0;
`;

const ShareAndCloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${spacing[16]};
  flex: '1';
  align-items: stretch;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ApprovalDetails: React.FC<{
  approval: Approval & {update: PackageData};
  onClickClose: () => void;
  flex: number;
}> = ({approval, onClickClose, flex}) => {
  const urlBase = useBearStore(state => state.urlBase);
  return (
    <ApprovalDetailsContainer flex={flex} data-testid="ApprovalDetails">
      <HeaderContainer>
        <DetailsHeading
          title={`${approval.update.package_type ? formatPackageType(approval.update.package_type) : ''}  ${
            approval.update.name ?? ''
          }`}
          linkTo={`${urlBase}${PATHS.updates}/${approval.update.id}`}
        />
        <ShareAndCloseContainer data-testid="CloseDetailsButton" onClick={onClickClose}>
          <IconButton>
            <CloseIcon sx={{color: colors.gray[600]}} />
          </IconButton>
        </ShareAndCloseContainer>
      </HeaderContainer>
      <BoxWithTitle title="Versions in update" collapsing={false}>
        {Object.entries(approval.update.versions).map(([versionType, version]) => (
          <div style={{flex: 1}}>
            <VersionInformation versionType={versionType as VersionType} version={version} />
          </div>
        ))}
      </BoxWithTitle>
      <BoxWithTitle title="Release Notes" collapsing={false}>
        <Typography variant="body1">{approval.update.notes}</Typography>
      </BoxWithTitle>
      {approval.approval_type === 'approval' ? (
        <>
          <AuthContainer>
            <div style={{flex: 1}}>
              <BoxWithTitle title="Approved" collapsing={false}>
                <ApprovalTypeAndTime
                  approvalType={approval.approval_type}
                  createdTimestamp={approval.created_timestamp}
                />
              </BoxWithTitle>
            </div>
            <div style={{flex: 1}}>
              <BoxWithTitle title="Approved By" collapsing={false}>
                <Typography variant="body1">{approval.creator_name}</Typography>
              </BoxWithTitle>
            </div>
          </AuthContainer>
          <BoxWithTitle title="Selected Deployment" collapsing={false}>
            <Typography variant="body1">
              <Typography variant="button" fontStyle="normal">
                {formatDate(approval.deployment_date)}
              </Typography>
            </Typography>
          </BoxWithTitle>
          <BoxWithTitle title={strings.SelectedTails} collapsing={true} subtitle={checkTails(approval.approved_tails)}>
            <TailList
              tails={approval.approved_tails}
              showLink={true}
              chipSx={{backgroundColor: colors.white}}
              backgroundColor={colors.gray[100]}
            />
          </BoxWithTitle>
        </>
      ) : (
        <>
          <AuthContainer>
            <div style={{flex: 1}}>
              <BoxWithTitle title="Rejected" collapsing={false}>
                <ApprovalTypeAndTime
                  approvalType={approval.approval_type}
                  createdTimestamp={approval.created_timestamp}
                />
              </BoxWithTitle>
            </div>
            <div style={{flex: 1}}>
              <BoxWithTitle title="Rejected By" collapsing={false}>
                <Typography variant="body1">{approval.creator_name}</Typography>
              </BoxWithTitle>
            </div>
          </AuthContainer>
          <BoxWithTitle title="Reason for rejection" collapsing={false}>
            <Typography variant="body1">{approval.reason}</Typography>
          </BoxWithTitle>
        </>
      )}
    </ApprovalDetailsContainer>
  );
};
