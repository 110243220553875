/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: RejectUpdateDialog component
 */
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import {spacing} from '@vst/beam';
import * as React from 'react';
import {useCallback} from 'react';
import {ModalDialog} from './ModalDialog';
import {Typography} from '@mui/material';

const ReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[8]} 0px;
  gap: ${spacing[8]};
`;

export const RejectUpdateDialog: React.FC<{
  updateName: string;
  open: boolean;
  updateOpen: (open: boolean) => void;
  onRejectClick: (reason: string) => void;
  reasonNotRequired?: boolean;
}> = ({updateName, open, updateOpen, onRejectClick, reasonNotRequired}) => {
  const [reason, setReason] = React.useState('');

  /**
   * Handle click of reject button
   */
  const handleReject = () => {
    onRejectClick(reason);
  };

  /**
   * Handle click of cancel button
   */
  const handleCancel = () => {
    updateOpen(false);
  };

  /**
   * Handle changes to reason field
   */
  const onReasonChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  }, []);

  return (
    <ModalDialog
      title="Reject Update"
      text={
        <>
          <Typography display="inline" variant="subtitle1">
            Are you sure you want to reject update
          </Typography>
          <Typography display="inline" variant="subtitle1">
            &nbsp;
          </Typography>
          <Typography display="inline" variant="subtitle2">
            {updateName}
          </Typography>
          <Typography display="inline" variant="subtitle1">
            ?
          </Typography>
        </>
      }
      open={open}
      displayState="default"
      onOk={handleReject}
      onClose={handleCancel}
      okText="Reject"
      okButtonDisabled={reasonNotRequired ? false : !reason}
    >
      {reasonNotRequired ? null : (
        <ReasonContainer>
          <TextField
            required
            fullWidth
            margin="dense"
            id="reason-input"
            label="Reason"
            InputLabelProps={{shrink: true, style: {fontSize: 18}}}
            helperText="Write a reason for your Technical Account Manager so that we can help you."
            onChange={onReasonChange}
          />
        </ReasonContainer>
      )}
    </ModalDialog>
  );
};
