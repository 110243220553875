/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: TailList component
 */

import styled from '@emotion/styled';
import {Button, Chip, ChipProps, SxProps, Theme} from '@mui/material';
import {colors, fonts, spacing} from '@vst/beam';
import {useState} from 'react';
import {TailListLink} from './TailListLink';
import DetectableOverflow from 'react-detectable-overflow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16]};
  align-items: start;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]};
  align-items: start;
`;

const collapsedStyle = {
  height: '72px', // 2x chip height of 32px, plus 8px gap between chips
  overflow: 'hidden',
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${spacing[8]}`
};

const expandedStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${spacing[8]}`
};

/**
 * Display a list of tails
 */
const TailList: React.FC<{
  tails: string[];
  color?: ChipProps['color'];
  chipSx?: SxProps<Theme> | undefined;
  showLink?: boolean;
  backgroundColor?: string;
}> = ({tails, color, chipSx, showLink, backgroundColor}) => {
  const [collapse, setCollapse] = useState(true);
  const [overflow, setOverflow] = useState(false);

  const expandableButtonStyle = {
    padding: `0px ${spacing[8]}`,
    border: `1px solid ${backgroundColor ?? colors.white}`,
    borderRadius: '4px',
    '&:hover': {
      color: colors.violet[500],
      backgroundColor: backgroundColor ?? colors.white,
      textDecorationLine: 'underline'
    },
    '&:focus': {
      color: colors.violet[500],
      backgroundColor: backgroundColor ?? colors.white,
      border: `1px solid ${colors.violet[500]}`,
      borderRadius: '4px'
    },
    '&:active': {
      color: colors.violet[700],
      backgroundColor: backgroundColor ?? colors.white
    }
  };

  return (
    <Container data-testid="TailList">
      {showLink ? <TailListLink tails={tails} /> : null}
      <ChipContainer>
        <DetectableOverflow onChange={setOverflow} style={collapse ? collapsedStyle : expandedStyle}>
          {tails.map(tail => (
            <Chip
              data-testid="TailChip"
              key={tail}
              label={tail}
              size="medium"
              sx={chipSx}
              color={color}
              style={{font: fonts.label.Small}}
            />
          ))}
        </DetectableOverflow>
        {overflow && (
          <Button
            variant="text"
            onClick={() => {
              setCollapse(!collapse);
            }}
            sx={expandableButtonStyle}
          >
            Show more
          </Button>
        )}
        {!collapse && (
          <Button
            variant="text"
            onClick={() => {
              setCollapse(!collapse);
            }}
            sx={expandableButtonStyle}
          >
            Show less
          </Button>
        )}
      </ChipContainer>
    </Container>
  );
};

TailList.defaultProps = {
  color: 'secondary'
};
export default TailList;
