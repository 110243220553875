/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: CppDetailsTable component
 */

import {Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material';
import {CppRequestHistory} from '../api/queries/useCppRequestHistory';
import {HeaderCell, TextCell} from './Tables';
import {formatDate} from '../utils/gridUtils';

interface CppInformationTableProps {
  data: CppRequestHistory;
}

/**
 * Component for the table header.
 * @returns {ReactElement} The table header component.
 */
const CppInformationTableHead: React.FC = () => (
  <TableHead>
    <TableRow>
      {['Requester', 'Airline', 'Part Number', 'Submitted', 'Last Change', 'Application'].map(header => (
        <HeaderCell key={header} text={header} />
      ))}
    </TableRow>
  </TableHead>
);

/**
 * Component for the table body, which displays the CPP request data
 * @param {CppInformationTableProps} props - The properties that define the component's behavior and display
 * @returns {ReactElement} The table body component
 */
const CppInformationTableBody: React.FC<CppInformationTableProps> = ({data}) => {
  const {creatorName, airlineName, id, createdTimestamp, updatedTimestamp, application} = data;

  return (
    <TableBody>
      <TableRow key={id}>
        <TextCell text={creatorName} sx={{verticalAlign: 'top'}} />
        <TextCell text={airlineName} sx={{verticalAlign: 'top'}} />
        <TextCell text={data.status === 'draft' ? '' : id} sx={{verticalAlign: 'top'}} />
        <TextCell text={data.status === 'draft' ? '' : formatDate(createdTimestamp)} sx={{verticalAlign: 'top'}} />
        <TextCell text={formatDate(updatedTimestamp)} sx={{verticalAlign: 'top'}} />
        <TextCell text={application} sx={{verticalAlign: 'top'}} />
      </TableRow>
    </TableBody>
  );
};

/**
 * Main component that combines the table header and body
 * @param {CppInformationTableProps} props - The properties that define the component's behavior and display
 * @returns {ReactElement} The main table component
 */
const CppInformationTable: React.FC<CppInformationTableProps> = ({data}) => (
  <TableContainer>
    <Table>
      <CppInformationTableHead />
      <CppInformationTableBody data={data} />
    </Table>
  </TableContainer>
);

export default CppInformationTable;
