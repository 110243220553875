/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Cpp Details Page
 */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ShareLink} from '@viasat/insights-components';
import {colors, spacing} from '@vst/beam';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography} from '@mui/material';
import {ExpandMore as ExpandMoreIcon, Delete as DeleteIcon, ContentCopy} from '@mui/icons-material';
import CustomButton from '../../components/CustomButton';
import {v4 as uuidv4} from 'uuid';
import {spaComs} from '@viasat/insights-spa-package';

import BackButton from '../../components/BackButton';
import CppInformationTable from '../../components/CppDetailsTable';
import ReviewIntakeDetails from '../../components/CppIntakeRequestReview';
import ErrorComponent from '../../components/ErrorComponent';
import {Section} from '../../components/Section';
import {ModalDialog} from '../../components/ModalDialog';

import {PATHS} from '../../paths';
import PageContainerTemplate from '../../theme/PageContainerTemplate';

import {getCurrentUrlPath} from '../../utils/linkUtils';
import useBearStore from '../../utils/stores/useBearStore';
import {useCppRequestDraftStore} from '../../utils/stores/cppRequestDraftStore';
import {mapDraftToFetchedFormat, mapFetchedToDraftFormat} from '../../utils/cppUtils';

import {strings} from '../../locale/strings';
import qs from 'qs';
import {statusMap} from '../../components/CppRequestHistoryTable';
import {convertToFinalType} from '../Cpp/CppRequestSteps/CppRequestShared';
import {CppRequest, CppRequestDraft} from '../Cpp/types';
import CppHelmValues from './CppHelmValues';
import {Alert} from '../../components/Alert';
import {useCppRequestDetails} from '../../api/queries/useCppRequestDetails';

type BannerDataProps = {
  show: boolean;
  message: string;
  status: 'error' | 'success';
};

const Banner: React.FC<
  BannerDataProps & {
    onClickClose: () => void;
  }
> = ({show, message, status, onClickClose}) =>
  show ? (
    <Alert
      data-testid="cpp-details-banner"
      bodyText={message}
      status={status}
      closing={true}
      onClickClose={onClickClose}
    />
  ) : (
    <></>
  );

const CppIntakeDetails: React.FC = () => {
  // Use the useBearStore hook to get the airlines state
  const {airlines, isInternal} = useBearStore(state => ({airlines: state.airlines, isInternal: state.init.isInternal}));
  const navigate = useNavigate();
  const {drafts, deleteDraft, updateDraft} = useCppRequestDraftStore();

  const {id} = useParams<string>();
  const {refetch, status, data} = useCppRequestDetails({id: id && drafts.has(id) ? undefined : id});

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<{title: string; message: string; okText: string}>({
    title: '',
    message: '',
    okText: ''
  });
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
  const [helmValuesExpanded, setHelmValuesExpanded] = useState<boolean>(false);
  const [bannerProps, setBannerProps] = useState<BannerDataProps>({show: false, message: '', status: 'success'});

  const handleDuplicate = (cppRequestData: CppRequest) => {
    // Generate a new ID for the duplicate draft
    const newId = uuidv4();

    const duplicateData = {...cppRequestData};

    // Save the duplicate data as a draft
    updateDraft(newId, mapFetchedToDraftFormat(duplicateData));

    // Navigate to the beginning of the intake form with the new ID
    navigate(`${PATHS.cppRequestBase}/${newId}`);
  };

  // Try to get the CPP request data from the drafts using the id. If it doesn't exist in drafts, find it in the fetched data.
  const cppRequestData: CppRequest | undefined =
    id && drafts.has(id) ? mapDraftToFetchedFormat(id, drafts.get(id) as CppRequestDraft) : data;

  const urlPath = getCurrentUrlPath();
  const urlQuery = qs.stringify({}, {arrayFormat: 'comma'});
  const readableStatus = cppRequestData ? statusMap[cppRequestData.status] : undefined;
  const isDraft = cppRequestData?.status === 'draft';
  const isWaitingForHelm = cppRequestData?.status === 'submitted';
  const isReadyToDeploy = cppRequestData?.status === 'ready_to_deploy';

  // Expand the Helm and Values accordion if the request is in the 'submitted' status
  useEffect(() => setHelmValuesExpanded(true), [isWaitingForHelm]);

  // Swap airlines if the current airline is not in the list of available airlines
  useEffect(() => {
    if (!airlines || !data?.airlineCode) return;

    if (!airlines.includes(data.airlineCode)) {
      spaComs.sendGroupChange(data.airlineCode);
    }
  }, [data?.airlineCode, airlines]);

  return status === 'error' ? (
    <ErrorComponent
      headerError={strings.CppIntakeRequestDetailsSection.ThirdPartyApplicationDetails}
      bodyErrorMessage={strings.CppIntakeRequestDetailsSection.NoDataError}
      dataTestId="no-data-details-container"
    />
  ) : (
    <>
      <PageContainerTemplate
        title={cppRequestData?.application}
        subtitle={readableStatus}
        isSubtitleLoading={false}
        getFullElementId={(name: string, type: string) => `${name}-${type}`}
        disableSpaGroupSelector={true}
        preTitleStack={[
          <BackButton label={'Back'} key="backButton" onClick={() => navigate(PATHS.cppRequestHistory)} />
        ]}
        leftStack={[]}
        rightStack={[
          isDraft ? (
            <CustomButton
              getFullElementId={(name: string, type: string) => `cppDetailsDeleteButton__${name}-${type}`}
              open={openDeleteModal}
              onClick={() => {
                setOpenDeleteModal(true);
                setModalContent({
                  title: strings.CppIntakeRequestDetailsSection.DeleteRequest,
                  message: strings.CppIntakeRequestDetailsSection.ConfirmDelete,
                  okText: strings.CppIntakeRequestDetailsSection.DeleteRequest
                });
              }}
              IconComponent={DeleteIcon}
              buttonText="Delete"
            />
          ) : (
            <>
              <ShareLink
                key="shareLink"
                getFullElementId={(name: string, type?: string) => `update__${name}-${type}`}
                disabled={false}
                urlPath={urlPath}
                urlQuery={urlQuery}
              />
              <CustomButton
                getFullElementId={(name: string, type: string) => `cppDetailsDuplicateButton__${name}-${type}`}
                open={openDuplicateModal}
                onClick={() => {
                  setOpenDuplicateModal(true);
                  setModalContent({
                    title: strings.CppIntakeRequestDetailsSection.DuplicateTitle,
                    message: strings.CppIntakeRequestDetailsSection.DuplicateBody,
                    okText: strings.CppIntakeRequestDetailsSection.DuplicateRequest
                  });
                }}
                IconComponent={ContentCopy}
                buttonText="Duplicate"
              />
            </>
          )
        ]}
      >
        <Banner {...bannerProps} onClickClose={() => setBannerProps({show: false, message: '', status: 'success'})} />
        {cppRequestData && (
          <>
            <CppInformationTable data={cppRequestData} />
            <Box sx={{margin: spacing[32]}}>
              <Accordion
                elevation={0}
                expanded={detailsExpanded}
                onChange={() => setDetailsExpanded(value => !value)}
                data-testid="accordion-summary"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{fontSize: 24, fontWeight: 'bold'}} />}
                  sx={{'&:hover': {backgroundColor: colors.gray[100]}}}
                >
                  <Section
                    title={'Intake Request Form'}
                    bodyText={strings.CppIntakeRequestDetailsSection.ReviewDetails}
                    layout="vertical"
                  />
                  <Box sx={{marginRight: 8, display: 'flex', alignItems: 'center'}}>
                    {isDraft && (
                      <Button
                        size="medium"
                        variant="contained"
                        data-testid="continue-button-summary"
                        onClick={() => {
                          navigate(`${PATHS.cppRequestBase}/${id}`);
                        }}
                      >
                        Continue
                      </Button>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails data-testid="accordion-details">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <ReviewIntakeDetails
                      data={convertToFinalType(cppRequestData ?? ({} as CppRequest))}
                      airline={cppRequestData.airlineName}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            {(isWaitingForHelm || isReadyToDeploy) && isInternal && (
              <Box sx={{margin: spacing[32]}}>
                <Accordion
                  elevation={0}
                  expanded={helmValuesExpanded}
                  onChange={() => setHelmValuesExpanded(value => !value)}
                  data-testid="accordion-helm-values"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{fontSize: 24, fontWeight: 'bold'}} />}
                    sx={{'&:hover': {backgroundColor: colors.gray[100]}}}
                  >
                    <Section
                      title={'Helm and Values Links'}
                      bodyText={strings.CppIntakeRequestDetailsSection.HelmDetails}
                      layout="vertical"
                    />
                  </AccordionSummary>
                  <AccordionDetails data-testid="accordion-helm-details">
                    <CppHelmValues
                      cppRequestData={cppRequestData}
                      onSubmitSuccess={async () => {
                        await refetch();
                        setBannerProps({
                          show: true,
                          message: strings.CppIntakeRequestDetailsSection.HelmUpdate.success,
                          status: 'success'
                        });
                      }}
                      onSubmitError={() =>
                        setBannerProps({
                          show: true,
                          message: strings.CppIntakeRequestDetailsSection.HelmUpdate.error,
                          status: 'error'
                        })
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </>
        )}
      </PageContainerTemplate>
      <ModalDialog
        title={modalContent.title}
        displayState="default"
        okText={modalContent.okText}
        cancelText={strings.Cancel}
        open={openDeleteModal || openDuplicateModal}
        onOk={() => {
          if (openDeleteModal) {
            deleteDraft(id as string);
            navigate(PATHS.cppRequestHistory);
          } else if (openDuplicateModal) {
            handleDuplicate(cppRequestData as CppRequest);
          }
          setOpenDeleteModal(false);
          setOpenDuplicateModal(false);
        }}
        onClose={() => {
          setOpenDeleteModal(false);
          setOpenDuplicateModal(false);
        }}
      >
        <Typography variant="body1">{modalContent.message}</Typography>
      </ModalDialog>
    </>
  );
};

export default CppIntakeDetails;
