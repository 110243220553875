/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FormSelect component
 */

import {Checkbox, MenuItem, SxProps, TextField} from '@mui/material';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

interface FormSelectProps {
  options: Record<string, string>;
  name: string;
  required?: boolean;
  label: string;
  defaultValue?: string;
  helperText?: string;
  sx?: SxProps;
  multiline?: boolean;
  multiple?: boolean;
  checkbox?: boolean;
  disabled?: boolean;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  name,
  required = false,
  label,
  disabled,
  helperText = ' ',
  sx = {flex: '1'},
  multiline,
  multiple,
  defaultValue = multiple ? [] : '',
  options,
  checkbox
}) => {
  const {
    control,
    formState: {errors}
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field}) => (
        <TextField
          {...field}
          select
          required={required}
          id={name}
          data-testid={name}
          label={label}
          error={!!errors[name]}
          helperText={!!errors[name] ? errors[name]?.message?.toString() : helperText}
          InputLabelProps={{
            shrink: true
          }}
          SelectProps={{
            multiple,
            renderValue: multiple ? selected => (selected as string[]).map(key => options[key]).join(', ') : undefined
          }}
          sx={sx}
          multiline={multiline}
          disabled={disabled}
        >
          {Object.entries(options).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {checkbox && <Checkbox checked={field.value.indexOf(key) > -1} />}
              {value}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
