/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mutation query to complete the CPP intake process
 */

import {useMutation} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {CppRequestFinalType} from '../../pages/Cpp/types';
import {Airline} from '../queries/useAirlines';
import {CppSubmitRequestResp} from './useCppSubmitRequest';

const useCppRequestComplete = (handleSuccess: () => void, handleError: () => void) =>
  useMutation({
    mutationFn: ({
      inputs,
      airline,
      response
    }: {
      inputs: CppRequestFinalType;
      response: CppSubmitRequestResp;
      airline: Airline;
    }) =>
      fetcher({
        route: `cpp/requestComplete`,
        params: {
          inputs: {
            ...inputs,
            container: {...inputs.container, imageFiles: undefined},
            additional: {...inputs.additional, otherFiles: undefined}
          },
          imageFileNames: inputs.container?.imageFiles.map(file => file.name),
          otherFileNames: inputs.additional?.otherFiles.map(file => file.name),
          airline,
          id: response.id,
          s3Location: response.bucketPath,
          timestamp: response.timestamp
        }
      }),
    onSuccess: () => {
      handleSuccess();
    },
    onError: async () => {
      handleError();
    }
  });

export default useCppRequestComplete;
