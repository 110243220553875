/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Display Update status and type
 */
import styled from '@emotion/styled';
import {PackageStatus} from '../api/queries/packages';
import {StatusIcon} from './StatusIcon';
import {Typography} from '@mui/material';
import {spacing} from '@vst/beam';
import {Dispatcher} from '../theme/Colors';
import {formatApprovedTailsCount} from '../utils/gridUtils';

const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${spacing[8]};
`;

/**
 * Display Update status and tails count
 */
const StatusAndTailsCount: React.FC<{
  status: PackageStatus;
  allowApproval?: boolean;
  approvedTails?: string[];
  applicableTails?: string[];
}> = ({status, allowApproval = false, approvedTails, applicableTails}) => {
  return (
    <Container>
      <StatusIcon status={status} allowApproval={allowApproval} />
      {approvedTails && applicableTails && (
        <Typography variant="body1" color={Dispatcher.AccessibleGray}>
          {formatApprovedTailsCount(approvedTails, applicableTails)}
        </Typography>
      )}
    </Container>
  );
};
export default StatusAndTailsCount;
