/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: useCppRequestHistory
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {CppStatusType} from '../../pages/Cpp/types';

export interface CppRequestHistory {
  id: string;
  status: CppStatusType;
  creatorName: string;
  createdTimestamp: string;
  updatedTimestamp: string;
  application: string;
  airlineName: string;
}

/**
 * Hook for cpp/requestHistory query.
 * @param params The request params.
 * @returns CPP request history array.
 */
export const useCppRequestHistory = (params: {airlines: string[]}) => {
  return useQuery({
    queryKey: ['cppRequestHistory', params.airlines],
    queryFn: () => fetcher({route: 'cpp/requestHistory', params}),
    select: (data): CppRequestHistory[] => data,
    refetchOnWindowFocus: false
  });
};
