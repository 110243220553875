/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: 404 Page
 */
import styled from '@emotion/styled';
import {Button, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {spacing} from '@vst/beam';
import Logo from '@vst/beam-icons/logos/viasat/ViasatLogoDefault';
import DeskDesktop from '../icons/DeskDesktop';
import {Dispatcher} from '../theme/Colors';

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: ${spacing[32]};
  text-align: center;
  margin-top: 80px;
`;

export const Page404: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <DeskDesktop />
      <Typography style={{marginTop: spacing[8]}} variant="h1">
        Sorry, something went wrong.
      </Typography>
      <Typography style={{marginTop: spacing[20], maxWidth: '520px'}} variant="body1" color={Dispatcher.AccessibleGray}>
        We couldn’t find the page you were looking for. Try checking the URL you typed in your browser window or try
        again later.
      </Typography>
      <Button color="primary" style={{marginTop: spacing[40]}} variant="contained" onClick={() => navigate('/updates')}>
        <span style={{margin: spacing[4]}}>Go back to Updates</span>
      </Button>
      <Logo style={{marginTop: spacing[40]}} width="96px" height="32px" />
    </PageWrapper>
  );
};
