/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Cpp Types
 */
import * as yup from 'yup';

export type CppRequestDraft = {
  airline: string;
  airlineName: string;
  application: string;
  containerChanges: string;
  containerInterfaces: string;
  createdTimestamp: string;
  creatorEmail: string;
  creatorName: string;
  details: string;
  endPoints: string;
  environmentVariables: string;
  functionalChanges: string;
  imageFiles: string[];
  labOnly: boolean;
  otherFiles: string[];
  ports: string;
  resourceChanges: string;
  secrets: string;
  trafficPolicies: string;
  updatedTimestamp: string;
  volumeMounts: string;
  lastActiveStep: number;
};

export const REQUIRED_ERROR = 'Please complete this required field.';
export const URL_REQUIRED = 'Please enter a valid URL.';

export const CppRequestDetailsFormSchema = yup.object().shape({
  airline: yup.string().required(REQUIRED_ERROR),
  airlineName: yup.string().required(REQUIRED_ERROR),
  application: yup.string().required(REQUIRED_ERROR)
});

export type CppRequestDetailsFormType = yup.InferType<typeof CppRequestDetailsFormSchema>;

export const CppRequestContainerFormSchema = yup.object().shape({
  containerChanges: yup.string().required(REQUIRED_ERROR),
  functionalChanges: yup.string().optional(),
  containerInterfaces: yup.string().optional(),
  volumeMounts: yup.string().optional(),
  ports: yup.string().optional(),
  trafficPolicies: yup.string().optional(),
  endPoints: yup.string().optional(),
  resourceChanges: yup.string().optional(),
  environmentVariables: yup.string().optional()
});

export type CppRequestContainerFormType = yup.InferType<typeof CppRequestContainerFormSchema>;

export const CppRequestSecretsFormSchema = yup.object().shape({
  secrets: yup.string().optional()
});

export type CppRequestSecretsFormType = yup.InferType<typeof CppRequestSecretsFormSchema>;

export const CppRequestAdditionalFormSchema = yup.object().shape({
  details: yup.string().optional(),
  labOnly: yup.boolean()
});

export type CppRequestAdditionalFormType = yup.InferType<typeof CppRequestAdditionalFormSchema>;

export type CppRequestFinalType = {
  details: CppRequestDetailsFormType | null;
  container: (CppRequestContainerFormType & {imageFiles: File[]}) | null;
  secrets: CppRequestSecretsFormType | null;
  additional: (CppRequestAdditionalFormType & {otherFiles: File[]}) | null;
};

type SetInputsInnerType = (value: CppRequestFinalType) => CppRequestFinalType;
type SetInputsType = (value: SetInputsInnerType) => void;

export type CppButtonSectionProps = {
  activeStep: number;
  setActiveStep: (step: number) => void;
  setInputs: SetInputsType;
  setDraftSaved: (value: boolean) => void;
  setSubmitMode: (mode: boolean) => void;
};

export type CppStepProps = CppButtonSectionProps & {inputs: CppRequestFinalType; id: string | null};

export type CppStatusType = 'draft' | 'submitted' | 'ready_to_deploy';

export const CppHelmValuesFormSchema = yup.object().shape({
  mudAppName: yup.string().required(REQUIRED_ERROR),
  helmLink: yup.string().url(URL_REQUIRED).required(REQUIRED_ERROR),
  valuesLink: yup.string().url(URL_REQUIRED).required(REQUIRED_ERROR)
});

export type CppHelmValuesFormType = yup.InferType<typeof CppHelmValuesFormSchema>;

export type CppHelmUpdateParams = CppHelmValuesFormType & {id: string};

export interface CppRequest {
  id: string;
  airlineCode: string;
  airlineName: string;
  application: string;
  containerChanges: string;
  functionalChanges: string;
  containerImages: string[];
  containerInterfaces: string;
  volumeMounts: string;
  ports: string;
  trafficPolicies: string;
  endpoints: string;
  resourceChanges: string;
  environmentVariables: string;
  secretsAndCertificates: string;
  additionalDetails: string;
  additionalAttachments: string[];
  labOnly: boolean;
  createdTimestamp: string;
  updatedTimestamp: string;
  helmLink: string;
  valuesLink: string;
  creatorEmail: string;
  mudAppName: string;
  s3Location: string;
  status: CppStatusType;
  creatorName: string;
}
