/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: UpdateInformationTable component
 */

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {PackageData} from '../api/queries/packages';
import {formatDate, formatPackageType} from '../utils/gridUtils';
import {versionLabelRecord, VersionType} from '../versions';
import StatusAndTailsCount from './StatusAndTailsCount';
import {HeaderCell, TextCell} from './Tables';

const UpdateInformationTableHead: React.FC<{update: PackageData; displayAirlineColumn: boolean}> = ({
  update,
  displayAirlineColumn
}) => (
  <TableHead>
    <TableRow>
      <HeaderCell text="Status" />
      {displayAirlineColumn && <HeaderCell text="Airline" />}
      <HeaderCell text="Type" />
      <HeaderCell text="Update" />
      <HeaderCell text="Released" />
      {Object.keys(update.versions).map(versionType => (
        <HeaderCell key={versionType} text={versionLabelRecord[versionType as VersionType]} />
      ))}
      <HeaderCell text="Release notes" />
    </TableRow>
  </TableHead>
);

const UpdateInformationTableBody: React.FC<{
  update: PackageData;
  displayAirlineColumn: boolean;
  allowApproval: boolean;
}> = ({update, displayAirlineColumn, allowApproval}) => {
  return (
    <TableBody>
      <TableRow key={update.id}>
        <TableCell align="left" sx={{whiteSpace: 'nowrap', verticalAlign: 'top'}}>
          <StatusAndTailsCount
            status={update.status}
            allowApproval={allowApproval}
            approvedTails={update.approved_tails}
            applicableTails={update.applicable_tails}
          />
        </TableCell>
        {displayAirlineColumn && <TextCell text={update.airline_name} sx={{verticalAlign: 'top'}} />}
        <TextCell text={formatPackageType(update.package_type)} sx={{verticalAlign: 'top'}} />
        <TextCell text={update.name} sx={{verticalAlign: 'top'}} />
        <TextCell text={formatDate(update.created_timestamp)} sx={{verticalAlign: 'top'}} />
        {Object.values(update.versions).map(version =>
          update.package_type === 'portal' ? (
            <TextCell
              key={version}
              text={version}
              tooltip={true}
              maxChars={7}
              ellipsis={true}
              sx={{verticalAlign: 'top'}}
            />
          ) : (
            <TextCell key={version} text={version} sx={{verticalAlign: 'top'}} />
          )
        )}
        <TextCell text={update.notes} sx={{whiteSpace: 'normal', textAlign: 'top', width: '99%'}} />
      </TableRow>
    </TableBody>
  );
};

/**
 * Table displaying a single update
 */
const UpdateInformationTable: React.FC<{
  update: PackageData;
  displayAirlineColumn: boolean;
  allowApproval: boolean;
}> = ({update, displayAirlineColumn, allowApproval}) => (
  <TableContainer>
    <Table>
      <UpdateInformationTableHead update={update} displayAirlineColumn={displayAirlineColumn} />
      <UpdateInformationTableBody
        update={update}
        displayAirlineColumn={displayAirlineColumn}
        allowApproval={allowApproval}
      />
    </Table>
  </TableContainer>
);
export default UpdateInformationTable;
