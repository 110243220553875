/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: UpdateInformationForm component
 */

import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {spacing} from '@vst/beam';
import React from 'react';
import {SubmitHandler, useFormContext} from 'react-hook-form';
import * as yup from 'yup';
import {Airline} from '../api/queries/useAirlines';
import {PackageType, packageTypes} from '../api/queries/packages';
import {AirlineFormSelect} from './AirlineFormSelect';
import FormDispatcherDatePicker from './FormDispatcherDatePicker';
import {FormInput} from './FormInput';
import {UpdateTypeFormSelect} from './UpdateTypeFormSelect';
import {Section} from './Section';
import {Divider} from '@mui/material';
import {strings} from '../locale/strings';
import AddLink from './AddLink';

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap: wrap;
`;

const EmptyDiv = styled.div`
  flex: 1;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[24]};
`;

const required_error = 'Please complete this required field';

// [Airline ICAO] [Aircraft ICAO] [Environment] [Semantic Version] i.e. AAL A321 PROD 7.15.1.64
const configVersionRegex = /^[A-Z]{3} [A-Z]{1}[A-Z0-9]{1,3} [a-zA-Z0-9]+ [0-9]+(\.[0-9]+){3}$/;
// Alphanumeric Characters and "_-."
const genericVersionRegex = /^[a-zA-Z0-9\-_.]+$/;
// Semantic Versioning i.e. 7.15.0.26
const serverVersionRegex = /^[0-9]+(\.[0-9]+){3}$/;
// i.e. 1.3.5.2.112
const modemVersionRegex = /^[0-9]+(\.[0-9]+){4}$/;
// SHA1 hash
const portalVersionRegex = /^[0-9a-f]{5,40}$/;

const URL =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const formSchema = yup.object().shape(
  {
    airline: yup.string().required(required_error),
    updateName: yup.string().required(required_error),
    updateType: yup.string().oneOf(packageTypes).required(required_error),
    deploymentStartDate: yup.object().when('updateType', {
      is: (updateType: PackageType) => updateType === 'ife' || updateType === 'portal',
      then: schema => schema.required(required_error),
      otherwise: schema => schema.notRequired()
    }),
    configVersionNumber: yup
      .string()
      .matches(configVersionRegex, strings.UpdateVersionErrors['config'])
      .when('updateType', {
        is: (updateType: PackageType) => updateType === 'config' || updateType === 'sslk',
        then: schema => schema.required(required_error),
        otherwise: schema => schema.notRequired()
      }),
    vcdmVersionNumber: yup
      .string()
      .matches(genericVersionRegex, strings.UpdateVersionErrors['generic'])
      .when('updateType', {
        is: (updateType: PackageType) => updateType === 'vcdm',
        then: schema => schema.required(required_error),
        otherwise: schema => schema.notRequired()
      }),
    helmChartVersionNumber: yup
      .string()
      .matches(genericVersionRegex, strings.UpdateVersionErrors['generic'])
      .when('updateType', {
        is: (updateType: PackageType) => updateType === 'mud',
        then: schema => schema.required(required_error),
        otherwise: schema => schema.notRequired()
      }),
    serverVersionNumber: yup
      .string()
      // If input is only whitespace, sets it to undefined. This is to allow the field to be empty.
      .transform((value, originalValue) => (originalValue.trim() === '' ? undefined : value))
      .matches(serverVersionRegex, strings.UpdateVersionErrors['server'])
      .when(['updateType', 'modemVersionNumber'], {
        is: (updateType: PackageType, modemVersionNumber: string) => updateType === 'sslk' && !modemVersionNumber,
        then: schema => schema.required('Required if no Modem Version Number is filled.'),
        otherwise: schema => schema.notRequired()
      }),
    modemVersionNumber: yup
      .string()
      // If input is only whitespace, sets it to undefined. This is to allow the field to be empty.
      .transform((value, originalValue) => (originalValue.trim() === '' ? undefined : value))
      .matches(modemVersionRegex, strings.UpdateVersionErrors['modem'])
      .when(['updateType', 'serverVersionNumber'], {
        is: (updateType: PackageType, serverVersionNumber: string) => updateType === 'sslk' && !serverVersionNumber,
        then: schema => schema.required('Required if no Server Version Number is filled.'),
        otherwise: schema => schema.notRequired()
      }),
    ifeContent: yup
      .string()
      .matches(genericVersionRegex, strings.UpdateVersionErrors['generic'])
      .when('updateType', {
        is: (updateType: PackageType) => updateType === 'ife',
        then: schema => schema.required(required_error),
        otherwise: schema => schema.notRequired()
      }),
    portalVersionNumber: yup
      .string()
      .matches(portalVersionRegex, strings.UpdateVersionErrors['portal'])
      .when('updateType', {
        is: (updateType: PackageType) => updateType === 'portal',
        then: schema => schema.required(required_error),
        otherwise: schema => schema.notRequired()
      }),
    otherVersionNumber: yup
      .string()
      .matches(genericVersionRegex, strings.UpdateVersionErrors['generic'])
      .when('updateType', {
        is: (updateType: PackageType) => updateType === 'other',
        then: schema => schema.required(required_error),
        otherwise: schema => schema.notRequired()
      }),
    releaseNotes: yup.string().required(required_error),
    internalNotes: yup.string().notRequired(),
    links: yup.array().of(
      yup.object().shape({
        title: yup.string().ensure().required(required_error),
        url: yup.string().ensure().matches(URL, strings.UpdateVersionErrors['url'])
      })
    )
  },
  [['serverVersionNumber', 'modemVersionNumber']]
);

export type UpdateFormSchema = yup.InferType<typeof formSchema>;

interface UpdateInformationFormProps {
  airlines: Airline[];
  onSubmit: SubmitHandler<UpdateFormSchema>;
}

export const UpdateInformationForm: React.FC<UpdateInformationFormProps> = ({airlines, onSubmit}) => {
  const {handleSubmit, watch} = useFormContext<UpdateFormSchema>();
  const watchUpdateType = watch('updateType');

  const packageTypeSpecificFields: Record<PackageType, React.ReactElement> = {
    config: (
      <FormRow>
        <FormDispatcherDatePicker name="deploymentStartDate" />
        <FormInput name="configVersionNumber" label="Config Version Number" required />
        <EmptyDiv />
      </FormRow>
    ),
    sslk: (
      <>
        <FormRow>
          <FormDispatcherDatePicker name="deploymentStartDate" />
          <EmptyDiv />
          <EmptyDiv />
        </FormRow>
        <FormRow>
          <FormInput
            name="serverVersionNumber"
            label="Server Version Number"
            helperText="Required if no Modem Version Number is filled."
            triggerValidation
            dependentFields={['modemVersionNumber']}
          />
          <FormInput
            name="modemVersionNumber"
            label="Modem Version Number"
            helperText="Required if no Server Version Number is filled."
            triggerValidation
            dependentFields={['serverVersionNumber']}
          />
          <FormInput name="configVersionNumber" label="Config Version Number" required />
        </FormRow>
      </>
    ),
    ife: (
      <FormRow>
        <FormDispatcherDatePicker name="deploymentStartDate" required />
        <FormInput name="ifeContent" label="Snapshot ID" required />
        <EmptyDiv />
      </FormRow>
    ),
    portal: (
      <FormRow>
        <FormDispatcherDatePicker name="deploymentStartDate" required />
        <FormInput name="portalVersionNumber" label="Portal Version Number" required />
        <EmptyDiv />
      </FormRow>
    ),
    vcdm: (
      <FormRow>
        <FormDispatcherDatePicker name="deploymentStartDate" />
        <FormInput name="vcdmVersionNumber" label="VCDM Firmware Version Number" required />
        <EmptyDiv />
      </FormRow>
    ),
    mud: (
      <FormRow>
        <FormDispatcherDatePicker name="deploymentStartDate" />
        <FormInput name="helmChartVersionNumber" label="Helm Chart Version Number" required />
        <EmptyDiv />
      </FormRow>
    ),
    other: (
      <FormRow>
        <FormDispatcherDatePicker name="deploymentStartDate" />
        <FormInput name="otherVersionNumber" label="Version Number" required />
        <EmptyDiv />
      </FormRow>
    )
  };

  return (
    <>
      <Section title="UPDATE INFORMATION" bodyText="Visible to airline and Viasat" layout="horizontal">
        <FormContainer>
          <FormRow>
            <AirlineFormSelect airlines={airlines} />
            <UpdateTypeFormSelect packageTypes={packageTypes} />
            <FormInput name="updateName" label="Update Name" required />
          </FormRow>
          {packageTypeSpecificFields[watchUpdateType]}
          <FormInput
            name="releaseNotes"
            label="Release Notes"
            helperText="Release notes will help airlines approving this update."
            multiline
            required
          />
        </FormContainer>
      </Section>

      <Divider style={{flex: 1, marginTop: spacing[32], marginBottom: spacing[32]}} />

      <Section title="Internal Notes" bodyText="Visible to Viasat only" layout="horizontal">
        <FormContainer style={{gap: '0px'}}>
          <FormRow>
            <FormInput name="internalNotes" label="Internal Notes" multiline />
          </FormRow>
          <AddLink />
        </FormContainer>
      </Section>

      <Divider style={{flex: 1, marginTop: spacing[32], marginBottom: spacing[32]}} />

      <Button size="medium" variant="contained" onClick={handleSubmit(onSubmit)}>
        Create New Update
      </Button>
    </>
  );
};
