/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Retrieve CPP request details
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {CppRequest} from '../../pages/Cpp/types';

/**
 * Hook for cpp/requestDetails query.
 * @param params The request params.
 * @returns CPP request history array.
 */
export const useCppRequestDetails = ({id}: {id: string | undefined}) => {
  return useQuery({
    queryKey: ['cppRequestDetails', id],
    queryFn: () => fetcher({route: `cpp/requestDetails/${id}`, params: {}}),
    select: (data): CppRequest => data,
    refetchOnWindowFocus: false,
    enabled: Boolean(id)
  });
};
