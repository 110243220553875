/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Init Data Route
 */

import {useQuery} from '@tanstack/react-query';
import {AppConfigs} from '../../types';
import {fetcher} from '../../utils/utils';

export interface InitData {
  isAdmin: boolean;
  isInternal: boolean;
  name: string;
  email: string;
  uid: string;
  approvalAirlineCodes: string[];
  appConfigs: AppConfigs;
}

export const useInitData = (params: {airlines: string[]} | undefined) =>
  useQuery<InitData>({
    queryKey: ['init', params?.airlines],
    queryFn: () => fetcher({route: 'init', params}),
    refetchOnWindowFocus: false,
    enabled: Boolean(params),
    staleTime: Infinity,
    cacheTime: Infinity
  });
