/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: BoxWithTitle component
 */

import * as React from 'react';
import {KeyboardArrowDown} from '@mui/icons-material';
import styled from '@emotion/styled';
import {SectionLabel} from './SectionLabel';
import {colors, spacing} from '@vst/beam';

export interface BoxWithTitleProps {
  title: string;
  subtitle?: string;
  collapsing: boolean;
}

const BoxWithTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: ${spacing[24]};
  flex: 1;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  flex-wrap: wrap;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const ExpandMoreButton = styled.button`
  flex: none;
  order: 1;
  flex-grow: 0;
  background: none;
  border: none;
`;

const SectionLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
`;

export const BoxWithTitle: React.FC<BoxWithTitleProps & React.PropsWithChildren> = ({
  children,
  title,
  subtitle,
  collapsing = false
}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const rotateExpandButton = collapsed ? 'rotate(0)' : 'rotate(180deg)';
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const sectionLabelArgs = {
    title,
    subtitle
  };

  return (
    <BoxWithTitleContainer data-testid="BoxWithTitle">
      <SectionLabelContainer>
        <SectionLabel {...sectionLabelArgs}></SectionLabel>
        {collapsing && (
          <ExpandMoreButton onClick={toggle}>
            <KeyboardArrowDown
              sx={{color: colors.violet['500']}}
              style={{transform: rotateExpandButton, transition: 'all 0.2s linear'}}
            />
          </ExpandMoreButton>
        )}
      </SectionLabelContainer>
      {!collapsed && <ContentContainer>{children}</ContentContainer>}
    </BoxWithTitleContainer>
  );
};
