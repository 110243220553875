/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FormDispatcherDatePicker component
 */
import {TextField} from '@mui/material';
import React, {useState} from 'react';
import {colors, spacing} from '@vst/beam';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment, {Moment} from 'moment';
import {Dispatcher} from '../theme/Colors';
import {popperSx} from './ApprovalForm';
import {Controller, useFormContext} from 'react-hook-form';

interface FormDispatcherDatePickerProps {
  name: string;
  required?: boolean;
  helperText?: string;
}

const FormDispatcherDatePicker: React.FC<FormDispatcherDatePickerProps> = ({
  required = false,
  name,
  helperText = ' '
}) => {
  const firstPossibleDeploymentDate = moment().utc(); // today (UTC)
  const [deploymentDate, setDeploymentDate] = useState<Moment>(moment(firstPossibleDeploymentDate.clone()));
  const [datePickerFocus, setDatePickerFocus] = useState(false);
  const {
    control,
    formState: {errors}
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={deploymentDate}
      render={({field: {onChange, ...restFields}}) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div style={{flex: '1', gap: '50px'}}>
            <DatePicker
              disablePast
              label="Start of deployment (UTC Time)"
              minDate={firstPossibleDeploymentDate}
              PaperProps={{
                sx: popperSx
              }}
              onOpen={() => setDatePickerFocus(true)}
              onClose={() => setDatePickerFocus(false)}
              OpenPickerButtonProps={{
                className: 'calendarButton',
                sx: {
                  padding: `0 0 0 ${spacing[12]}`,
                  '&:hover': {
                    background: 'none'
                  },
                  '& svg': {
                    fontSize: spacing[24],
                    color: Dispatcher.AccessibleGrayIconsAndBorders
                  },
                  '& svg:hover': {
                    color: Dispatcher.AccessibleGray
                  },
                  '& svg:active': {
                    color: colors.violet[600]
                  }
                }
              }}
              InputAdornmentProps={{position: 'start'}}
              components={{
                OpenPickerIcon: CalendarTodayIcon
              }}
              onChange={date => {
                onChange(date);
                setDeploymentDate(date ?? firstPossibleDeploymentDate);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  focused={datePickerFocus}
                  onFocus={() => setDatePickerFocus(true)}
                  onBlur={() => {
                    setDatePickerFocus(false);
                  }}
                  fullWidth
                  required={required}
                  sx={{
                    [`& .${outlinedInputClasses.root}`]: {
                      paddingLeft: spacing[12]
                    },
                    [`& .${outlinedInputClasses.input}`]: {
                      padding: `${spacing[16]} ${spacing[12]} ${spacing[16]} 0px`
                    }
                  }}
                  error={!!errors[name]}
                  helperText={!!errors[name] ? errors[name]?.message?.toString() : helperText}
                />
              )}
              {...restFields}
            />
          </div>
        </LocalizationProvider>
      )}
    />
  );
};

export default FormDispatcherDatePicker;
