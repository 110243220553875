/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FormDialog component
 */
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import * as React from 'react';
import {useEffect, useMemo} from 'react';
import {DefaultValues, FieldPath, FieldValues, FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {ModalDialog} from './ModalDialog';
import {strings} from '../locale/strings';

interface FormDialogProps<TFieldValues extends FieldValues, TDependencies> {
  title: string;
  text?: string;
  open: boolean;
  onSubmit: (response: TFieldValues) => void;
  onClose: () => void;
  okText: string;
  formSchema: yup.ObjectSchema<TFieldValues>; // yup schema for the form
  defaultValues?: (dependencies: TDependencies) => DefaultValues<TFieldValues>; // Default values for the form
  isLoading?: boolean;
  renderForm: (dependencies: TDependencies) => React.ReactNode; // Actual Form
  dependencies?: TDependencies;
  errors?: Array<{field: FieldPath<TFieldValues> | `root.${string}` | 'root'; message: string}>;
}

/**
 * Generic modal dialog that contains a form
 */
export const FormDialog = <TFieldValues extends FieldValues, TDependencies>({
  title,
  text,
  open,
  onSubmit,
  onClose,
  okText,
  formSchema,
  defaultValues: getDefaultValues,
  isLoading,
  renderForm,
  dependencies,
  errors
}: FormDialogProps<TFieldValues, TDependencies>) => {
  const defaultValues = useMemo(
    () => dependencies && getDefaultValues?.(dependencies),
    [dependencies, getDefaultValues]
  );
  const formMethods = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(formSchema)
  });

  useEffect(() => {
    errors?.forEach(({field, message}) => {
      formMethods.setError(field, {type: 'custom', message});
    });
  }, [errors, formMethods]);

  useEffect(() => {
    if (!open) {
      formMethods.reset(defaultValues);
    }
  }, [open, formMethods, defaultValues]);
  return (
    <FormProvider {...formMethods}>
      <ModalDialog
        title={title}
        text={text}
        open={open}
        displayState={!dependencies || isLoading ? 'loading' : 'default'}
        loadingText={strings.Loading}
        onOk={() => {
          formMethods.handleSubmit(data => {
            onSubmit(data);
          })();
        }}
        onClose={onClose}
        okText={okText}
        okButtonDisabled={!formMethods.formState.isDirty}
      >
        {dependencies && renderForm(dependencies)}
      </ModalDialog>
    </FormProvider>
  );
};
