/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Display approval progress
 */

import styled from '@emotion/styled';
import {LinearProgress, Typography} from '@mui/material';
import {Dispatcher} from '../theme/Colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/**
 * Display Approval Progress
 */
const ApprovalProgress: React.FC<{
  numerator: number;
  denominator: number;
}> = ({numerator, denominator}) => {
  return (
    <Container>
      <Typography color={Dispatcher.AccessibleGray}>{`${numerator} / ${denominator} tails approved`}</Typography>
      {numerator ? <LinearProgress value={(numerator / denominator) * 100} variant={'determinate'} /> : null}
    </Container>
  );
};

export default ApprovalProgress;
