/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: MUI theme for Dispatcher
 */

import {createTheme} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {Dispatcher} from './Colors';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';

const body2 = {
  fontFamily: 'Source Sans Pro',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '20px'
};
export const muiTheme = createTheme({
  palette: {
    common: {
      black: colors.gray[800]
    },
    primary: {
      main: colors.violet[500],
      light: colors.violet[300],
      dark: colors.violet[700],
      contrastText: colors.white
    },
    secondary: {
      main: Dispatcher.Secondary.Violet[0],
      light: colors.white,
      dark: colors.violet[100],
      contrastText: colors.violet[500]
    }
  },
  typography: {
    allVariants: {
      color: colors.gray[800]
    },
    fontFamily: 'Source Sans Pro',
    h1: {
      fontFamily: 'Uni Neue',
      fontWeight: 800,
      fontSize: 28,
      lineHeight: '36px'
    },
    h2: {
      fontFamily: 'Uni Neue',
      fontWeight: 800,
      fontSize: 18,
      lineHeight: '24px'
    },
    h3: {
      fontFamily: 'Uni Neue',
      fontWeight: 800,
      fontSize: 14,
      lineHeight: '24px',
      textTransform: 'uppercase'
    },
    h5: {
      fontFamily: 'Uni Neue',
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '28px',
      textTransform: 'uppercase'
    },
    h6: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px'
    },
    subtitle1: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px'
    },
    subtitle2: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '28px'
    },
    body1: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px'
    },
    body2,
    button: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'none'
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          width: spacing[104]
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: Dispatcher.Secondary.Violet[0]
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          color: colors.white,
          backgroundColor: colors.violet[700],
          padding: '8px 12px'
        },
        arrow: {
          color: colors.violet[700]
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.gray[800],
          textDecoration: 'underline',
          textDecorationThickness: 1.5,
          textDecorationColor: colors.violet[500],
          textUnderlinePosition: 'under',
          padding: `${spacing[4]} ${spacing[8]}`,
          '&:hover': {
            color: colors.violet[500],
            textDecorationColor: colors.violet[500]
          },
          '&:active': {
            color: colors.violet[600],
            textDecoration: 'none',
            fontWeight: 600
          },
          '&:focus': {
            textDecoration: 'none',
            outline: `${colors.violet[500]} auto 1.5px`,
            outlineOffset: 'unset'
          },
          '&:visited': {
            color: colors.violet[600],
            textDecorationColor: colors.violet[500]
          },

          '&.linksHeading': {
            '&:active': {
              fontWeight: 800
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: Dispatcher.AccessibleGrayIconsAndBorders
          },
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            border: `solid 2px ${Dispatcher.AccessibleGray}`
          },
          [`&:hover.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            border: `solid 2px ${colors.alert[400]}`
          },
          [`&.Mui-focused.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            border: `solid 2px ${colors.alert[400]}`
          },
          [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            color: colors.alert[400]
          },
          [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: colors.gray[200],
            border: `solid 1px ${colors.gray[200]}`
          }
        },
        input: {
          WebkitTextFillColor: colors.gray[800],
          '&.Mui-disabled': {WebkitTextFillColor: Dispatcher.AccessibleGray}
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        focusRipple: false
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Source Sans Pro',
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '24px',
          '&.Mui-disabled': {
            color: Dispatcher.AccessibleGrayIconsAndBorders
          },
          '&.Mui-error': {
            color: colors.alert[400]
          }
        }
      },
      variants: [
        {
          props: {variant: 'contained'},
          style: {
            textTransform: 'none',
            borderRadius: 28,
            '&:disabled': {
              backgroundColor: Dispatcher.AccessibleGrayIconsAndBorders,
              color: colors.white
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${colors.violet[300]}`
            }
          }
        },
        {
          props: {variant: 'outlined'},
          style: {
            color: colors.gray[800],
            border: 'unset',
            outline: `1px solid ${colors.gray[800]}`,
            textTransform: 'none',
            borderRadius: 28,
            '&:disabled': {
              backgroundColor: colors.white,
              color: Dispatcher.AccessibleGrayIconsAndBorders,
              outline: `1px solid ${Dispatcher.AccessibleGrayIconsAndBorders}`
            },
            '&:hover': {
              backgroundColor: colors.gray[800],
              color: colors.white,
              border: 'unset'
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${colors.gray[800]}`
            }
          }
        }
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...body2,
          color: colors.gray[600],
          '&.Mui-disabled': {
            WebkitTextFillColor: Dispatcher.AccessibleGray
          },
          '&.Mui-error': {
            color: colors.alert[400]
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          paddingTop: spacing[24],
          paddingBottom: spacing[24],
          paddingLeft: spacing[32],
          paddingRight: spacing[32]
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          color: Dispatcher.AccessibleGray,
          '&.Mui-disabled': {
            WebkitTextFillColor: Dispatcher.AccessibleGray
          },
          '&.Mui-focused': {
            WebkitTextFillColor: colors.violet[600]
          },
          '&.Mui-error': {
            WebkitTextFillColor: colors.alert[400]
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: spacing[16],
          paddingBottom: spacing[16],
          paddingLeft: spacing[24],
          paddingRight: spacing[24]
        },
        head: {
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.gray[100],
            borderLeft: `2px solid ${colors.violet[600]}`
          },
          '&.Mui-hover': {
            backgroundColor: colors.gray[100]
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.gray[100]
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '58px'
        }
      }
    }
  }
});
