/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Available Updates View
 */
import React from 'react';

import {ShareLink} from '@viasat/insights-components';
import {useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {isMultiSelectFunc} from '../../utils/gridUtils';
import {getCurrentUrlPath, getFiltersToUrl} from '../../utils/linkUtils';
import {useUpdates} from '../../api/queries/useUpdates';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import {PATHS} from '../../paths';
import styled from '@emotion/styled';
import {PageProps} from '../../types';
import UpdatesTable from '../../components/UpdatesTable';
import useBearStore from '../../utils/stores/useBearStore';
import {UpdatesFilter} from '../../components/UpdatesFilter';
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';
import useUrlParams from '../../utils/useUrlParams';
import availableUpdatesStore from './availableUpdatesStore';

const AddUpdateSection = styled.div`
  margin: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
`;

const AvailableUpdates: React.FC<PageProps> = ({title}) => {
  const {
    init: {isAdmin, approvalAirlineCodes},
    airlines
  } = useBearStore(state => ({
    init: state.init,
    airlines: state.airlines
  }));

  const {data: updatesData, isLoading, isError} = useUpdates({airlines});
  const navigate = useNavigate();

  const handleAddUpdateClick = () => {
    navigate(PATHS.createUpdate);
  };

  const {updatesFilters, setUpdatesFilters, loadUrlParams} = availableUpdatesStore(state => ({
    loadUrlParams: state.loadUrlParams,
    updatesFilters: state.filters,
    setUpdatesFilters: state.setFilters
  }));

  const urlPath = getCurrentUrlPath();
  const urlQuery = getFiltersToUrl(updatesFilters, airlines);

  useUrlParams(loadUrlParams);

  return (
    <PageContainerTemplate
      title={title}
      isSubtitleLoading={false}
      subtitle={''}
      getFullElementId={(name: string, type: string) => `${name}-${type}`}
      leftStack={[
        <UpdatesFilter
          updates={updatesData}
          filters={updatesFilters}
          onFiltersChange={(filters: FilterRow[]) => {
            setUpdatesFilters(filters);
          }}
        />
      ]}
      rightStack={[
        <ShareLink
          key="shareLink"
          getFullElementId={(name: string, type?: string) => `${title}__${name}-${type}`}
          disabled={false}
          urlPath={urlPath}
          urlQuery={urlQuery}
        />
      ]}
    >
      <UpdatesTable
        filters={updatesFilters}
        updates={updatesData}
        isLoading={isLoading}
        error={isError}
        onClickUpdateRow={({id}) => {
          navigate(id);
        }}
        displayAirlineColumn={isMultiSelectFunc(airlines ?? [])}
        allowApproval={update =>
          !isAdmin &&
          approvalAirlineCodes?.includes(update.airline_code) &&
          ['available', 'partially_approved'].includes(update.status)
        }
        isAdmin={isAdmin}
      />

      {isAdmin ? (
        <AddUpdateSection>
          <Button size="large" variant="contained" onClick={handleAddUpdateClick} startIcon={<AddIcon />}>
            Create Update
          </Button>
        </AddUpdateSection>
      ) : null}
    </PageContainerTemplate>
  );
};

export default AvailableUpdates;
