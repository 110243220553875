/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: TailListLink component
 */

import {SxProps, Theme} from '@mui/material';
import {strings} from '../locale/strings';
import {comAvTailListLinkUrl} from '../utils/utils';
import ExternalLink from './ExternalLink';
import useBearStore from '../utils/stores/useBearStore';
export const TailListLink: React.FC<{
  tails: string[];
  text?: string;
  sx?: SxProps<Theme>;
}> = ({tails, text, sx}) => {
  const urlBase = useBearStore(state => state.urlBase);

  return (
    <ExternalLink href={comAvTailListLinkUrl(urlBase, tails)} sx={sx}>
      {text}
    </ExternalLink>
  );
};

TailListLink.defaultProps = {
  text: strings.TailStatusOnThisSelection
};
