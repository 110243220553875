/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Components related to tables
 */

import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  SvgIconTypeMap,
  SxProps,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  TableBody as MUITableBody,
  Skeleton
} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {colors, spacing} from '@vst/beam';
import {Dispatcher} from '../theme/Colors';
import {strings} from '../locale/strings';

/**
 * A box representing an error
 */
const Error: React.FC<{text: string}> = ({text}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: spacing[8],
      padding: spacing[32]
    }}
  >
    <ErrorIcon color="primary" />
    <Typography variant="subtitle1" color={Dispatcher.AccessibleGray}>
      {text}
    </Typography>
  </div>
);

/**
 * Table header cell
 */
export const HeaderCell: React.FC<{text: string | JSX.Element; sx?: SxProps<Theme>}> = ({text, sx}) => (
  <TableCell sx={sx}>
    <Typography variant="h6" color={Dispatcher.AccessibleGray}>
      {text}
    </Typography>
  </TableCell>
);

/**
 * Typography for text cell
 */
export const TextCellTypography: React.FC<{
  text: string;
  ellipsis?: boolean;
  maxChars?: number;
  color?: string;
}> = ({text, ellipsis, maxChars, color}) => {
  const textSx = {
    width: maxChars ? (ellipsis ? `${maxChars + 3}ch` : `${maxChars}ch`) : 'auto'
  };
  return (
    <Typography
      variant="body1"
      color={color ?? colors.gray[800]}
      sx={ellipsis ? {...textSx, overflow: 'hidden', textOverflow: 'ellipsis'} : textSx}
    >
      {text}
    </Typography>
  );
};

/**
 * Table cell containing text
 */
export const TextCell: React.FC<{
  text: string;
  ellipsis?: boolean;
  tooltip?: boolean;
  maxChars?: number;
  sx?: SxProps<Theme>;
  color?: string;
}> = ({text, ellipsis, tooltip, maxChars, sx, color}) => (
  <TableCell align="left" sx={{whiteSpace: 'nowrap', ...(ellipsis ? {maxWidth: 0} : {}), ...sx}}>
    {tooltip ? (
      <Tooltip title={text} placement="top" arrow>
        {<TextCellTypography text={text} ellipsis={ellipsis} maxChars={maxChars} color={color} />}
      </Tooltip>
    ) : (
      <TextCellTypography text={text} ellipsis={ellipsis} maxChars={maxChars} color={color} />
    )}
  </TableCell>
);

/** Table cell containing icon and text */
export const IconTextCell: React.FC<{
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  text: string;
}> = ({Icon, text}) => (
  <TableCell align="left">
    <Box sx={{display: 'flex', gap: spacing[8], alignItems: 'center'}}>
      <Icon htmlColor={Dispatcher.AccessibleGrayIconsAndBorders} />
      <TextCellTypography text={text} />
    </Box>
  </TableCell>
);

/**
 * Row representing an error
 */
export const ErrorRow: React.FC<{text: string; colSpan: number}> = ({text, colSpan}) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <Error text={text} />
    </TableCell>
  </TableRow>
);

/**
 * Skeleton row
 */
export const LoadingRow: React.FC<{i: number; numCols: number}> = ({i, numCols}) => (
  <TableRow key={i}>
    {Array.from({length: numCols}, (_, j) => (
      <TableCell align="left" key={j}>
        <Skeleton animation="wave" variant="rounded" height={12} role="skeleton" />
      </TableCell>
    ))}
  </TableRow>
);

export const TableBody = <T,>({
  items,
  defaultSortFn,
  isLoading,
  error,
  numCols,
  renderRow,
  renderEmpty,
  sx
}: {
  items?: T[];
  defaultSortFn?: (a: T, b: T) => number;
  isLoading: boolean;
  error: boolean;
  numCols: number;
  renderRow: (item: T, i: number) => React.ReactNode;
  renderEmpty: () => React.ReactNode;
  sx?: SxProps<Theme> | undefined;
}) => {
  return (
    <MUITableBody sx={sx}>
      {error === true ? (
        <ErrorRow key="error" colSpan={numCols} text={strings.SomethingWentWrongTryRefreshingYourBrowser} />
      ) : isLoading ? (
        Array.from({length: 4}, (_, i) => <LoadingRow key={i} i={i} numCols={numCols} />)
      ) : items != null && items.length > 0 ? (
        items.sort(defaultSortFn).map((item, i) => {
          return renderRow(item, i);
        })
      ) : (
        renderEmpty()
      )}
    </MUITableBody>
  );
};
