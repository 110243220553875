/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approvals Filter
 */
import {FilterSelector} from '@viasat/insights-components';
import {formatPackageType} from '../utils/gridUtils';
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';
import {packageTypes} from '../api/queries/packages';
import React, {useEffect, useState} from 'react';
import {Dispatcher} from '../theme/Colors';
import {Approval, ApprovalTypes} from '../api/queries/approvals';
import {FilterSelectorOption, UpdateApproval} from '../types';

/**
 * Determines if the approval history record passes the status filter.
 *
 * @param filter The status filter row.
 * @param approval The approval record.
 * @return Whether the approval record passes the filter.
 */
const filterStatuses = (filter: FilterRow, approval: Approval): boolean => {
  return (
    filter.rangeOptionsKeys?.some((status: string) => approval.approval_type === status) ||
    filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the approval history record passes the approvers filter.
 *
 * @param filter The approver filter row.
 * @param approval The approval record.
 * @return Whether the approval record passes the filter.
 */
const filterApprovers = (filter: FilterRow, approval: Approval): boolean => {
  return (
    filter.rangeOptionsKeys?.some((approver: string) => approval.creator_name === approver) ||
    filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the approval passes the applicable tails filter.
 *
 * @param filter The applicable tails filter row.
 * @param approval The approval.
 * @return Whether the approval passes the filter.
 */
const filterApplicableTails = (filter: FilterRow, approval: UpdateApproval): boolean => {
  return (
    filter.rangeOptionsKeys?.some((tail: string) => approval.approved_tails.includes(tail)) ||
    filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the approval passes the update type filter.
 *
 * @param filter The udpate type filter row.
 * @param approval The approval.
 * @return Whether the approval passes the filter.
 */
const filterUpdateType = (filter: FilterRow, approval: UpdateApproval): boolean => {
  return (
    filter.rangeOptionsKeys?.some(
      (updateType: string) => approval.update.package_type.toLowerCase() === updateType.toLowerCase()
    ) || filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the approval passes the update name filter.
 *
 * @param filter The udpate name filter row.
 * @param approval The approval.
 * @return Whether the approval passes the filter.
 */
const filterUpdateName = (filter: FilterRow, approval: UpdateApproval): boolean =>
  filter.rangeOptionsKeys?.some((name: string) => approval.update.name === name) ||
  filter.rangeOptionsKeys?.length === 0;

/**
 * Apply filters to approvals.
 *
 * @param filters Array of filters.
 * @param approvals Array of approvals.
 * @return Array of approvals which pass all filters.
 */
export const filterApprovals = (filters: FilterRow[], approvals: UpdateApproval[]): UpdateApproval[] => {
  return approvals.filter(approval => {
    let included: boolean = true;

    for (const filter of filters) {
      const domain = filter.domainOptionsKey;

      switch (domain) {
        case 'updateName':
          included = included && filterUpdateName(filter, approval);
          break;
        case 'updateType':
          included = included && filterUpdateType(filter, approval);
          break;
        case 'applicableTails':
          included = included && filterApplicableTails(filter, approval);
          break;
        case 'status':
          included = included && filterStatuses(filter, approval);
          break;
        case 'approver':
          included = included && filterApprovers(filter, approval);
          break;
      }
    }

    return included;
  });
};

export const ApprovalsFilter: React.FC<{
  approvals?: UpdateApproval[];
  filters: FilterRow[];
  onFiltersChange: (filters: FilterRow[]) => void;
}> = ({approvals, filters, onFiltersChange}) => {
  const [updateNameOptions, setUpdateNameOptions] = useState<FilterSelectorOption[]>([]);
  const [applicableTailOptions, setApplicableTailOptions] = useState<FilterSelectorOption[]>([]);
  const [approverOptions, setApproverOptions] = useState<FilterSelectorOption[]>([]);
  const updateTypeOptions: FilterSelectorOption[] = packageTypes.map(packageType => ({
    optionKey: packageType,
    optionValue: formatPackageType(packageType)
  }));

  const statusOptions: Array<FilterSelectorOption> = [
    {
      optionKey: ApprovalTypes.APPROVAL,
      optionValue: 'Approved'
    },
    {
      optionKey: ApprovalTypes.REJECTION,
      optionValue: 'Rejected'
    }
  ];

  const domainOptions = [
    {
      optionKey: 'updateName',
      optionValue: 'Update'
    },
    {
      optionKey: 'updateType',
      optionValue: 'Type'
    },
    {
      optionKey: 'applicableTails',
      optionValue: 'Applicable Tails'
    },
    {
      optionKey: 'status',
      optionValue: 'Status'
    },
    {
      optionKey: 'approver',
      optionValue: 'Approver'
    }
  ];

  const rangeOptions = {
    updateName: updateNameOptions,
    updateType: updateTypeOptions,
    applicableTails: applicableTailOptions,
    status: statusOptions,
    approver: approverOptions
  };

  // Update the filter options when the updates data changes
  useEffect(() => {
    const nonUniqueTails: string[] = [];
    const updateNamesSet: Set<string> = new Set();
    const approversSet: Set<string> = new Set();
    const tailOptions: FilterSelectorOption[] = [];

    // Create selector options from available updates information
    approvals?.forEach(approval => {
      nonUniqueTails.push(...approval.approved_tails);
      updateNamesSet.add(approval.update.name);
      approversSet.add(approval.creator_name);
    });

    // Make sure there are no duplicate tail options
    new Set(nonUniqueTails).forEach(tail => {
      tailOptions.push({
        optionKey: tail,
        optionValue: tail
      });
    });

    const setMapper = (updateNamesSetElement: string) => ({
      optionKey: updateNamesSetElement,
      optionValue: updateNamesSetElement
    });

    const nameOptions: FilterSelectorOption[] = Array.from(updateNamesSet).map(setMapper);
    const approverOptions: FilterSelectorOption[] = Array.from(approversSet).map(setMapper);

    setUpdateNameOptions(nameOptions);
    setApplicableTailOptions(tailOptions);
    setApproverOptions(approverOptions);
  }, [approvals]);

  return (
    <FilterSelector
      getFullElementId={(name: string, type: string) => `FilterSelector__${name}-${type}`}
      filters={filters}
      domainOptions={domainOptions}
      rangeOptions={rangeOptions}
      onFiltersChange={onFiltersChange}
      addFilterDisabled={filters.length >= 5}
      addFilterDisabledColor={Dispatcher.AccessibleGrayIconsAndBorders}
    />
  );
};
