/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: StatusIcon component
 */
import {colors} from '@vst/beam';
import {Dispatcher} from '../theme/Colors';
import {AddCircle, Block, CancelOutlined, CheckCircle, CircleOutlined} from '@mui/icons-material';
import PartiallyApprovedIcon from '../icons/PartiallyApproved';
import {PackageStatus} from '../api/queries/packages';
import useBearStore from '../utils/stores/useBearStore';

export const StatusIcon: React.FC<{status: PackageStatus; allowApproval: boolean}> = ({status, allowApproval}) => {
  const {
    init: {isAdmin}
  } = useBearStore(state => ({init: state.init}));

  const icons: Record<PackageStatus, React.ReactElement> = {
    available: (
      <CircleOutlined
        htmlColor={allowApproval ? colors.violet[500] : colors.green[500]}
        style={{height: 24, width: 24}}
      />
    ),
    partially_approved: (
      <PartiallyApprovedIcon color={allowApproval ? colors.violet[500] : colors.green[500]} width={24} height={24} />
    ),
    fully_approved: <CheckCircle htmlColor={colors.green[500]} style={{height: 24, width: 24}} />,
    rejected: <CancelOutlined htmlColor={colors.alert[300]} style={{height: 24, width: 24}} />,
    closed: <Block htmlColor={Dispatcher.AccessibleGrayIconsAndBorders} style={{height: 24, width: 24}} />,
    no_applicable_tails: (
      <AddCircle
        htmlColor={isAdmin ? colors.violet[500] : Dispatcher.AccessibleGrayIconsAndBorders}
        style={{height: 24, width: 24}}
      />
    )
  };

  return icons[status];
};
