/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ApprovalHistory component
 */

import styled from '@emotion/styled';
import {Table, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {spacing} from '@vst/beam';
import {Approval} from '../api/queries/approvals';
import {strings} from '../locale/strings';
import {Dispatcher} from '../theme/Colors';
import {formatDate} from '../utils/gridUtils';
import ApprovalProgress from './ApprovalProgress';
import {ApprovalTypeAndTime} from './ApprovalTypeAndTime';
import {HeaderCell, TableBody} from './Tables';
import TailListDropdown from './TailListDropdown';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[8]};
  padding: 0px ${spacing[32]} ${spacing[32]};
`;

const Header = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const ApprovalHistoryTableHead: React.FC = () => (
  <TableHead>
    <TableRow>
      <HeaderCell text="Approvals & Rejections" />
      <HeaderCell text="Approver" />
      <HeaderCell text="Start of Deployment" />
      <HeaderCell text={strings.SelectedTails} sx={{width: '99%'}} />
    </TableRow>
  </TableHead>
);

/**
 * Row representing an approval
 */
const ApprovalRow: React.FC<{approval: Approval}> = ({approval}) => (
  <TableRow key={approval.id}>
    <TableCell align="left" sx={{verticalAlign: 'top'}}>
      <ApprovalTypeAndTime approvalType={approval.approval_type} createdTimestamp={approval.created_timestamp} />
    </TableCell>
    <TableCell align="left" sx={{verticalAlign: 'top'}}>
      <Typography variant="body1" sx={{whiteSpace: 'nowrap'}}>
        {approval.creator_name}
      </Typography>
    </TableCell>
    <TableCell align="left" sx={{verticalAlign: 'top'}}>
      <Typography variant="body1">
        {approval.deployment_date != null ? formatDate(approval.deployment_date) : '–'}
      </Typography>
    </TableCell>
    <TableCell align="left" sx={{width: '99%', verticalAlign: 'top', paddingLeft: spacing[16], paddingRight: 0}}>
      <TailListDropdown tails={approval.approved_tails} displayDividerWhenClosed={false} tailListLinkPosition="top" />
    </TableCell>
  </TableRow>
);

/**
 * Approval History of an Update Package
 */
const ApprovalHistoryTable: React.FC<{
  approvals?: Approval[];
  isLoading: boolean;
  error: boolean;
  updateName: string;
  applicableTailsNum: number;
}> = ({approvals, isLoading, error, updateName, applicableTailsNum}) => {
  const approvedTailsNum = approvals?.reduce((acc, curr) => acc + curr.approved_tails.length, 0);
  const noApprovals = approvals?.length === 0;
  return (
    <Container>
      <Header>
        <div style={{flex: 1}}>
          <Typography variant="h3" color="primary">
            Approval History
          </Typography>

          <Typography color={Dispatcher.AccessibleGray}>
            {noApprovals
              ? `There are no approvals or rejections made for ${updateName}`
              : `Below are all approvals and rejections made for ${updateName}`}
          </Typography>
        </div>
        {approvedTailsNum != null ? (
          <ApprovalProgress numerator={approvedTailsNum} denominator={applicableTailsNum} />
        ) : null}
      </Header>
      {noApprovals ? null : (
        <TableContainer sx={{pr: 0, pl: 0}}>
          <Table aria-label="simple table">
            <ApprovalHistoryTableHead />
            <TableBody
              items={approvals}
              defaultSortFn={(a: Approval, b: Approval) => (a.created_timestamp < b.created_timestamp ? 1 : -1)}
              isLoading={isLoading}
              error={error}
              numCols={4}
              renderRow={approval => <ApprovalRow key={approval.id} approval={approval} />}
              renderEmpty={() => null}
            />
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

ApprovalHistoryTable.defaultProps = {};
export default ApprovalHistoryTable;
