/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Index
 */
import './utils/MuiClassNameSetup';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Global} from '@emotion/react';
import * as FullStory from '@fullstory/browser';
import './index.css';
import App from './App';
import AppProviders from './AppProviders';
import globalStyle from './theme/GlobalStyle';
import '@vst/beam/styles/css/min/beam.min.css';
import {DEV_ENVIRONMENT, environment, fullStoryOrgId} from './utils/config';

/**
 * Declaring <Global/> as static constant to prevent rerender as reccomended by link
 * @link https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
 */
const inputGlobalStyles = <Global styles={globalStyle} />;

const devMode = environment === DEV_ENVIRONMENT;
FullStory.init({orgId: fullStoryOrgId, recordCrossDomainIFrames: true, devMode});
console.info(`Environment: ${environment}, devMode: ${devMode}`);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AppProviders>
      {inputGlobalStyles}
      <App />
    </AppProviders>
  </React.StrictMode>
);
