/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ApproversTable component
 */

import {Delete} from '@mui/icons-material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import PersonIcon from '@mui/icons-material/Person';
import {
  IconButton,
  SxProps,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  Theme
} from '@mui/material';
import {tableCellClasses} from '@mui/material/TableCell';
import {tableRowClasses} from '@mui/material/TableRow';
import {spacing} from '@vst/beam';
import React from 'react';
import {Approver} from '../api/queries/useApprovers';
import {strings} from '../locale/strings';
import {Dispatcher} from '../theme/Colors';
import IconTextInABox from './IconTextInABox';
import {HeaderCell, IconTextCell, TableBody} from './Tables';

const ApproversTableHead: React.FC = () => (
  <TableHead>
    <MUITableRow>
      <HeaderCell text={strings.Approver} />
      <HeaderCell text={strings.Airline} sx={{width: '99%'}} />
      <TableCell />
    </MUITableRow>
  </TableHead>
);

const ApproversTableRow: React.FC<{
  approver: Approver;
  onClickDeleteRow?: (approver: Approver, i: number) => void;
  i: number;
}> = ({approver, onClickDeleteRow, i}) => (
  <MUITableRow>
    <IconTextCell Icon={PersonIcon} text={approver.email} />
    <IconTextCell Icon={AirplanemodeActiveIcon} text={approver.airline_name} />
    <TableCell align="left">
      {onClickDeleteRow && (
        <IconButton data-testid="btnDeleteUser" onClick={() => onClickDeleteRow(approver, i)}>
          <Delete htmlColor={Dispatcher.AccessibleGrayIconsAndBorders} />
        </IconButton>
      )}
    </TableCell>
  </MUITableRow>
);

const ApproversTableEmptyRow: React.FC<{numCols: number}> = ({numCols}) => (
  <MUITableRow>
    <TableCell colSpan={numCols + 1} style={{paddingTop: `${spacing[32]}`, paddingBottom: `${spacing[40]}`}}>
      <IconTextInABox text={strings.ApproversTable.Empty} />
    </TableCell>
  </MUITableRow>
);

// Sort ascending by email
const defaultSortFn = (a: Approver, b: Approver) => a.email.localeCompare(b.email);

/**
 * Table of Approvers
 */
const ApproversTable: React.FC<{
  approvers?: Approver[];
  isLoading: boolean;
  error: boolean;
  onClickDeleteRow?: (approver: Approver, i: number) => void;
  sx?: SxProps<Theme>;
}> = ({approvers, isLoading, error, onClickDeleteRow, sx}) => {
  const numCols = 3;
  return (
    <TableContainer sx={{flex: 1, padding: 0, ...sx}}>
      <Table data-testid="ApproversTable">
        <ApproversTableHead />
        <TableBody
          sx={{[`& .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]: {borderBottom: 'none'}}}
          items={approvers}
          isLoading={isLoading}
          error={error}
          numCols={numCols}
          renderRow={(approver, i) => (
            <ApproversTableRow key={approver.email} approver={approver} i={i} onClickDeleteRow={onClickDeleteRow} />
          )}
          renderEmpty={() => <ApproversTableEmptyRow numCols={numCols} />}
          defaultSortFn={defaultSortFn}
        />
      </Table>
    </TableContainer>
  );
};
export default ApproversTable;
