/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: useUpdatesData
 */

import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {PackageData} from './packages';

export const useUpdates = (params: {airlines: string[] | null}): UseQueryResult<PackageData[]> =>
  useQuery<PackageData[]>({
    queryKey: ['packages', params.airlines],
    queryFn: () => fetcher({route: 'packages', params}),
    select: (data): PackageData[] =>
      data.map(item => ({
        ...item,
        notes: item.notes || '',
        applicable_tails: item.applicable_tails || [],
        approved_tails: item.approved_tails || []
      }))
  });
