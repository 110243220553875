/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request Special Conditions Step
 */
import React, {useState} from 'react';
import {Controller, FormProvider, useForm, useFormContext} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Section} from '../../../components/Section';
import {CppButtonSection, CustomSectionText, RequestBox, SubSectionContainer} from './CppRequestShared';
import {FormInput} from '../../../components/FormInput';
import {CppRequestAdditionalFormSchema, CppRequestAdditionalFormType, CppStepProps} from '../types';
import {clearFormObject} from '../../../utils/utils';
import CustomDropzone from '../../../components/CustomDropzone';
import {Box, Checkbox, FormControl, FormControlLabel} from '@mui/material';
import {spacing} from '@vst/beam';
import {useCppRequestDraftStore} from '../../../utils/stores/cppRequestDraftStore';

type SpecialCheckBoxProps = {
  label: string;
  name: string;
  defaultValue?: boolean;
};

/**
 * A special checkbox component.
 *
 * @component
 * @param {SpecialCheckBoxProps} props - The props for the SpecialCheckBox component.
 * @param {string} props.label - The label for the checkbox.
 * @param {string} props.name - The name of the checkbox.
 * @returns {React.FC<SpecialCheckBoxProps>} The SpecialCheckBox component.
 */
const SpecialCheckBox: React.FC<SpecialCheckBoxProps> = ({label, name, defaultValue}: SpecialCheckBoxProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field}) => {
        return (
          <FormControlLabel label={label} control={<Checkbox {...field} checked={field.value} />}></FormControlLabel>
        );
      }}
    />
  );
};

const CppRequestAdditionalStep: React.FC<CppStepProps> = props => {
  const {additional} = props.inputs;
  const [otherFiles, setOtherFiles] = useState<File[]>(additional?.otherFiles || []);
  const {drafts} = useCppRequestDraftStore(state => ({
    drafts: state.drafts
  }));
  const draft = props.id ? drafts.get(props.id) : null;
  const formMethods = useForm<CppRequestAdditionalFormType>({
    defaultValues: additional ?? undefined,
    resolver: yupResolver(CppRequestAdditionalFormSchema)
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <RequestBox>
          <Section
            title="Additional Details & Attachments"
            bodyText="Provide any additional details that apply to the container image(s) that were not identified earlier in the process."
            layout="vertical"
          >
            <SubSectionContainer>
              <FormInput name="details" defaultValue={draft?.details} label="Additional Details" multiline />
              <CustomSectionText>
                Here you can attach any files or supporting documents related to your request.
              </CustomSectionText>
              <CustomDropzone
                disabled={false}
                files={otherFiles}
                setFiles={setOtherFiles}
                keyPfx="more-files-dropzone"
              />
              <FormControl sx={{m: `0 0 ${spacing[16]}`}} variant="standard">
                Check the box below if this update is only applicable for your lab and not intended to be deployed to
                production tails.
                <Box sx={{ml: `${spacing[32]}`}}>
                  <SpecialCheckBox defaultValue={draft?.labOnly || false} label="Lab Only" name="labOnly" />
                </Box>
              </FormControl>
            </SubSectionContainer>
          </Section>
        </RequestBox>
      </FormProvider>
      <CppButtonSection
        {...props}
        verify={callback =>
          formMethods.handleSubmit(data => {
            callback({...data, otherFiles});
          })
        }
        clear={() => {
          setOtherFiles([]);
          formMethods.reset(clearFormObject(additional));
        }}
        valueKey="additional"
        id={props.id as string}
      />
    </>
  );
};

export default CppRequestAdditionalStep;
