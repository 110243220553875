/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: SectionLabel component
 */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import {colors} from '@vst/beam';

export interface SectionLabelProps {
  title: string;
  subtitle?: string;
}

const SectionLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const HorizontalRule = styled.hr`
  height: 1px;
  border: 1px ${colors.gray[200]};
  background: ${colors.gray[200]};
  flex: none;
  order: 1;
  flex-grow: 1;
`;

export const SectionLabel: React.FC<SectionLabelProps> = ({title, subtitle}: SectionLabelProps) => {
  return (
    <SectionLabelContainer>
      <LabelsContainer>
        <Typography data-testid="Title" variant="h3" color={colors.gray[600]} fontStyle="normal">
          {title}
        </Typography>
        {subtitle ? (
          <Typography data-testid="Subtitle" variant="body1" color={colors.gray[600]} fontStyle="normal">
            {subtitle}
          </Typography>
        ) : null}
      </LabelsContainer>
      <HorizontalRule />
    </SectionLabelContainer>
  );
};
