/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mutation query to create an approval/rejection.
 */

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import useBearStore from '../../utils/stores/useBearStore';

export interface CreateApprovalVars {
  updateId: string;
  params:
    | {
        approval_type: 'approval';
        approved_tails: string[];
        deployment_date: string;
      }
    | {
        approval_type: 'rejection';
        reason: string;
      }
    | {
        approval_type: 'closure';
        reason: string;
      };
}

export const useCreateApproval = ({onMutate}: {onMutate: () => void}) => {
  const queryClient = useQueryClient();
  const airlines = useBearStore(state => state.airlines);
  return useMutation({
    mutationFn: ({updateId, params}: CreateApprovalVars) =>
      fetcher({
        route: `packages/${updateId}/approvals/create`,
        params
      }),
    onMutate: async () => {
      onMutate();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['packages', airlines]);
      await queryClient.invalidateQueries(['approvals']);
    }
  });
};
