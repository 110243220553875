/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Error Component
 */

import React, {ReactNode} from 'react';
import {Typography} from '@mui/material';
import styled from '@emotion/styled';
import {spacing} from '@vst/beam';
import DeskDesktop from '../icons/DeskDesktop';

/**
 * Props for the ErrorComponent.
 *
 * @param {string} headerError - The header error message to display.
 * @param {string} bodyErrorMessage - The body error message to display.
 * @param {string} dataTestId - The test ID for the component.
 * @param {ReactNode} children - The children components to render.
 */
interface ErrorComponentProps {
  headerError: string;
  bodyErrorMessage: string;
  dataTestId: string;
  children?: ReactNode;
}

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const MessageFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 ${spacing[32]};
  gap: ${spacing[16]};
`;

/**
 * A component that displays an error message and wraps children components.
 *
 * @param {ErrorComponentProps} props - The props for the component.
 * @returns {JSX.Element} A container with a header, body error message, and children components.
 */
const ErrorComponent: React.FC<ErrorComponentProps> = ({headerError, bodyErrorMessage, dataTestId, children}) => {
  return (
    <NoDataContainer data-testid={dataTestId}>
      <MessageFrame>
        <DeskDesktop />
        <Typography align="center" variant="h1">
          {headerError}
        </Typography>
        <Typography align="center" variant="body1">
          {bodyErrorMessage}
        </Typography>
        {children}
      </MessageFrame>
    </NoDataContainer>
  );
};

export default ErrorComponent;
