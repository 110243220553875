/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description Relevant links component
 */

import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import {colors, spacing} from '@vst/beam';
import {strings} from '../locale/strings';
import Stack from '@mui/material/Stack';
import React, {useState} from 'react';
import {Controller, FieldErrors, useFieldArray, useFormContext} from 'react-hook-form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {DISPATCHER_PRIMARY_PURPLE} from '../theme/Colors';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[24]};
  align-items: baseline;
`;

const StyledAddIcon = styled(AddIcon)`
  width: ${spacing[24]};
  height: ${spacing[24]};
`;

interface Link {
  title: string;
  url: string;
}

interface FormData {
  links: Link[];
}

/**
 * Gets the error message for a specific field in a FormData object.
 *
 * @param errors An object containing the validation errors for a FormData object.
 * @param fieldName The name of the field to get the error message for.
 * @param index The index of the error message to get. If the field has multiple errors,
 * @returns A string containing the error message for the specified field, or `undefined` if
 * there is no error message for that field.
 */
const getErrorMessage = (
  errors: FieldErrors<FormData>,
  fieldName: 'title' | 'url',
  index: number
): string | undefined | null => {
  const errorField = errors.links?.[index] as null | Record<string, {message?: string}>;
  return errorField?.[fieldName]?.message;
};

const AddLink: React.FC = () => {
  const [linkCounter, setLinkCounter] = useState(1);
  const incrementCounter = () => setLinkCounter(linkCounter + 1);
  const decrementCounter = () => setLinkCounter(linkCounter - 1);

  const {
    control,
    formState: {errors}
  } = useFormContext();

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'links'
  });

  return (
    <Box>
      <Stack sx={{gap: spacing[24], marginBottom: fields.length ? spacing[24] : '0px'}}>
        {fields.map((field, index) => {
          return (
            <section key={field.id}>
              <FormRow>
                <Controller
                  name={`links.${index}.title`}
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Link Title"
                      InputLabelProps={{
                        shrink: true
                      }}
                      sx={{flex: '1'}}
                      error={!!getErrorMessage(errors, 'title', index)}
                      helperText={getErrorMessage(errors, 'title', index)}
                    />
                  )}
                />
                <Controller
                  name={`links.${index}.url`}
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Link URL"
                      InputLabelProps={{
                        shrink: true
                      }}
                      sx={{flex: '1'}}
                      error={!!getErrorMessage(errors, 'url', index)}
                      helperText={getErrorMessage(errors, 'url', index)}
                    />
                  )}
                />
                <span>
                  <IconButton
                    onClick={() => {
                      remove(index);
                      decrementCounter();
                    }}
                    sx={{
                      color: DISPATCHER_PRIMARY_PURPLE,
                      '&:hover': {
                        backgroundColor: DISPATCHER_PRIMARY_PURPLE,
                        color: colors.white
                      },
                      '&.Mui-focusVisible': {
                        outline: `2px solid ${colors.violet[500]}`
                      }
                    }}
                  >
                    <DeleteOutlineIcon sx={{width: spacing[24], height: spacing[24], color: 'inherit'}} />
                  </IconButton>
                </span>
              </FormRow>
            </section>
          );
        })}
      </Stack>
      {linkCounter <= 5 && (
        <Button
          variant="text"
          onClick={() => {
            append({
              title: '',
              url: ''
            });
            incrementCounter();
          }}
          sx={{
            gap: '8px',
            color: 'DISPATCHER_PRIMARY_PURPLE',
            border: 'unset',
            textTransform: 'none',
            borderRadius: 100,
            '&:hover': {
              backgroundColor: DISPATCHER_PRIMARY_PURPLE,
              color: 'white',
              border: 'unset'
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${colors.violet[500]}`
            }
          }}
        >
          <StyledAddIcon />
          <Typography variant="button" sx={{color: 'inherit'}}>
            {fields.length ? strings.CreateUpdatePage.AnotherLink : strings.CreateUpdatePage.AddLink}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default AddLink;
