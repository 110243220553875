/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: App Component
 */
import {ThemeProvider} from '@mui/material';
import {SliderMenu} from '@viasat/insights-components';
import {spaComs} from '@viasat/insights-spa-package';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import {Page404} from './pages/Page404';
import {useInitData} from './api/queries/init';
import {DISPATCHER_PRIMARY_PURPLE} from './theme/Colors';
import {muiTheme} from './theme/muiTheme';
import {localLogout} from './utils/auth';
import {getCurrentUrlBase} from './utils/linkUtils';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import useBearStore from './utils/stores/useBearStore';
import {getNonTabPages, getTabPages} from './Pages';

const queryClient = new QueryClient();

const OuterWrapper: React.FC<React.PropsWithChildren<{hotelComsCompleteOverride?: boolean}>> = ({
  children,
  hotelComsCompleteOverride = false
}) => {
  const {setAirlines, setInit, urlBase, airlines} = useBearStore(state => ({
    setAirlines: state.setAirlines,
    setInit: state.setInit,
    urlBase: state.urlBase,
    airlines: state.airlines
  }));

  const [hotelComsComplete, setHotelComsComplete] = useState<boolean>(hotelComsCompleteOverride);
  const spaInitCallback = useCallback(
    (token: string, groupCodes: string[], location: string) => {
      sessionStorage.iframeLocation = location; // This location should be used for links, including share links and opening a new tab/window.

      if (localStorage.token && localStorage.token !== token) {
        localLogout(); // This happens at a new login. This should clear the application session and local storage as necessary.
      }

      localStorage.token = token;
      setAirlines(groupCodes);
      setHotelComsComplete(true);
    },
    [setAirlines]
  );

  const spaUpdateCallback = useCallback(
    (groupCodes: string[]) => {
      setAirlines(groupCodes);
    },
    [setAirlines]
  );

  useEffect(() => {
    spaComs.initWithMulti(spaInitCallback, spaUpdateCallback);
  }, [spaInitCallback, spaUpdateCallback]);

  const fetchParams = useMemo(
    () => (hotelComsComplete && airlines && airlines.length > 0 ? {airlines} : undefined),
    [hotelComsComplete, airlines]
  );
  const {data} = useInitData(fetchParams);

  useEffect(() => {
    if (!data) return;
    FullStory.identify(data.uid, {email: data.email, displayName: data.name});
    setInit(getCurrentUrlBase(), data);
  }, [data, setInit]);

  return hotelComsComplete && urlBase ? <>{children}</> : <></>;
};

const App: React.FC<{hotelComsCompleteOverride?: boolean}> = ({hotelComsCompleteOverride = false}) => {
  const {appConfigs} = useBearStore(state => ({appConfigs: state.init.appConfigs}));

  const tabPages = getTabPages(appConfigs);
  const nonTabPages = getNonTabPages(appConfigs);

  return (
    <QueryClientProvider client={queryClient}>
      <OuterWrapper hotelComsCompleteOverride={hotelComsCompleteOverride}>
        <ThemeProvider theme={muiTheme}>
          <BrowserRouter>
            <SliderMenu
              getFullElementId={(name, type) => `sliderMenu__${name}-${type}`}
              items={tabPages}
              activeColor={DISPATCHER_PRIMARY_PURPLE}
            >
              <Routes>
                {tabPages.map(page => (
                  <Route key={`${page.key}-route`} path={page.route} element={<page.component {...page.props} />} />
                ))}
                {nonTabPages.map(page => (
                  <Route key={`${page.key}-route`} path={page.route} element={<page.component {...page.props} />} />
                ))}
                <Route
                  path="*"
                  element={tabPages.length > 0 ? <Navigate replace to={tabPages[0].route} /> : <Page404 />}
                />
              </Routes>
            </SliderMenu>
          </BrowserRouter>
        </ThemeProvider>
      </OuterWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
