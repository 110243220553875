/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: AddApproverDialog component
 */
import {Box} from '@mui/material';
import * as React from 'react';
import * as yup from 'yup';
import {strings} from '../locale/strings';
import {Airline, useAirlines} from '../api/queries/useAirlines';
import {AirlineFormSelect} from './AirlineFormSelect';
import {FormDialog} from './FormDialog';
import {FormInput} from './FormInput';
import {spacing} from '@vst/beam';
import {useAddApprover} from '../api/mutations/useAddApprover';
import {spaComs} from '@viasat/insights-spa-package';
import {useEffect} from 'react';

const AddApproverForm: React.FC<{airlines: Airline[]}> = ({airlines}) => {
  return (
    <Box display="flex" flexDirection="column" gap={spacing[16]}>
      <FormInput name="email" label={strings.Email} required type="email" triggerValidation />
      <AirlineFormSelect airlines={airlines} />
    </Box>
  );
};

interface AddApproverFormFields {
  airline: string;
  email: string;
}

type AddApproverFormField = keyof AddApproverFormFields;

type FormFieldError<T> = {field: T; message: string};

const serverErrors: Record<string, FormFieldError<AddApproverFormField>> = {
  approver_email_exists: {field: 'email', message: strings.AddApprover.ErrorMessages.approver_email_exists}
};

export const AddApproverDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}> = ({open, onClose, onSuccess, onError}) => {
  const formSchema = yup.object().shape({
    airline: yup.string().required(strings.FormValidation.PleaseCompleteThisRequiredField),
    email: yup.string().email().required(strings.FormValidation.PleaseCompleteThisRequiredField)
  });

  const {data: airlines} = useAirlines();

  const [errors, setErrors] = React.useState<FormFieldError<AddApproverFormField>[]>([]);
  const addApproverResult = useAddApprover({
    onSuccess: () => {
      onClose();
      onSuccess();
      addApproverResult.reset();
    },
    onError: error => {
      try {
        const errorObj = JSON.parse((error as Error).message) as {
          response: {errors: {code: string; message: string}[]};
        };
        if (errorObj.response) {
          const errors = errorObj.response.errors.map(({code, message}) => serverErrors[code] ?? message);
          setErrors(errors);
        }
      } catch (e) {
        onError();
        onClose();
      }
    }
  });

  useEffect(() => {
    spaComs.sendGroupDisable(open);
  }, [open]);

  return (
    <FormDialog
      title={strings.AddApproverDialog.Title}
      open={open}
      onSubmit={({airline, email}) => {
        addApproverResult.mutate({params: {airline_code: airline, email}});
      }}
      onClose={onClose}
      formSchema={formSchema}
      defaultValues={airlines => ({
        airline: airlines[0]?.code,
        email: ''
      })}
      okText={strings.Add}
      isLoading={addApproverResult.isLoading || addApproverResult.isSuccess}
      dependencies={airlines}
      renderForm={airlines => <AddApproverForm airlines={airlines} />}
      errors={errors}
    />
  );
};
