/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: VersionInformation component
 */
import * as React from 'react';
import styled from '@emotion/styled';
import {Typography} from '@mui/material';
import {colors} from '@vst/beam';
import {EllipsizedDiv} from '../components/EllipsizedDiv';
import {spacing} from '@vst/beam';
import {versionLabelRecord, VersionType} from '../versions';

const VersionInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  width: 100%;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[4]};
  width: 100%;
`;

const VersionContent = styled.div`
  width: 100%;
  flex-grow: 1;
`;

export interface VersionInformationProps {
  versionType: VersionType;
  version: string;
}

export const VersionInformation: React.FC<{versionType: VersionType; version: string}> = ({versionType, version}) => {
  return (
    <VersionInformationContainer data-testid="VersionInfoContainer">
      <Typography
        data-testid="VersionType"
        variant="body2"
        color={colors.gray[600]}
        fontStyle="normal"
        sx={{whiteSpace: 'nowrap'}}
      >
        {versionLabelRecord[versionType]}
      </Typography>
      <CardContent data-testid="VersionNumber">
        <Typography width="100%" variant="body1" color={colors.gray[800]} fontStyle="normal">
          <VersionContent>
            <EllipsizedDiv>{version}</EllipsizedDiv>
          </VersionContent>
        </Typography>
      </CardContent>
    </VersionInformationContainer>
  );
};
