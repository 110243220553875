/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: CPP utils.
 */
import {CppRequestHistory} from '../api/queries/useCppRequestHistory';
import {CppRequest, CppRequestDraft} from '../pages/Cpp/types';

/**
 * Maps an array of CPP drafts in local storage of type CppRequestDraft to any array of CPP requests
 * that matches the data schema returned from the CPP request history API.
 * @param drafts The local storage drafts.
 * @returns An array of CPP requests.
 */
export const mapDraftDataToHistory = (drafts: Map<string, CppRequestDraft>): CppRequestHistory[] =>
  Array.from(drafts).map(
    ([key, draft]): CppRequestHistory => ({
      id: key,
      status: 'draft',
      creatorName: draft.creatorName,
      createdTimestamp: draft.createdTimestamp,
      updatedTimestamp: draft.updatedTimestamp,
      application: draft.application,
      airlineName: draft.airlineName
    })
  );

/**
 * Maps a CPP draft in local storage of type CppRequestDraft to the CPP request schema
 * that matches the data returned from the CPP request history API.
 * @param key The draft ID.
 * @param value The draft
 * @returns A CPP Request.
 */
export const mapDraftToFetchedFormat = (key: string, value: CppRequestDraft): CppRequest => {
  const {airline, secrets, details, endPoints, lastActiveStep, ...other} = value;
  return {
    id: key,
    airlineCode: airline,
    containerImages: [],
    secretsAndCertificates: secrets,
    additionalDetails: details,
    status: 'draft',
    endpoints: endPoints,
    s3Location: null,
    ...other
  } as unknown as CppRequest;
};

/**
 * Maps a CPP request of type CppRequest to the CPP draft schema
 * that matches the data schema used in local storage.
 * @param value The request
 * @returns A CPP Draft.
 */
export const mapFetchedToDraftFormat = (value: CppRequest): CppRequestDraft => {
  const {
    application,
    airlineCode,
    airlineName,
    endpoints,
    containerChanges,
    containerInterfaces,
    additionalDetails,
    environmentVariables,
    functionalChanges,
    labOnly,
    ports,
    resourceChanges,
    secretsAndCertificates,
    trafficPolicies,
    volumeMounts
  } = value;
  return {
    airline: airlineCode,
    airlineName: airlineName,
    application: application,
    containerChanges: containerChanges,
    containerInterfaces: containerInterfaces,
    createdTimestamp: '',
    creatorEmail: '',
    creatorName: '',
    details: additionalDetails,
    endPoints: endpoints,
    environmentVariables: environmentVariables,
    functionalChanges: functionalChanges,
    imageFiles: [],
    labOnly: labOnly,
    otherFiles: [],
    ports: ports,
    resourceChanges: resourceChanges,
    secrets: secretsAndCertificates,
    trafficPolicies: trafficPolicies,
    updatedTimestamp: '',
    volumeMounts: volumeMounts,
    lastActiveStep: 0
  };
};
