/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Cpp Request History Page
 */
import styled from '@emotion/styled';
import qs from 'qs';
import {Button, CircularProgress} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import React, {useState} from 'react';
import {ShareLink} from '@viasat/insights-components';
import {PageProps} from '../../types';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import {Alert} from '../../components/Alert';
import CppRequestHistoryTable from '../../components/CppRequestHistoryTable';
import {useCppRequestHistory} from '../../api/queries/useCppRequestHistory';
import useBearStore from '../../utils/stores/useBearStore';
import {useCppRequestDraftStore} from '../../utils/stores/cppRequestDraftStore';
import {PATHS} from '../../paths';
import {strings} from '../../locale/strings';
import ErrorComponent from '../../components/ErrorComponent';
import {isMultiSelectFunc} from '../../utils/gridUtils';
import {mapDraftDataToHistory} from '../../utils/cppUtils';
import {getCurrentUrlPath} from '../../utils/linkUtils';

const Main = styled.main`
  height: 100%;
  display: flex;
`;

const AddCppRequestSection = styled.div`
  margin: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
`;

const Banner: React.FC<{
  isLoading: boolean;
  isCppRequestSuccess: boolean;
  onClickClose: () => void;
}> = ({isLoading, isCppRequestSuccess, onClickClose}) => {
  // Hide banner while action is being performed
  if (isLoading) {
    return null;
  } else if (isCppRequestSuccess) {
    return (
      <Alert
        data-testid="cpp-submitted-success-banner"
        bodyText={strings.CppRequestHistory.Banner['success']}
        status="success"
        closing={true}
        onClickClose={onClickClose}
      />
    );
  }
  // Default: No banner display
  else {
    return null;
  }
};

/**
 * CPP Request History Page
 */
const CppRequestHistory: React.FC<PageProps> = ({title}) => {
  const {airlines} = useBearStore(state => ({
    airlines: state.airlines
  }));

  const {drafts} = useCppRequestDraftStore(state => ({
    drafts: new Map(Array.from(state.drafts).filter(([_, value]) => airlines.includes(value.airline)))
  }));

  const location = useLocation();
  const [bannerIsActive, setBannerIsActive] = useState<boolean>(true);
  const {data, isLoading, isError} = useCppRequestHistory({airlines});
  const [selectedCppRequestId] = useState<string | null>(null);
  const navigate = useNavigate();

  const urlPath = getCurrentUrlPath();
  const urlQuery = qs.stringify({airlineCode: airlines}, {arrayFormat: 'comma'});

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
    </div>
  ) : data && (data.length > 0 || drafts.size > 0) ? (
    <PageContainerTemplate
      title={title}
      isSubtitleLoading={false}
      subtitle={''}
      getFullElementId={(name: string, type: string) => `${name}-${type}`}
      leftStack={[]}
      rightStack={[
        <ShareLink
          key="shareLink"
          getFullElementId={(name: string, type?: string) => `cppHistory__${name}-${type}`}
          disabled={false}
          urlPath={urlPath}
          urlQuery={urlQuery}
        />
      ]}
    >
      {bannerIsActive ? (
        <Banner
          isLoading={isLoading}
          isCppRequestSuccess={location.state?.requestSuccess}
          onClickClose={() => {
            setBannerIsActive(false);
          }}
        />
      ) : null}
      <Main>
        <CppRequestHistoryTable
          isLoading={isLoading}
          error={isError}
          cppRequests={[...mapDraftDataToHistory(drafts), ...data]}
          selectedId={selectedCppRequestId ?? undefined}
          onClickRow={({id}) => navigate(`${PATHS.cppDetails}/${id}`)}
          displayAirlineColumn={isMultiSelectFunc(airlines ?? [])}
        />
      </Main>

      <AddCppRequestSection>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            navigate(PATHS.cppRequestBase);
          }}
        >
          Create New Request
        </Button>
      </AddCppRequestSection>
    </PageContainerTemplate>
  ) : (
    <ErrorComponent
      headerError={strings.CppRequestHistory.ThirdPartyApplicationUpdates}
      bodyErrorMessage={strings.CppRequestHistory.NoSubmissions}
      dataTestId="no-cpp-history-container"
    >
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          navigate(PATHS.cppRequestBase);
        }}
      >
        Create New Request
      </Button>
    </ErrorComponent>
  );
};

export default CppRequestHistory;
