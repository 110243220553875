/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Loading Indicator component
 */

import styled from '@emotion/styled';
import {CircularProgress} from '@mui/material';
import React from 'react';
import Indicator from './Indicator';
import {spacing} from '@vst/beam';

const LoadingContainer = styled.div`
  padding: ${spacing[80]} 0 ${spacing[112]} 0;
`;

const Loading: React.FC<{text?: string}> = ({text}) => (
  <LoadingContainer>
    <Indicator icon={<CircularProgress color="primary" size={32} thickness={4} />} text={text || 'Loading'} />
  </LoadingContainer>
);

export default Loading;
