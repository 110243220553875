/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mutation query to get CPP intake links
 */

import {useMutation} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';
import {Airline} from '../queries/useAirlines';

export type CppSubmitRequestResp = {
  id: string;
  bucketPath: string;
  imageFiles: Record<string, string>;
  otherFiles: Record<string, string>;
  timestamp: number;
};

export const useCppSubmitRequest = (handleSuccess: (data: CppSubmitRequestResp) => void, handleError: () => void) =>
  useMutation({
    mutationFn: ({imageFiles, otherFiles, airline}: {imageFiles: File[]; otherFiles: File[]; airline: Airline}) =>
      fetcher({
        route: `cpp/request`,
        params: {
          airline,
          imageFileNames: imageFiles.map(file => file.name),
          otherFileNames: otherFiles.map(file => file.name)
        }
      }),
    onSuccess: (data: CppSubmitRequestResp) => {
      handleSuccess(data);
    },
    onError: async () => {
      handleError();
    }
  });
