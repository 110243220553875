/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: MultiSelectorDropdown component
 */

import styled from '@emotion/styled';
import {Search} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import _ from 'lodash';
import React, {memo, ReactNode, useCallback, useMemo, useState} from 'react';
import {Dispatcher} from '../theme/Colors';
import {spacing} from '@vst/beam';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SelectorFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterContents = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing[16]} 0;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

const EmptyContainer = styled.div`
  height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Empty: React.FC<{}> = () => (
  <EmptyContainer>
    <Search color="primary" fontSize="large" />
    <Typography style={{textAlign: 'center'}}>
      Try searching for something else.
      <br />
      Only tails applicable for the update can be searched.
    </Typography>
  </EmptyContainer>
);

const MultiSelectorDropdownCheckBox: React.FC<{
  item: string;
  checked: boolean;
  onChange: (checked: boolean, id: string) => void;
}> = memo(({item, checked, onChange}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={checked}
        id={item}
        inputProps={{'aria-label': 'controlled'}}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked, event.target.id)}
      />
    }
    label={item}
    sx={{width: '100px'}}
  />
));

/**
 * Select from multiple items
 */
const MultiSelectorDropdown: React.FC<{
  items: Set<string>;
  selectedItems: Set<string>;
  setSelectedItems: (selectedItems: Set<string>) => void;
  prompt: string;
  label?: string;
  helperText?: string | ReactNode;
  selectAllLabel?: string;
  saveSelectionLabel?: string;
  renderFooter?: (selectedItems: Set<string>) => ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  helperTextSx?: SxProps<Theme>;
  selectAllByDefault?: boolean;
}> = ({
  items,
  selectedItems,
  setSelectedItems,
  prompt,
  label,
  helperText,
  selectAllLabel,
  saveSelectionLabel,
  renderFooter,
  error,
  fullWidth = true,
  helperTextSx,
  selectAllByDefault = true
}) => {
  const [open, setOpen] = useState(false);
  const [selectedItemsInternal, setSelectedItemsInternal] = useState(
    selectAllByDefault ? new Set<string>(items) : new Set<string>()
  );
  const [searchString, setSearchString] = useState('');
  const allTailsSelected = useMemo(
    () => _.isEqual(selectedItemsInternal, items) && items.size > 0,
    [selectedItemsInternal, items]
  );

  /**
   * Create new set with added item
   * @param s set
   * @param item item to include in new set
   * @returns new set
   */
  const setWith = <T,>(s: Set<T>, item: T) => new Set(s).add(item);

  /**
   * Create new set without item
   * @param s set
   * @param item item to leave out of new set
   * @returns new set
   */
  const setWithout = <T,>(s: Set<T>, item: T) => {
    const newSet = new Set(s);
    newSet.delete(item);
    return newSet;
  };

  /**
   * Handle changes to search string
   */
  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  }, []);

  /**
   * Handle changes to individual checkboxes
   */
  const onCheckboxChange = useCallback((checked: boolean, id: string) => {
    setSelectedItemsInternal(prev => (checked ? setWith : setWithout)(prev, id));
  }, []);

  /**
   * Handle changes to the "select all" checkbox
   */
  const onSelectAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSelectedItemsInternal(new Set(checked ? items : []));
    },
    [items]
  );

  const filteredItems = useMemo(
    () =>
      Array.from(items).filter(item =>
        item.trim().toLocaleLowerCase().includes(searchString.trim().toLocaleLowerCase())
      ),
    [items, searchString]
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      aria-describedby="component-helper-text"
      required
      sx={{
        [`& .${outlinedInputClasses.input}`]: {
          padding: `${spacing[16]} ${spacing[12]} ${spacing[16]} ${spacing[12]}`
        }
      }}
    >
      {label && (
        <InputLabel id="input-label" error={error} shrink={true}>
          {label}
        </InputLabel>
      )}
      <Select
        label={label || ''}
        labelId="input-label"
        notched={Boolean(label)}
        error={error}
        displayEmpty
        native={false}
        renderValue={() => <Typography color={Dispatcher.AccessibleGray}>{prompt}</Typography>}
        onOpen={x => {
          setOpen(true);
          setSelectedItemsInternal(selectedItems);
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Container>
          <Header>
            <FormControl variant="outlined">
              <OutlinedInput
                type={'text'}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="start">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
                size="small"
                placeholder="Search"
                sx={{
                  borderRadius: spacing[28],
                  input: {
                    '&::placeholder': {
                      color: Dispatcher.AccessibleGray,
                      opacity: '100%'
                    }
                  }
                }}
                value={searchString}
                onChange={onSearchChange}
              />
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={onSelectAllChange} />}
                label={selectAllLabel}
                checked={allTailsSelected}
              />
            </FormGroup>
          </Header>
          <Divider />

          {filteredItems.length > 0 ? (
            <FormGroup row sx={{maxHeight: '300px', overflow: 'auto'}}>
              {filteredItems.map(item => (
                <MultiSelectorDropdownCheckBox
                  key={item}
                  item={item}
                  checked={selectedItemsInternal.has(item)}
                  onChange={onCheckboxChange}
                />
              ))}
            </FormGroup>
          ) : (
            <Empty />
          )}

          <Divider />
          <SelectorFooter>
            {renderFooter && selectedItemsInternal.size > 0 && (
              <FooterContents>{renderFooter(selectedItemsInternal)}</FooterContents>
            )}
            <SaveButtonContainer>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  setSelectedItems(selectedItemsInternal);
                  setOpen(false);
                }}
              >
                {saveSelectionLabel}
              </Button>
            </SaveButtonContainer>
          </SelectorFooter>
        </Container>
      </Select>
      {helperText && (
        <FormHelperText component="div" sx={{...helperTextSx}} error={error} id="component-helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

MultiSelectorDropdown.defaultProps = {
  selectAllLabel: 'Select all',
  saveSelectionLabel: 'Save selection'
};
export default MultiSelectorDropdown;
