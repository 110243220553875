/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common configuration
 */

declare let externalConfig: any;
// Allow for default behavior via not defining externalConfig
let internalConfig: any = {};
try {
  internalConfig = externalConfig;
} catch (e) {}

export const DEV_ENVIRONMENT = 'dev';

const environment = internalConfig.ENVIRONMENT_NAME || DEV_ENVIRONMENT;
const apiBaseUrl = internalConfig.API_BASE || 'http://localhost:3001/api';
const fullStoryOrgId = internalConfig.FULL_STORY_ORG_ID || 'o-1KAGQA-na1';

export {environment, apiBaseUrl, fullStoryOrgId};
