/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Indicator component
 */

import styled from '@emotion/styled';
import {Divider, Typography} from '@mui/material';
import {spacing} from '@vst/beam';
import React from 'react';
import {Dispatcher} from '../theme/Colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${spacing[16]};
`;

const ProgressAndText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${spacing[16]};
`;

/**
 * Indicator with icon and text
 */
const Indicator: React.FC<{icon: React.ReactNode; text: string}> = ({icon, text}) => {
  return (
    <Container>
      <Divider style={{flex: 1}} />
      {icon}
      <ProgressAndText>
        <Typography variant="subtitle1" color={Dispatcher.AccessibleGray}>
          {text}
        </Typography>
      </ProgressAndText>
      <Divider style={{flex: 1}} />
    </Container>
  );
};

export default Indicator;
