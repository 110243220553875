/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approval History Page
 */
import styled from '@emotion/styled';
import {ShareLink} from '@viasat/insights-components';
import React, {useState} from 'react';
import {generatePath, useParams} from 'react-router-dom';
import {ApprovalDetails} from '../../components/ApprovalDetails';
import ApprovalsTable from '../../components/ApprovalsTable';
import {useApprovals} from '../../api/queries/useApprovals';
import {useUpdates} from '../../api/queries/useUpdates';
import {PackageData} from '../../api/queries/packages';
import {isMultiSelectFunc} from '../../utils/gridUtils';
import {getCurrentUrlBase, getFiltersToUrl} from '../../utils/linkUtils';
import PageContainerTemplate from '../../theme/PageContainerTemplate';
import {PATHS} from '../../paths';
import {PageProps, UpdateApproval} from '../../types';
import useBearStore from '../../utils/stores/useBearStore';
import {ApprovalsFilter} from '../../components/ApprovalsFilter';
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';
import approvalHistoryStore from './approvalHistoryStore';
import useUrlParams from '../../utils/useUrlParams';

const Main = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
`;

/**
 * Approval History Page
 */
const ApprovalHistory: React.FC<PageProps> = ({title}) => {
  const {airlines} = useBearStore(state => ({
    airlines: state.airlines
  }));
  const {approvalsFilters, setApprovalsFilters, loadUrlParams} = approvalHistoryStore(state => ({
    loadUrlParams: state.loadUrlParams,
    approvalsFilters: state.filters,
    setApprovalsFilters: state.setFilters
  }));
  let pageParams = useParams<{approvalId: string}>();
  const [selectedApprovalId, setSelectedApprovalId] = useState<string | null>(pageParams.approvalId ?? null);

  const urlPath =
    selectedApprovalId === null
      ? `${getCurrentUrlBase()}${PATHS.approvals}`
      : `${getCurrentUrlBase()}${generatePath(PATHS.approval, {approvalId: selectedApprovalId})}`;
  const urlQuery = getFiltersToUrl(approvalsFilters, airlines);

  useUrlParams(loadUrlParams);

  const {data: approvalsData, isLoading: isApprovalsLoading, isError: isApprovalsError} = useApprovals({airlines});
  const {data: updatesData, isLoading: isUpdatesLoading, isError: isUpdatesError} = useUpdates({airlines});

  const isLoading = isApprovalsLoading || isUpdatesLoading;
  const isError = isApprovalsError || isUpdatesError;

  const approvals: UpdateApproval[] =
    approvalsData?.approvals.flatMap(approval => {
      const update: PackageData | null = updatesData?.find(({id}) => approval.package_id === id) ?? null;
      if (update == null) {
        console.warn(`can't find package ${approval.package_id}`);
        return [];
      }
      return {
        ...approval,
        update
      };
    }) ?? [];

  const selectedApproval = approvals.find(approval => approval.id === selectedApprovalId);

  return (
    <PageContainerTemplate
      title={title}
      isSubtitleLoading={false}
      subtitle={''}
      getFullElementId={(name: string, type: string) => `${name}-${type}`}
      leftStack={[
        <ApprovalsFilter
          approvals={approvals}
          filters={approvalsFilters}
          onFiltersChange={(filters: FilterRow[]) => {
            setApprovalsFilters(filters);
          }}
        />
      ]}
      rightStack={[
        <ShareLink
          key="shareLink"
          getFullElementId={(name: string, type?: string) => `${title}__${name}-${type}`}
          disabled={false}
          urlPath={urlPath}
          urlQuery={urlQuery}
        />
      ]}
    >
      <Main>
        <ApprovalsTable
          filters={approvalsFilters}
          approvals={approvals}
          isLoading={isLoading}
          error={isError}
          onClickApprovalRow={approval => {
            setSelectedApprovalId(approval.id);
          }}
          displayAirlineColumn={isMultiSelectFunc(airlines ?? [])}
          selectedApprovalId={selectedApprovalId ?? undefined}
          sx={{flex: 2}}
        />
        {selectedApproval && (
          <ApprovalDetails approval={selectedApproval} onClickClose={() => setSelectedApprovalId(null)} flex={1} />
        )}
      </Main>
    </PageContainerTemplate>
  );
};

export default ApprovalHistory;
