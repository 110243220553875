/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: IconTextInABox component
 */

import styled from '@emotion/styled';
import {Divider, Typography} from '@mui/material';
import {spacing} from '@vst/beam';
import React from 'react';
import {Dispatcher} from '../theme/Colors';
import Icon from '@mui/material/Icon';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing[8]};
  text-align: center;
`;

/**
 * IconTextInABox with icon and text
 */
const IconTextInABox: React.FC<{icon?: string; text: string}> = ({icon, text}) => {
  return (
    <Container>
      <Divider style={{flex: 1}} />
      {icon && (
        <Icon color="primary" sx={{fontSize: 40}}>
          {icon}
        </Icon>
      )}
      <Typography variant="subtitle1" color={Dispatcher.AccessibleGray}>
        {text}
      </Typography>
      <Divider style={{flex: 1}} />
    </Container>
  );
};

export default IconTextInABox;
