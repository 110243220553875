/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: approvers Route
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';

export interface Approver {
  email: string;
  airline_code: string;
  airline_name: string;
}

export const useApprovers = (params: {airlines: string[] | null}) =>
  useQuery({
    queryKey: ['approvers', params.airlines],
    queryFn: () => fetcher({route: 'approvers', params}),
    select: (data): Approver[] => data.approvers,
    refetchOnWindowFocus: false
  });
