/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Grid store base
 */
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';

export type GridBaseStateType = {
  filters: FilterRow[];
};

export type GridBaseActionType = {
  setFilters: (filters: FilterRow[]) => void;
};

export const gridBaseInitialValues: GridBaseStateType = {
  filters: []
};

/**
 * Sets up action mapping for grid items
 * @param set Set store function
 * @returns Action mappings
 */
export const gridBaseHookActions = (set: (newState: Partial<GridBaseStateType>) => void): GridBaseActionType => ({
  setFilters: (filters: FilterRow[]) => set({filters: filters})
});
