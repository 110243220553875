/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ExternalLink component
 */

import {OpenInNew} from '@mui/icons-material';
import {Link, LinkProps} from '@mui/material';
import {fonts, colors, spacing} from '@vst/beam';

/**
 * A link to an external url
 */
const ExternalLink: React.FC<LinkProps> = props => {
  return (
    <Link
      sx={{display: 'inline-flex', flexDirection: 'row', gap: `${spacing[4]}`, alignItems: 'center', ...props.style}}
      fontStyle={{font: fonts.body.Small.Regular}}
      {...props}
      target="_blank"
    >
      {props.children}
      <OpenInNew
        fontSize="small"
        htmlColor={colors.violet[500]}
        sx={{verticalAlign: 'middle', paddingX: `${spacing[4]}`}}
      />
    </Link>
  );
};

export default ExternalLink;
