/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: DetailsHeading component
 */
import * as React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import {Link} from '@mui/material';

const DetailsHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex-grow: 1;
`;

export const DetailsHeading: React.FC<{
  title: string;
  linkTo: string;
}> = ({title, linkTo}) => {
  return (
    <DetailsHeadingContainer data-testid="DetailsHeading">
      <Typography variant="h5">
        <Link rel="noreferrer" target="_blank" href={linkTo} className="linksHeading" sx={{padding: '0px'}}>
          {title}
        </Link>
      </Typography>
    </DetailsHeadingContainer>
  );
};
