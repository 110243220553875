/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connected Partner Program Request Secrets Step
 */
import React from 'react';
import {Divider} from '@mui/material';
import {Section} from '../../../components/Section';
import {CppButtonSection, RequestBox, SubSectionContainer} from './CppRequestShared';
import {CppStepProps} from '../types';
import ReviewIntakeDetails from '../../../components/CppIntakeRequestReview';

/**
 * Converts newlines to HTML line breaks.
 * @param input String input
 * @param keyPfx Fragment key prefix
 * @returns Newlines replaced with HTML line breaks
 */
export const replaceNewlines = (input: string, keyPfx: string): JSX.Element[] =>
  input.split('\n').map((item, idx) => (
    <React.Fragment key={`${keyPfx}-${idx}`}>
      {item}
      <br />
    </React.Fragment>
  ));

/**
 * Represents the review step of the CPP request process.
 *
 * @component
 * @param {CppStepProps} props - The component props.
 * @returns {JSX.Element} The JSX element representing the CppRequestReviewStep.
 */
const CppRequestReviewStep: React.FC<CppStepProps> = props => {
  const {inputs, setActiveStep} = props;

  return (
    <>
      <RequestBox>
        <Section
          title="Review"
          bodyText="Please take a moment to review that the details of your submission are accurate."
          layout="vertical"
        >
          <SubSectionContainer>
            <ReviewIntakeDetails data={inputs} airline={inputs.details?.airlineName!} setActiveStep={setActiveStep} />
          </SubSectionContainer>
          <Divider />
        </Section>
      </RequestBox>

      <CppButtonSection
        {...props}
        verify={callback => () => callback({})}
        clear={null}
        valueKey="review"
        id={props.id as string}
      />
    </>
  );
};

export default CppRequestReviewStep;
