/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: DeleteNotifiedUserDialog component
 */

import * as React from 'react';

import {strings} from '../locale/strings';

import {useDeleteNotifiedUser} from '../api/mutations/useDeleteNotifiedUser';
import {ModalDialog} from './ModalDialog';
import {Typography} from '@mui/material';
import {NotifiedUser} from './NotifiedUsersTable';

export const DeleteNotifiedUserDialog: React.FC<{
  notifiedUserToDelete: NotifiedUser | null;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}> = ({notifiedUserToDelete, onClose, onSuccess, onError}) => {
  const deleteNotifiedUserResult = useDeleteNotifiedUser({
    onSuccess: () => {
      onClose();
      onSuccess();
      deleteNotifiedUserResult.reset();
    },
    onError: error => {
      onError();
      onClose();
      deleteNotifiedUserResult.reset();
    }
  });

  const onDelete = React.useCallback(() => {
    if (notifiedUserToDelete) {
      deleteNotifiedUserResult.mutate({params: {email: notifiedUserToDelete.email}});
    }
  }, [notifiedUserToDelete, deleteNotifiedUserResult]);

  return (
    <ModalDialog
      title={strings.RemoveNotifiedUserDialog.Title}
      open={notifiedUserToDelete != null}
      displayState={deleteNotifiedUserResult.isLoading ? 'loading' : 'default'}
      onOk={onDelete}
      onClose={onClose}
    >
      {notifiedUserToDelete && (
        <Typography>
          {strings.RemoveNotifiedUserDialog.BodyText}{' '}
          <Typography sx={{fontWeight: 'bold'}} component="span">
            {notifiedUserToDelete.email}
          </Typography>
          ?
        </Typography>
      )}
    </ModalDialog>
  );
};
