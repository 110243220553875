/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Responsive Custom Button
 */

import React from 'react';
import {StyledButton} from '@viasat/insights-components';
import styled from '@emotion/styled';
import {useTheme} from '@mui/material';

const ButtonLabel = styled.span<{activeColor?: string}>`
  color: ${props => props.activeColor};
  padding-left: 8px;
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

interface CustomButtonProps {
  getFullElementId: (name: string, type: string) => string;
  open: boolean;
  disabled?: boolean;
  onClick: (open: boolean, $event: any) => void;
  IconComponent: React.ElementType;
  buttonText?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  getFullElementId,
  open,
  disabled,
  onClick,
  IconComponent,
  buttonText
}: CustomButtonProps) => {
  const theme = useTheme();

  return (
    <StyledButton
      id={getFullElementId('customButton', 'button')}
      selected={open}
      disabled={disabled}
      onClick={($event: any) => onClick(!open, $event)}
    >
      <IconComponent style={{fill: open ? theme.palette.primary.main : '', fontSize: 24}} />
      <ButtonLabel activeColor={open ? theme.palette.primary.main : ''}>{buttonText}</ButtonLabel>
    </StyledButton>
  );
};

export default CustomButton;
