/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: DeleteApproverDialog component
 */

import React from 'react';
import {strings} from '../locale/strings';
import {useDeleteApprover} from '../api/mutations/useDeleteApprover';
import {ModalDialog} from './ModalDialog';
import {Approver} from '../api/queries/useApprovers';
import {Typography} from '@mui/material';

export const DeleteApproverDialog: React.FC<{
  approverToDelete: Approver | null;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}> = ({approverToDelete, onClose, onSuccess, onError}) => {
  const deleteApproverResult = useDeleteApprover({
    onSuccess: () => {
      onClose();
      onSuccess();
      deleteApproverResult.reset();
    },
    onError: () => {
      onError();
      onClose();
      deleteApproverResult.reset();
    }
  });

  const onDelete = React.useCallback(() => {
    if (approverToDelete) {
      deleteApproverResult.mutate({params: {email: approverToDelete.email}});
    }
  }, [approverToDelete, deleteApproverResult]);

  return (
    <ModalDialog
      title={strings.RemoveApproverDialog.Title}
      open={approverToDelete != null}
      displayState={deleteApproverResult.isLoading ? 'loading' : 'default'}
      onOk={onDelete}
      onClose={onClose}
    >
      {approverToDelete && (
        <Typography>
          {strings.RemoveApproverDialog.BodyText}{' '}
          <Typography sx={{fontWeight: 'bold'}} component="span">
            {approverToDelete.email}
          </Typography>
          ?
        </Typography>
      )}
    </ModalDialog>
  );
};
