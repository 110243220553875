/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Custom Dropzone Component
 */
import {Alert, Box, Snackbar, Typography, useTheme} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import Dropzone, {FileRejection} from 'react-dropzone';
import {spacing} from '@vst/beam';
import CloudUpload from '@mui/icons-material/CloudUpload';
import {Dispatcher} from '../theme/Colors';

const UNIT_ARR = ['B', 'KB', 'MB', 'GB', 'TB'];
const BYTES_PER_MB = 1000;

/**
 * Formats a size to the correct bytes unit
 * @param value Size value to format
 * @returns Formatted size area
 */
const formatSize = (value: number) => {
  let shrunkSize = value;
  let count = 0;

  while (shrunkSize > BYTES_PER_MB) {
    shrunkSize /= BYTES_PER_MB;
    count++;
  }

  return `${shrunkSize.toFixed(1)} ${UNIT_ARR[count]}`;
};

const CustomDropzone: React.FC<{
  accept?: Record<string, string[]>;
  disabled?: boolean;
  files: File[];
  setFiles: (files: File[]) => void;
  errorMsg?: string;
  keyPfx: string;
}> = ({disabled, files, setFiles, errorMsg, accept, keyPfx}) => {
  const [rejectionReason, setRejectionReason] = useState<string | null>(null);
  const theme = useTheme();

  const onDrop = (acceptedFiles: File[], rejections: FileRejection[]) => {
    for (const acceptedFile of acceptedFiles) {
      if (files.some(aFile => aFile.name === acceptedFile.name)) {
        setRejectionReason(`File ${acceptedFile.name} is already included.`);
        return;
      }
    }

    setFiles(files.concat(acceptedFiles));
    if (rejections.length > 0) {
      setRejectionReason(rejections[0].errors[0].message);
    }
  };

  const handleCloseSnackbar = useCallback(() => {
    setRejectionReason(null);
  }, [setRejectionReason]);

  const extensions = useMemo(
    () =>
      accept &&
      Object.values(accept)
        .reduce((acc: string[], item: string[]) => {
          acc.push(...item);
          return acc;
        }, [])
        .join(', '),
    [accept]
  );

  return (
    <Box>
      <Dropzone
        onDrop={onDrop}
        onError={err => console.error(err)}
        accept={accept}
        noClick={true}
        noKeyboard={true}
        disabled={disabled}
        maxSize={5000000000}
      >
        {({getRootProps, getInputProps, open}) => (
          <section>
            <Box
              {...getRootProps()}
              sx={{
                border: `2px dashed ${errorMsg ? theme.palette.error.main : Dispatcher.AccessibleGrayIconsAndBorders}`,
                borderRadius: '8px',
                height: '128px'
              }}
            >
              <input {...getInputProps()} />
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: disabled ? 'not-allowed' : 'default',
                  color: Dispatcher.AccessibleGrayIconsAndBorders,
                  textAlign: 'center'
                }}
              >
                <Box sx={{ml: 2, mr: 2}}>
                  <CloudUpload fontSize="large" />
                  <Typography variant="body1" color={theme.palette.text.primary}>
                    Drag and drop container image here or{' '}
                    <Box
                      component="span"
                      onClick={() => {
                        if (!disabled) open();
                      }}
                      sx={{color: theme.palette.primary.main, cursor: disabled ? 'not-allowed' : 'pointer'}}
                    >
                      browse files
                    </Box>
                    .
                  </Typography>
                </Box>
              </Box>
            </Box>
          </section>
        )}
      </Dropzone>
      {extensions ? (
        <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
          <Typography variant="body2" color={Dispatcher.AccessibleGray} sx={{marginTop: spacing[8], flexGrow: 1}}>
            Accepted file types: {extensions}
          </Typography>
          <Typography variant="body2" color={Dispatcher.AccessibleGray} sx={{marginTop: spacing[8]}}>
            Maximum file size: 5GB
          </Typography>
        </Box>
      ) : (
        <Typography variant="body2" color={Dispatcher.AccessibleGray} sx={{marginTop: spacing[8], textAlign: 'right'}}>
          Maximum file size: 5GB
        </Typography>
      )}
      {errorMsg ? (
        <Typography variant="body2" color={theme.palette.error.main} sx={{marginTop: spacing[8]}}>
          {errorMsg}
        </Typography>
      ) : null}
      {files.map((item, index) => (
        <Box
          key={`${keyPfx}-${index}`}
          sx={{
            border: `1px solid ${Dispatcher.AccessibleGrayIconsAndBorders}`,
            borderRadius: '4px',
            display: 'flex',
            flexFlow: 'row nowrap',
            padding: `${spacing[8]} ${spacing[16]}`,
            marginTop: spacing[16]
          }}
        >
          <Typography variant="body2" color={theme.palette.text.primary} sx={{flexGrow: 1, fontWeight: 'bold'}}>
            {item.name}{' '}
            <Box component="span" sx={{fontWeight: 'normal'}}>
              ({formatSize(item.size)})
            </Box>
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.primary.main}
            sx={{cursor: disabled ? 'not-allowed' : 'pointer'}}
          >
            <span
              data-testid="RemoveFromDropzone"
              onClick={() => setFiles(files.filter(aFile => aFile.name !== item.name))}
            >
              Remove
            </span>
          </Typography>
        </Box>
      ))}

      <Snackbar open={Boolean(rejectionReason)} onClose={handleCloseSnackbar} autoHideDuration={5000}>
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {rejectionReason || ''}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomDropzone;
