/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: useUrlParams hook
 */
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';

/**
 * Hook for loading URL parameters into the view
 * @param loadUrlParams Store function for loading in URL parameters
 */
const useUrlParams = (loadUrlParams: (params: any) => void) => {
  const navigate = useNavigate();
  useEffect(() => {
    const search = window.location.search;

    if (search) {
      loadUrlParams(qs.parse(window.location.search, {ignoreQueryPrefix: true}));
      navigate(window.location.pathname, {replace: true});
    }
  }, [navigate, loadUrlParams]);
};

export default useUrlParams;
